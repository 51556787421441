import React, { Component } from "react";
import LoadPageAuthCheck from "../../components/LoadPageAuthCheck";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router";
import Loading from "../../components/Loading";
import "../../css/fonts.css";
import "../../css/Download.css";
import DownloadPageLogo from "../../img/downloadPageLogo.svg";
import AndroidIcon from "../../img/android_icon_black.svg";
import AppleIcon from "../../img/Apple.png"

class Download extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_mobile: false,
        };
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        if (window.innerWidth <= 767) {
            this.setState({
                is_mobile: true
            });
        } else {
            this.setState({
                is_mobile: false
            });
        }
    };

    render() {
        if (this.props.loading) {
            return (
                <div>
                    <Loading />
                    <LoadPageAuthCheck {...this.props} />
                </div>
            );
        } else if (!this.props.logged_in) {
            return <Redirect to="/" />;
        } else {
            return (
                <div className="download-page" style={{ paddingTop: this.state.is_mobile ? "4em" : "0", width: '100%', minHeight: '100vh',
                backgroundRepeat: 'no-repeat', backgroundSize: 'cover'}}>
                    <div>
                        <h1 className="download-page-title">Download StellaNova Mobile App</h1>
                        <div className="download-page-img">
                            <img src={DownloadPageLogo} alt="stellanova logo"/>
                        </div>
                        <div className="download-page-buttons" style={{ flexDirection: this.state.is_mobile ? 'column' : 'row'}}>
                            <button><a href="https://testflight.apple.com/join/WMSBCdor" target="_blank" rel="noopener noreferrer">Download on</a><img src={AppleIcon} alt="apple icon"/></button>
                            <button><a href="https://play.google.com/apps/internaltest/4701495486733336093" target="_blank" rel="noopener noreferrer">Download on</a><img src={AndroidIcon} alt="android icon"/></button>
                        </div>
                    </div>
                </div>
            );
        }
    }
}



const mapStateToProps = (state) => ({
    logged_in: state.logged_in,
    loading: state.loading,
});

export default withRouter(connect(mapStateToProps)(Download));
