import React from "react";
import { Media, Col } from "reactstrap";
import "../../css/fonts.css";

const Header = (props) => {
    return (
        <div>
            <h2
                style={{
                    display: "flex",
                    justifyContent: "center",
                    textAlign: "center",
                    fontFamily: "Roboto-Light",
                    paddingTop: "1em",
                    fontSize: props.is_mobile ? "28px" : "32px"
                }}
            >
                {props.title}
            </h2>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <Media
                    object
                    src={props.s3_logo_large}
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        padding: "1em",
                        maxWidth: "12em"
                    }}
                    alt="Large Feature Logo"
                />
            </div>
            <div style={{ display: "flex", justifyContent: "center" }}>
                <Col>
                    <div>
                        <p
                            style={{
                                textAlign: "center",
                                fontSize: props.is_mobile ? "14px" : "16px"
                            }}
                        >
                            {props.description}
                        </p>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center", paddingTop: "1em" }}>
                        <Media heading>Setup Instructions</Media>
                    </div>
                </Col>
            </div>
        </div>
    );
};

export default Header;
