import React from "react";
import { Button, Col, ToastBody, Jumbotron, Badge } from "reactstrap";
import Header from "./Header";
import Images from "./Images";
import Links from "./Links";
import Text from "./Text";
import "../../css/fonts.css";

const DisplayFeature = (props) => {
    console.log("JUMBO WIDTH", props.jumbotron_width);
    return (
        <ToastBody
            style={{
                display: "flex",
                justifyContent: "center",
                backgroundColor:
                    props.is_deploy_preview && props.image_text_mismatch
                        ? "#F4B2B9"
                        : "transparent",
                outline:
                    props.is_deploy_preview && props.image_text_mismatch
                        ? "2px solid #DC3545"
                        : "0px"
            }}
        >
            <Jumbotron
                style={{
                    padding: "1em",
                    width: props.jumbotron_width,
                    backgroundColor:
                        props.is_deploy_preview && props.image_text_mismatch
                            ? "#F4B2B9"
                            : "transparent"
                }}
            >
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        fontFamily: "Roboto-Light"
                    }}
                >
                    <Col>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            {props.is_preview ? (
                                <Badge pill color="warning">
                                    Preview
                                </Badge>
                            ) : null}
                        </div>
                        <Header
                            is_mobile={props.is_mobile}
                            title={props.feature.title}
                            description={props.feature.description}
                            s3_logo_large={props.feature.s3_logo_large}
                        />
                        <Col>
                            {/* Links if chosen to place before content */}
                            {props.links && props.feature.links_first ? (
                                <Links
                                    is_mobile={props.is_mobile}
                                    link_instructions_text={props.feature.link_instructions_text}
                                    link_instructions_links={props.feature.link_instructions_links}
                                />
                            ) : null}

                            {/* Images or Images + Text */}
                            {props.images ? (
                                <Images
                                    is_mobile={props.is_mobile}
                                    s3_instructions={props.feature.s3_instructions}
                                    text_instructions={props.feature.text_instructions}
                                    text={props.text}
                                />
                            ) : null}

                            {/* Text only */}
                            {props.text && !props.images ? (
                                <Text
                                    is_mobile={props.is_mobile}
                                    text_instructions={props.feature.text_instructions}
                                />
                            ) : null}

                            {/* Links if chosen to place after content */}
                            {props.links && !props.feature.links_first ? (
                                <Links
                                    is_mobile={props.is_mobile}
                                    link_instructions_text={props.feature.link_instructions_text}
                                    link_instructions_links={props.feature.link_instructions_links}
                                />
                            ) : null}
                        </Col>
                        {!props.is_deploy_preview ? (
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                {props.is_preview ? (
                                    <Button
                                        size={props.is_mobile ? "sm" : "md"}
                                        color="primary"
                                        href="/admin"
                                    >
                                        Back to Console
                                    </Button>
                                ) : (
                                    <Button
                                        size={props.is_mobile ? "sm" : "md"}
                                        color="primary"
                                        href="/getstarted"
                                    >
                                        Back to Features
                                    </Button>
                                )}
                            </div>
                        ) : null}
                    </Col>
                </div>
            </Jumbotron>
        </ToastBody>
    );
};

export default DisplayFeature;
