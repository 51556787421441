import axios from "axios";

// Gets access token of user using code in url
/* 
  fca_code: string, required (code in url)
*/
async function get_access_token(fca_code) {
    let apiurl = process.env.REACT_APP_BETA_SITE_GET_TOKEN_URL;
    let redirect_uri;
    if (process.env.NODE_ENV === "development") {
        redirect_uri = process.env.REACT_APP_BETA_SITE_REDIRECT_URI_DEV;
    } else {
        redirect_uri = process.env.REACT_APP_BETA_SITE_REDIRECT_URI_PROD;
    }
    var params = new URLSearchParams();
    params.append("grant_type", "authorization_code");
    params.append("redirect_uri", redirect_uri);
    params.append("client_id", process.env.REACT_APP_BETA_SITE_CLIENT_ID);
    params.append("code", fca_code);

    return new Promise((resolve, reject) => {
        axios
            .post(apiurl, params)
            .then((response) => {
                console.log("Get access token response", response);
                try {
                    authorizeUser(response.data.access_token)
                        .then((result) => {
                            console.log(
                                `Authorized user after getting 
              token`,
                                response
                            );
                            resolve(result);
                        })
                        .catch((error) => {
                            console.log(
                                `Error authorizing user after  
              getting token`,
                                error
                            );
                            reject(error);
                        });
                } catch (error) {
                    console.log("Invalid response object.");
                    reject(response);
                }
            })
            .catch((error) => {
                console.log("Get access token error", error);
                if (error.response) {
                    console.log(
                        `Request was made, server responded 
        with status code outside 2xx`,
                        error.response
                    );
                } else if (error.request) {
                    console.log(
                        `The request was made but no response 
        was received`,
                        error.request
                    );
                } else {
                    console.log(`Error`, error.message);
                }
                reject(error);
            });
    });
}

// Gets user info using access token
/* 
  token: string, required (FCA OAuth/Cognito Access Token)
*/
async function authorizeUser(token) {
    let apiurl = process.env.REACT_APP_STELLANOVA_BACKEND_API_URL;
    const options = {
        headers: {
            Authorization: "Bearer " + token
        },
        method: "POST",
        url: apiurl + "/token_login",
        data: {
            gigya_login_token: token
        }
    };
    console.log("Login token:", token);
    return new Promise((resolve, reject) => {
        axios(options)
            .then((response) => {
                console.log("Authorize user response", response);
                resolve(response);
            })
            .catch((error) => {
                console.log("Authorize user error", error);
                if (error.response) {
                    console.log(
                        `Request was made, server responded 
          with status code outside 2xx`,
                        error.response
                    );
                } else if (error.request) {
                    console.log(
                        `The request was made but no response 
          was received`,
                        error.request
                    );
                } else {
                    console.log(`Error`, error.message);
                }
                reject(error);
            });
    });
}

// Finds user in beta-site-users database
/*
  email: string, required
  token: string, required (FCA OAuth/Cognito Access Token)
*/
async function findUserAPI(email, token) {
    let apiurl = process.env.REACT_APP_BETA_SITE_LAMBDA + "/finduser";
    const options = {
        method: "get",
        url: apiurl,
        headers: {
            email: email,
            authorization: "Bearer " + token
        }
    };
    console.log("Find user Headers:", options.headers);
    return new Promise((resolve, reject) => {
        axios(options)
            .then((response) => {
                console.log("Find user lambda response", response.data);
                resolve(response.data);
            })
            .catch((error) => {
                console.log(`Error`, error);
                reject(error);
            });
    });
}

// Adds user to beta-site-users database
/*
  email: string, required
  token: string, required (FCA OAuth/Cognito Access Token)
*/
async function addUserAPI(email, token) {
    let apiurl = process.env.REACT_APP_BETA_SITE_LAMBDA + "/adduser";
    const options = {
        method: "post",
        url: apiurl,
        headers: {
            email: email,
            authorization: "Bearer " + token
        }
    };
    console.log("Add user Headers:", options.headers);
    return new Promise((resolve, reject) => {
        axios(options)
            .then((response) => {
                console.log("Add user lambda response", response.data);
                resolve(response.data);
            })
            .catch((error) => {
                console.log(`Error`, error);
                reject(error);
            });
    });
}

// Delete user from beta-site-users database
/* 
  info: {
    beta_site_email: info.beta_site_email,
    uconnect_email: info.uconnect_email,
    developer_status_requested: info.developer_status_requested,
    vins_pending: info.vins_pending
  }
  token: string, required (FCA OAuth/Cognito Access Token)
*/
async function deleteUserAPI(info, token) {
    let apiurl = process.env.REACT_APP_BETA_SITE_LAMBDA + "/deleteuser";
    const options = {
        method: "delete",
        url: apiurl,
        headers: {
            authorization: "Bearer " + token
        },
        data: {
            beta_site_email: info.beta_site_email,
            uconnect_email: info.uconnect_email,
            developer_status_requested: info.developer_status_requested,
            vins_pending: info.vins_pending
        }
    };
    console.log("delete user options", options);
    return new Promise((resolve, reject) => {
        axios(options)
            .then((response) => {
                console.log("Delete user lambda response", response.data);
                if (response.data.hasOwnProperty("error")) {
                    reject(response.data.error);
                } else {
                    resolve(response.data);
                }
            })
            .catch((error) => {
                console.log(`Error`, error);
                reject(error);
            });
    });
}

// Checks service status of Sirius XM
/* 
  token: string, required (FCA OAuth/Cognito Access Token)
*/
async function checkStatusAPI(token) {
    let apiurl = process.env.REACT_APP_BETA_SITE_LAMBDA + "/check-status";
    const options = {
        method: "get",
        url: apiurl,
        headers: {
            authorization: "Bearer " + token
        }
    };
    console.log("Check status Headers:", options.headers);
    return new Promise((resolve, reject) => {
        axios(options)
            .then((response) => {
                console.log("Check status lambda response", response);
                resolve(response);
            })
            .catch((error) => {
                console.log(`Error`, error);
                reject(error);
            });
    });
}

// Edits dev request status of user
/* 
  beta_site_email: string, required
  uconnect_email: string, required
  request_status: string, required 
    'waiting' | 'internal' | 'public' | 'denied'
  token: string, required (FCA OAuth/Cognito Access Token)
*/
async function devRequestAPI(beta_site_email, uconnect_email, request_status, token) {
    let apiurl = process.env.REACT_APP_BETA_SITE_LAMBDA + "/devrequest?request_status=";
    if (request_status) apiurl += "true";
    else apiurl += "false";
    const options = {
        method: "post",
        url: apiurl,
        headers: {
            beta_site_email: beta_site_email,
            uconnect_email: uconnect_email,
            authorization: "Bearer " + token
        }
    };
    console.log("Headers:", options.headers);
    return new Promise((resolve, reject) => {
        axios(options)
            .then((response) => {
                console.log("Dev request lambda response", response);
                resolve(response);
            })
            .catch((error) => {
                console.log(`Error`, error);
                reject(error);
            });
    });
}

// Get url to upload image binary to for S3
/* 
  pure_type: string, required (image type)
  token: string, required (FCA OAuth/Cognito Access Token)
*/
async function getUploadURLAPI(pure_type, token) {
    let apiurl = process.env.REACT_APP_BETA_SITE_LAMBDA + "/feature/getuploadurl?type=" + pure_type;
    const options = {
        method: "get",
        url: apiurl,
        headers: {
            authorization: "Bearer " + token
        }
    };
    return new Promise((resolve, reject) => {
        axios(options)
            .then((response) => {
                console.log("Get upload URL lambda response", response);
                resolve(response);
            })
            .catch((error) => {
                console.log(`Error`, error);
                reject(error);
            });
    });
}

// Add new feature or redeploy existing feature to be reviewed
/* 
  feature_info: { (required)
    feature_id: string, required
    title: string, required
    author: string, required
    description: string, required
    category: string, required
    other_text: string, optional (added if category === 'Other')
    s3_logo_large: string, required (S3 image URL for large logo)
    s3_logo_small: string, required (S3 image URL for small logo)
    text_instructions: [string], optional
    s3_instructions: [string], optional
    link_instructions_text: [string], optional
    link_instructions_links: [string], optional
    links_first: boolean, optional
  }
  redeploy: boolean, optional
  is_admin_edit: boolean, optional
  token: string, required (FCA OAuth/Cognito Access Token)
*/
async function addFeatureAPI(feature_info, redeploy = false, is_admin_edit = false, token) {
    console.log("Feature info:", feature_info);
    let apiurl = process.env.REACT_APP_BETA_SITE_LAMBDA + "/feature/addfeature";
    let headers = {
        "Content-Type": "application/json, text/plain",
        authorization: "Bearer " + token
    };
    let data = {
        feature_id: feature_info.feature_id,
        title: feature_info.title,
        author: feature_info.author,
        description: feature_info.description,
        category: feature_info.category,
        s3_logo_large: feature_info.s3_logo_large,
        s3_logo_small: feature_info.s3_logo_small
    };
    if (feature_info.hasOwnProperty("other_text")) {
        data = Object.assign(data, {
            other_text: feature_info.other_text
        });
    }
    if (feature_info.text_instructions.length !== 0) {
        data = Object.assign(data, {
            text_instructions: feature_info.text_instructions
        });
    }
    if (feature_info.s3_instructions.length !== 0) {
        data = Object.assign(data, {
            s3_instructions: feature_info.s3_instructions
        });
    }
    if (feature_info.link_instructions_text.length !== 0) {
        data = Object.assign(data, {
            link_instructions_text: feature_info.link_instructions_text,
            link_instructions_links: feature_info.link_instructions_links,
            links_first: feature_info.links_first
        });
    }
    if (redeploy) {
        data = Object.assign(data, {
            redeploy: true
        });
    }
    if (redeploy) {
        data = Object.assign(data, {
            redeploy: true
        });
    }
    if (is_admin_edit) {
        data = Object.assign(data, {
            is_admin_edit: true,
            approval_status: feature_info.approval_status,
            approval_notes: feature_info.approval_notes
        });
    }

    const options = {
        method: "post",
        url: apiurl,
        headers: headers,
        data: data
    };

    return new Promise((resolve, reject) => {
        axios(options)
            .then((response) => {
                console.log("Add feature lambda response", response);
                if (response.data.hasOwnProperty("error")) {
                    if (response.data.error.includes("ConditionalCheckFailedException")) {
                        reject("ConditionalCheckFailedException");
                    } else reject(response);
                } else {
                    resolve(response);
                }
            })
            .catch((error) => {
                console.log(`Error`, error);
                reject(error);
            });
    });
}

// Find feature using feature_id
/* 
  feature_id: string, required
  token: string, required (FCA OAuth/Cognito Access Token)
*/
async function findFeatureAPI(feature_id, token) {
    let apiurl = process.env.REACT_APP_BETA_SITE_LAMBDA + "/feature/findfeature";
    const options = {
        method: "get",
        url: apiurl,
        headers: {
            feature_id: feature_id,
            authorization: "Bearer " + token
        }
    };
    console.log("Headers:", options.headers);
    return new Promise((resolve, reject) => {
        axios(options)
            .then((response) => {
                console.log("Find feature exists lambda response", response.data);
                resolve(response.data);
            })
            .catch((error) => {
                console.log(`Error`, error);
                reject(error);
            });
    });
}

// Return all features
/*
  token: string, required (FCA OAuth/Cognito Access Token)
*/
async function queryFeaturesAPI(token) {
    let apiurl = process.env.REACT_APP_BETA_SITE_LAMBDA + "/feature/queryfeatures";
    const options = {
        method: "get",
        url: apiurl,
        headers: {
            authorization: "Bearer " + token
        }
    };
    return new Promise((resolve, reject) => {
        axios(options)
            .then((response) => {
                console.log("Query features lambda response", response.data);
                resolve(response.data);
            })
            .catch((error) => {
                console.log(`Error`, error);
                reject(error);
            });
    });
}

// Find multiple features using list of feature_ids
/* 
  feature_ids: [string], required
  token: string, required (FCA OAuth/Cognito Access Token)
*/
async function findFeaturesAPI(feature_ids, token) {
    let apiurl = process.env.REACT_APP_BETA_SITE_LAMBDA + "/feature/findfeatures";
    const options = {
        method: "post",
        url: apiurl,
        headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + token
        },
        data: {
            feature_ids: feature_ids
        }
    };
    console.log("feature_ids:", options.data);
    return new Promise((resolve, reject) => {
        axios(options)
            .then((response) => {
                console.log("Find features lambda response", response.data);
                resolve(response.data);
            })
            .catch((error) => {
                console.log(`Error`, error);
                reject(error);
            });
    });
}

// Approve/deny feature deployment request
/* 
  info: { (required)
    author: string, required
    feature_id: string, required
    title: string, required
    approval_status: boolean, required
  }
  token: string, required (FCA OAuth/Cognito Access Token)
*/
async function approveFeatureAPI(info, token) {
    let apiurl = process.env.REACT_APP_BETA_SITE_LAMBDA + "/feature/approve";
    let data = {
        author: info.author,
        feature_id: info.feature_id,
        title: info.title,
        approval_status: info.approval_status
    };
    if (info.approval_notes) {
        data = Object.assign(data, {
            approval_notes: info.approval_notes
        });
    }
    const options = {
        method: "post",
        url: apiurl,
        headers: {
            "Content-Type": "application/json",
            authorization: "Bearer " + token
        },
        data: data
    };

    console.log("Approval options:", options);
    return new Promise((resolve, reject) => {
        axios(options)
            .then((response) => {
                console.log("Approve feature lambda response", response.data);
                if (response.data.hasOwnProperty("error")) {
                    reject(response.data.error);
                } else {
                    resolve(response.data);
                }
            })
            .catch((error) => {
                console.log(`Error`, error);
                reject(error);
            });
    });
}

// Delete feature using feature_id and author
/* 
  feature_id: string, required
  author: string, required
  token: string, required (FCA OAuth/Cognito Access Token)
*/
async function deleteFeatureAPI(feature_id, author, token) {
    let apiurl = process.env.REACT_APP_BETA_SITE_LAMBDA + "/feature/delete";
    const options = {
        method: "delete",
        url: apiurl,
        headers: {
            feature_id: feature_id,
            author: author,
            authorization: "Bearer " + token
        }
    };
    console.log("delete options", options);
    return new Promise((resolve, reject) => {
        axios(options)
            .then((response) => {
                console.log("Delete feature lambda response", response.data);
                if (response.data.hasOwnProperty("error")) {
                    reject(response.data.error);
                } else {
                    resolve(response.data);
                }
            })
            .catch((error) => {
                console.log(`Error`, error);
                reject(error);
            });
    });
}

// Return all developer status requests
/*
  token: string, required (FCA OAuth/Cognito Access Token)
*/
async function queryDevRequestsAPI(token) {
    let apiurl = process.env.REACT_APP_BETA_SITE_LAMBDA + "/querydevrequests";
    const options = {
        method: "get",
        url: apiurl,
        headers: {
            authorization: "Bearer " + token
        }
    };
    return new Promise((resolve, reject) => {
        axios(options)
            .then((response) => {
                console.log("Query dev requests lambda response", response.data);
                resolve(response.data);
            })
            .catch((error) => {
                console.log(`Error`, error);
                reject(error);
            });
    });
}

// Approve/deny developer status request
/* 
  beta_site_email: string, required
  uconnect_email: string, required
  approve: boolean, required
  token: string, required (FCA OAuth/Cognito Access Token)
*/
async function approveDevRequestAPI(beta_site_email, uconnect_email, approve, token) {
    let apiurl =
        process.env.REACT_APP_BETA_SITE_LAMBDA +
        "/approvedev?approve=" +
        (approve ? "true" : "false");
    const options = {
        method: "post",
        url: apiurl,
        headers: {
            beta_site_email: beta_site_email,
            uconnect_email: uconnect_email,
            authorization: "Bearer " + token
        }
    };

    return new Promise((resolve, reject) => {
        axios(options)
            .then((response) => {
                console.log("Approve dev request lambda response", response.data);
                resolve(response.data);
            })
            .catch((error) => {
                console.log(`Error`, error);
                reject(error);
            });
    });
}

// Sends verification email
/* 
  uconnect_email: string, required
  token: string, required (FCA OAuth/Cognito Access Token)
*/
async function verifyEmailAPI(uconnect_email, token) {
    let apiurl = process.env.REACT_APP_BETA_SITE_LAMBDA + "/verifyemail";
    const options = {
        method: "post",
        url: apiurl,
        headers: {
            uconnect_email: uconnect_email,
            authorization: "Bearer " + token
        }
    };
    console.log("Verify email options:", options);
    return new Promise((resolve, reject) => {
        axios(options)
            .then((response) => {
                console.log("Verify email lambda response", response.data);
                resolve(response.data);
            })
            .catch((error) => {
                console.log(`Error`, error);
                reject(error);
            });
    });
}

// Set user's Uconnect Email (can be same as regular Beta Site Email)
/* 
  beta_site_email: string, required,
  uconnect_email: string, required
  token: string, required (FCA OAuth/Cognito Access Token)
*/
async function setUconnectInfoAPI(beta_site_email, uconnect_email, phone_number, token) {
    let apiurl = process.env.REACT_APP_BETA_SITE_LAMBDA + "/setuconnectemail";
    const options = {
        method: "post",
        url: apiurl,
        headers: {
            beta_site_email: beta_site_email,
            uconnect_email: uconnect_email,
            phone_number: phone_number,
            authorization: "Bearer " + token
        }
    };

    return new Promise((resolve, reject) => {
        axios(options)
            .then((response) => {
                console.log("Set Uconnect Email lambda response", response.data);
                resolve(response.data);
            })
            .catch((error) => {
                console.log(`Error`, error);
                reject(error);
            });
    });
}

// Resets the user's code used for ADA features (for VIN validation)
/* 
  uconnect_email: string, required
  token: string, required (FCA OAuth/Cognito Access Token)
*/
async function resetCodeAPI(uconnect_email, token) {
    let apiurl = process.env.REACT_APP_BETA_SITE_LAMBDA + "/resetcode";
    const options = {
        method: "post",
        url: apiurl,
        headers: {
            uconnect_email: uconnect_email,
            authorization: "Bearer " + token
        }
    };
    console.log("Verify email options:", options);
    return new Promise((resolve, reject) => {
        axios(options)
            .then((response) => {
                console.log("Verify email lambda response", response.data);
                if (response.data.hasOwnProperty("error")) {
                    reject(response.data.error);
                } else {
                    resolve(response.data);
                }
            })
            .catch((error) => {
                console.log(`Error`, error);
                reject(error);
            });
    });
}

// Submits VINs request for validation
/* 
  info: {
    uconnect_email: string, required 
    beta_site_email: string, required
    vins: [string], required
    vins_nicknames: [string], required
  }
  token: string, required (FCA OAuth/Cognito Access Token)
*/
async function submitVINsRequestAPI(info, token) {
    let apiurl = process.env.REACT_APP_BETA_SITE_LAMBDA + "/submitvinsrequest";
    const options = {
        method: "post",
        url: apiurl,
        data: {
            uconnect_email: info.uconnect_email,
            beta_site_email: info.beta_site_email,
            vins: info.vins,
            vins_nicknames: info.vins_nicknames
        },
        headers: {
            authorization: "Bearer " + token
        }
    };
    console.log("Submit VINs options:", options);
    return new Promise((resolve, reject) => {
        axios(options)
            .then((response) => {
                console.log("Submit VINs lambda response", response.data);
                if (response.data.hasOwnProperty("error")) {
                    reject(response.data.error);
                } else {
                    resolve(response.data);
                }
            })
            .catch((error) => {
                console.log(`Error`, error);
                reject(error);
            });
    });
}

// Return all VINs requests
/*
  token: string, required (FCA OAuth/Cognito Access Token)
*/
async function queryVINsRequestsAPI(token) {
    let apiurl = process.env.REACT_APP_BETA_SITE_LAMBDA + "/queryvinsrequests";
    const options = {
        method: "get",
        url: apiurl,
        headers: {
            authorization: "Bearer " + token
        }
    };
    return new Promise((resolve, reject) => {
        axios(options)
            .then((response) => {
                console.log("Query vins requests lambda response", response.data);
                resolve(response.data);
            })
            .catch((error) => {
                console.log(`Error`, error);
                reject(error);
            });
    });
}

// Approve/Deny VINs request
/* 
  info: { (required)
    uconnect_email: string, required 
    beta_site_email: string, required
  }
  approve: boolean, required
  token: string, required (FCA OAuth/Cognito Access Token)
*/
async function approveVINsRequestAPI(info, approve, token) {
    let apiurl = process.env.REACT_APP_BETA_SITE_LAMBDA + "/approvevins?approve=" + approve;
    const options = {
        method: "post",
        url: apiurl,
        data: {
            uconnect_email: info.uconnect_email,
            beta_site_email: info.beta_site_email
        },
        headers: {
            authorization: "Bearer " + token
        }
    };
    console.log("approve vins options", options);
    return new Promise((resolve, reject) => {
        axios(options)
            .then((response) => {
                console.log("Approve vins requests lambda response", response.data);
                if (response.data.hasOwnProperty("error")) {
                    reject(response.data);
                } else {
                    resolve(response.data);
                }
            })
            .catch((error) => {
                console.log(`Error`, error);
                reject(error);
            });
    });
}

// Adds email of interested regular (non-FCA) user to beta-site-interested-users
/* 
  email: string, required
*/
async function addInterestedUserAPI(email) {
    let apiurl = process.env.REACT_APP_BETA_SITE_LAMBDA + "/addinteresteduser";
    const options = {
        method: "post",
        url: apiurl,
        headers: {
            email: email
        }
    };
    return new Promise((resolve, reject) => {
        axios(options)
            .then((response) => {
                console.log("Add interested user lambda response", response.data);
                if (response.data.hasOwnProperty("error")) {
                    reject(response.data.error);
                } else {
                    resolve(response.data);
                }
            })
            .catch((error) => {
                console.log(`Error`, error);
                reject(error);
            });
    });
}

/* Delete feature using feature_id and author
/* 
  feature_id: string, required
  author: string, required
  token: string, required (FCA OAuth/Cognito Access Token)
*/
async function deleteVIN(vins_id, vins_nickname, author, token) {
    let apiurl = process.env.REACT_APP_BETA_SITE_LAMBDA + "/submitvinsrequest/delete";
    const options = {
        method: "post",
        url: apiurl,
        data: {
            vins_id: vins_id,
            vins_nicknames: vins_nickname,
            author: author
        },
        headers: {
            authorization: "Bearer " + token
        } /*  */
    };
    console.log("delete vins", options);
    return new Promise((resolve, reject) => {
        axios(options)
            .then((response) => {
                console.log("Delete vin lambda response", response.data);
                if (response.data.hasOwnProperty("error")) {
                    reject(response.data.error);
                } else {
                    resolve(response.data);
                }
                window.location.reload();
            })
            .catch((error) => {
                console.log(`Error`, error);
                reject(error);
            });
    });
}

// Adds phone number to beta-auth-table
/*
  body: {
    email: string, required (email of user submitting, can be 'anonymous')
    feedback_body: string, required
    feedback_title: string, required
    is_bug_report: boolean, required
  }
*/
async function addPhoneNumber(phoneNumber, gigyaToken) {
    let gigyaAxiosInstance = CreateStellaNovaInstance();
    try {
        return await gigyaAxiosInstance.post("/phone", {
            phone_number: phoneNumber,
            gigya_login_token: gigyaToken
        });
    } catch (error) {
        console.error(error);
    }
}

// Finds user in beta-site-users database and beta-auth-table
/*
  email: string, required
  token: string, required (FCA OAuth/Cognito Access Token)
*/
async function findUserBetaAPI(email, token) {
    let apiurl = process.env.REACT_APP_BETA_SITE_LAMBDA + "/finduserbeta";
    const options = {
        method: "get",
        url: apiurl,
        headers: {
            email: email,
            authorization: "Bearer " + token
        }
    };
    console.log("Find user beta Headers:", options.headers);
    return new Promise((resolve, reject) => {
        axios(options)
            .then((response) => {
                console.log("Find user beta lambda response", response.data);
                resolve(response.data);
            })
            .catch((error) => {
                console.log(`Error`, error);
                reject(error);
            });
    });
}

function CreateStellaNovaInstance(bearer_token = null) {
    let apiurl = process.env.REACT_APP_STELLANOVA_BACKEND_API_URL;

    const defaultConfig = {
        baseURL: apiurl,
        params: {},
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${bearer_token}`
        },
        data: {}
    };
    let gigyaAxiosInstance = axios.create(defaultConfig);
    return gigyaAxiosInstance;
}

async function GigyaLogin(gigyaAxiosInstance, userName, password) {
    try {
        return await gigyaAxiosInstance.post("/login", {
            gigya_email: userName,
            gigya_password: password
        });
    } catch (error) {
        console.error(error);
        return error;
    }
}

async function TokenGigyaLoginRequest(gigyaAxiosInstance, gigyaToken) {
    try {
        return await gigyaAxiosInstance.post("/login", {
            gigya_login_token: gigyaToken
        });
    } catch (error) {
        console.error(error);
    }
}

async function OAuthLoginRequest(gigyaAxiosInstance, oAuthCode) {
    try {
        return await gigyaAxiosInstance.post("/login", {
            code: oAuthCode
        });
    } catch (error) {
        console.error(error);
    }
}

async function UserOAuthLogin(oAuthCode) {
    let gigyaInstance = CreateStellaNovaInstance();
    return OAuthLoginRequest(gigyaInstance, oAuthCode);
}

async function UserGigyaLogin(userName, password) {
    let gigyaInstance = CreateStellaNovaInstance();
    return GigyaLogin(gigyaInstance, userName, password);
}

async function TokenGigyaLogin(gigyaToken) {
    let gigyaInstance = CreateStellaNovaInstance();
    return TokenGigyaLoginRequest(gigyaInstance, gigyaToken);
}

async function AcceptLegalTerms(gigyaToken) {
    let gigyaInstance = CreateStellaNovaInstance(localStorage.getItem("fca_token"));
    try {
        return await gigyaInstance.post("/legal", {
            gigya_login_token: gigyaToken
        });
    } catch (error) {
        console.error(error);
    }
}

// Sends feedback to the Stellanova mobile API
/* 
  author: string, required
  feedback_title: string, required
  feedback_body: string, required
  is_bug_report: boolean, required
*/
async function sendFeedback(feedback_info, token) {
    let apiurl = process.env.REACT_APP_STELLANOVA_MOBILE_API_URL;

    const options = {
        method: "POST",
        url: `${apiurl}/feedback-mobile`,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`
        },
        data: {
            author: feedback_info.author,
            feedback_title: feedback_info.feedback_title,
            feedback_body: feedback_info.feedback_body,
            is_bug_report: feedback_info.is_bug_report,
            feedback_topic: feedback_info.feedback_topic,
            origin: "web"
        }
    };
    return new Promise((resolve, reject) => {
        axios(options)
            .then((response) => {
                console.log("Submit feedback response", response);
                resolve(response);
            })
            .catch((error) => {
                console.log("Submit feedback error", error);
                reject(error);
            });
    });
}

async function fetchFeatures(bearerToken) {
    let apiurl = process.env.REACT_APP_STELLANOVA_MOBILE_API_URL;

    const defaultConfig = {
        baseURL: apiurl,
        params: {},
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${bearerToken}`
        },
        data: {}
    };
    let featuresInstance = axios.create(defaultConfig);

    try {
        return await featuresInstance.get("/features-list");
    } catch (error) {
        console.error(error);
        return error;
    }
}

async function EnrollInSpace(email, first_name, last_name, brand, operating_system) {
    let gigyaInstance = CreateStellaNovaInstance(localStorage.getItem("fca_token"));
    try {
        return await gigyaInstance.post("/space_beta", {
            first_name: first_name,
            last_name: last_name,
            email: email,
            brand: brand,
            operating_system: operating_system
        });
    } catch (error) {
        console.error(error);
    }
}

function createStellanovaServicesInstance(bearer_token = null) {
    let apiurl = process.env.REACT_APP_STELLANOVA_SERVICES_API_URL;

    const defaultConfig = {
        baseURL: apiurl,
        params: {},
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${bearer_token}`
        },
        data: {}
    };
    let gigyaAxiosInstance = axios.create(defaultConfig);
    return gigyaAxiosInstance;
}

async function getMoonbaseSequences() {
    const gigyaInstance = createStellanovaServicesInstance(localStorage.getItem("fca_token"));
    try {
        const response = await gigyaInstance.get("/moonbase-service");
        if (response.data.message !== undefined) {
            response.data = [];
        }
        return response;
    } catch (error) {
        return {
            statusCode: 500,
            message: error
        };
    }
}

async function postMoonbaseSequence(body) {
    const gigyaInstance = createStellanovaServicesInstance(localStorage.getItem("fca_token"));
    try {
        const response = await gigyaInstance.post("/moonbase-service", body);
        return response;
    } catch (error) {
        return {
            statusCode: 500,
            message: error
        };
    }
}

async function putMoonbaseSequence(body) {
    const gigyaInstance = createStellanovaServicesInstance(localStorage.getItem("fca_token"));
    try {
        const response = await gigyaInstance.put("/moonbase-service", body);
        return response;
    } catch (error) {
        return {
            statusCode: 500,
            message: error
        };
    }
}

async function deleteMoonbaseSequence(seqId, vin = "None") {
    let body = {
        data: {
            sequence_id: seqId,
            vin: vin
        }
    };
    const gigyaInstance = createStellanovaServicesInstance(localStorage.getItem("fca_token"));
    try {
        const response = await gigyaInstance.delete("/moonbase-service", body);
        return response;
    } catch (error) {
        return {
            statusCode: 500,
            message: error
        };
    }
}

async function deleteMoonbaseVinInstance(vin, seqId) {
    const body = {
        data: {
            vin: vin,
            sequence_id: seqId
        }
    };
    const gigyaInstance = createStellanovaServicesInstance(localStorage.getItem("fca_token"));
    try {
        const response = await gigyaInstance.delete("/moonbase-service", body);
        return response;
    } catch (error) {
        return {
            statusCode: 500,
            message: error
        };
    }
}

async function StellanovaUnenroll(unenrollmentString) {
    let apiurl = process.env.REACT_APP_STELLANOVA_BACKEND_API_URL;

    const defaultConfig = {
        baseURL: apiurl,
        params: {},
        headers: {
            "Content-Type": "application/json"
        },
        data: {}
    };
    let stellanovaAxiosInstance = axios.create(defaultConfig);

    try {
        const response = await stellanovaAxiosInstance.post(`/unenroll`, {
            unenrollment_string: unenrollmentString
        });
        return response;
    } catch (error) {
        console.log(error);
        return error;
    }
}

export {
    deleteMoonbaseSequence,
    deleteMoonbaseVinInstance,
    getMoonbaseSequences,
    postMoonbaseSequence,
    putMoonbaseSequence,
    get_access_token,
    authorizeUser,
    findUserAPI,
    addUserAPI,
    deleteUserAPI,
    checkStatusAPI,
    devRequestAPI,
    getUploadURLAPI,
    addFeatureAPI,
    findFeatureAPI,
    queryFeaturesAPI,
    findFeaturesAPI,
    approveFeatureAPI,
    deleteFeatureAPI,
    queryDevRequestsAPI,
    approveDevRequestAPI,
    verifyEmailAPI,
    submitVINsRequestAPI,
    queryVINsRequestsAPI,
    approveVINsRequestAPI,
    setUconnectInfoAPI,
    resetCodeAPI,
    addInterestedUserAPI,
    deleteVIN,
    addPhoneNumber,
    findUserBetaAPI,
    UserGigyaLogin,
    TokenGigyaLogin,
    AcceptLegalTerms,
    sendFeedback,
    fetchFeatures,
    UserOAuthLogin,
    EnrollInSpace,
    StellanovaUnenroll
};
