import React, { Component } from "react";
import Lottie from "react-lottie";
import loadingData from "../img/uconnect_main.json";

class Loading extends Component {
    render() {
        return (
            <div
                style={{
                    position: "absolute",
                    left: "50%",
                    top: "50%",
                    transform: "translate(-50%, -50%)"
                }}
            >
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <Lottie
                        options={{
                            loop: true,
                            autoplay: true,
                            animationData: loadingData,
                            rendererSettings: {
                                preserveAspectRatio: "xMidYMid slice"
                            }
                        }}
                        height={40}
                        width={40}
                    />
                </div>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        fontFamily: "Roboto-Light",
                        fontSize: "12px",
                        paddingLeft: "0.4em"
                    }}
                >
                    {this.props.component
                        ? "Loading " + this.props.component + "..."
                        : "Loading..."}
                </div>
            </div>
        );
    }
}

export default Loading;
