import React from "react";
import "../../css/fonts.css";

const Text = (props) => {
    return Object.keys(props.text_instructions).map(function (key) {
        return (
            <div
                key={key}
                style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "1em",
                    paddingBottom: "1em",
                    textAlign: "center",
                    fontSize: props.is_mobile ? "14px" : "16px"
                }}
            >
                {props.text_instructions[key]
                    ? props.text_instructions[key].split("\n").map((text, index) => (
                          <React.Fragment key={`${text}-${index}`}>
                              {text}
                              <br />
                          </React.Fragment>
                      ))
                    : null}
            </div>
        );
    });
};

export default Text;
