import React from "react";
import "../../css/EvenRow.css";

const EvenRow = (props) => {
    const CreateDescriptionHTML = (htmlString) => {
        return { __html: htmlString };
    };

    return (
        <div
            id={props.id.toLowerCase()}
            style={{ backgroundImage: `url(${props.imageUrl})`, backgroundSize: "cover" }}
            className="flex-container"
        >
            <img className="sr-only" alt={props.imageAltText} src={props.imageUrl} />
            <div className="flex-left">
                <div className="text-div">
                    <h1>{props.title}</h1>
                    {props.description_html ? (
                        <div
                            dangerouslySetInnerHTML={CreateDescriptionHTML(props.description_html)}
                        ></div>
                    ) : (
                        <p>{props.description}</p>
                    )}
                    {props.setup_instruction.length !== 0 ? (
                        <>
                            <p>INSTRUCTIONS</p>
                            <ol className={"feature-instructions"}>
                                {props.setup_instruction.map((instruction) => {
                                    return <li>{instruction}</li>;
                                })}
                            </ol>
                        </>
                    ) : null}
                    {props.links.length !== 0 ? (
                        <>
                            <p>LINKS</p>
                            <p>
                                {props.links.map((link) => {
                                    return (
                                        <div>
                                            <a href={link.link_url}>{link.link_text}</a>
                                        </div>
                                    );
                                })}
                            </p>
                        </>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default EvenRow;
