import React from "react";
import "../../css/fonts.css";

const Links = (props) => {
    return Object.keys(props.link_instructions_text).map(function (key) {
        return (
            <div
                key={key}
                style={{
                    display: "flex",
                    justifyContent: "center",
                    paddingTop: "0.5em",
                    paddingBottom: "0.5em",
                    fontSize: props.is_mobile ? "14px" : "16px"
                }}
            >
                {props.link_instructions_text[key]
                    ? props.link_instructions_text[key].split("\n").map((text, index) => (
                          <React.Fragment key={`${text}-${index}`}>
                              <a
                                  href={
                                      props.link_instructions_links[key]
                                          ? props.link_instructions_links[key]
                                          : ""
                                  }
                                  target="_blank"
                                  rel="noopener noreferrer"
                              >
                                  {text}
                              </a>
                              <br />
                          </React.Fragment>
                      ))
                    : null}
            </div>
        );
    });
};

export default Links;
