import React, { useEffect, useState } from "react";
import { StellanovaUnenroll } from "../../api";
import { useLocation } from "react-router-dom";
import { LinearProgress } from "@material-ui/core";
import Loading from "../../components/Loading";
import "../../css/Unenroll.css";


const Unenroll = (props) => {
    const [unenrollLoading, setUnenrollLoading] = useState(false);
    const [unenrollFail, setUnenrollFail] = useState("");

    const location = useLocation();
    const queryParameters = new URLSearchParams(location.search);

    useEffect(() => {
        if (queryParameters.get("unenrollment_string")) {
            StellanovaUnenrollRequest(queryParameters.get("unenrollment_string"));
        }
    }, []);

    const StellanovaUnenrollRequest = async (unenrollment_string) => {
        setUnenrollLoading(true);
        let unenrollResponse = await StellanovaUnenroll(unenrollment_string);
        console.log(unenrollResponse);
        if (!unenrollResponse && !unenrollResponse.response) {
            setUnenrollLoading(false);
            return null;
        }
        if (unenrollResponse.response) {
            if (unenrollResponse.response.status === 400) {
                setUnenrollLoading(false);
                setUnenrollFail("This enrollment is not found");
                console.log("This enrollment is not found");
                return null;
            } else {
                setUnenrollLoading(false);
                setUnenrollFail("Unenrollment failed");
                console.log("Unenrollment failed");
                return null;
            }
        }
        if (unenrollResponse.status === 200) {
            setUnenrollFail("Unenollment was successful!")
            console.log("success");
        }
        setUnenrollLoading(false);
    };

    return <div className="unenroll-page">
        <div className="main">
            <h1 className="unenroll-page-title">Unenrollment result</h1>

            <div id="unenrollment-result">
                {
                    queryParameters.get("unenrollment_string") ?
                    <p>{
                        unenrollLoading ? <LinearProgress /> : unenrollFail
                    }</p>
                    :
                    <p>Invalid link. Please use the link sent to your inbox to unenroll from the Stellanova program.</p>
                }
                
            </div>
        </div>
    </div>;
};

export default Unenroll;
