import React, { Component } from "react";
import { Redirect, withRouter } from "react-router";
import { connect } from "react-redux";
import LoadPageAuthCheck from "../../components/LoadPageAuthCheck";
import Loading from "../../components/Loading";
import { findFeatureAPI } from "../../api";
import DisplayFeature from "./DisplayFeature";
import "../../css/fonts.css";

class Feature extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_mobile: false,
            jumbotron_width: "50em",
            user_info: {},
            feature: {},
            feature_loading: true,
            redirect_to_root: false
        };
    }
    componentDidMount() {
        window.addEventListener("resize", this.handleWindowSizeChange);
        this.handleWindowSizeChange();

        if (localStorage.getItem("fca_token")) {
            this.props.dispatch({ type: "LOADING" });
        } else {
            localStorage.removeItem("fca_token")
            this.props.dispatch({ type: "LOGOUT" });
        }
        let feature_id = this.props.match.params.feature_id;
        this.findFeature(feature_id);
    }
    findFeature(feature_id) {
        try {
            findFeatureAPI(feature_id, localStorage.getItem("fca_token"))
                .then(async (result) => {
                    console.log("Find feature result:", result);
                    if (Object.keys(result.data).length !== 0) {
                        let text = false,
                            images = false,
                            links = false;

                        if (result.data.hasOwnProperty("text_instructions")) {
                            if (result.data.text_instructions.length !== 0) {
                                text = true;
                            }
                        }
                        if (result.data.hasOwnProperty("s3_instructions")) {
                            if (result.data.s3_instructions.length !== 0) {
                                images = true;
                            }
                        }
                        if (result.data.hasOwnProperty("link_instructions_text")) {
                            if (result.data.link_instructions_text.length !== 0) {
                                links = true;
                            }
                        }

                        await this.setState({
                            feature: result.data,
                            text: text,
                            images: images,
                            links: links,
                            feature_loading: false
                        });
                    } else {
                        this.setState({ redirect_to_root: true });
                    }
                })
                .catch((error) => {
                    console.log("Find feature error:", error);
                    this.setState({ redirect_to_root: true });
                });
        } catch {
            console.log("Find feature error.");
            this.setState({ redirect_to_root: true });
        }
    }
    logout() {
        localStorage.removeItem("fca_token")
        this.props.dispatch({ type: "LOGOUT" });
    }
    handleWindowSizeChange = () => {
        if (window.innerWidth <= 500) {
            this.setState({
                jumbotron_width: "22em",
                is_mobile: true
            });
        } else if (window.innerWidth > 500 && window.innerWidth <= 767) {
            this.setState({
                jumbotron_width: "30em",
                is_mobile: true
            });
        } else {
            this.setState({
                jumbotron_width: "47em",
                is_mobile: false
            });
        }
    };
    render() {
        if (this.props.loading) {
            return (
                <div>
                    <Loading />
                    <LoadPageAuthCheck {...this.props} />
                </div>
            );
        } else if (!this.props.logged_in) {
            return <Redirect to="/" />;
        } else if (this.state.feature_loading) {
            return (
                <div>
                    <Loading />
                </div>
            );
        } else if (this.state.redirect_to_root) {
            return <Redirect to="/" />;
        } else if (
            this.state.feature.approval_status !== "public" &&
            this.state.feature.approval_status !== "internal" &&
            !this.props.is_preview &&
            !this.props.user_info.is_admin
        ) {
            return <Redirect to="/" />;
        } else if (this.props.is_preview && !this.props.user_info.is_admin) {
            return <Redirect to="/" />;
        } else {
            return (
                <DisplayFeature
                    feature={{
                        title: this.state.feature.title,
                        description: this.state.feature.description,
                        s3_logo_large: this.state.feature.s3_logo_large,
                        s3_instructions: this.state.feature.s3_instructions,
                        text_instructions: this.state.feature.text_instructions,
                        link_instructions_text: this.state.feature.link_instructions_text,
                        link_instructions_links: this.state.feature.link_instructions_links,
                        links_first: this.state.feature.links_first
                    }}
                    text={this.state.text}
                    images={this.state.images}
                    links={this.state.links}
                    is_preview={this.props.is_preview}
                    jumbotron_width={this.state.jumbotron_width}
                    is_mobile={this.state.is_mobile}
                />
            );
        }
    }
}

const mapStateToProps = (state) => ({
    logged_in: state.logged_in,
    loading: state.loading,
    user_info: state.user_info
});

export default withRouter(connect(mapStateToProps)(Feature));
