import React, { Component } from "react";
import { Row, Col } from "reactstrap";
import { Redirect } from "react-router";
import { connect } from "react-redux";
import LoadPageAuthCheck from "../../components/LoadPageAuthCheck";
import Loading from "../../components/Loading";
import "../../css/fonts.css";
import "../../css/Profile.css";


class Profile extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_mobile: false
        };
    }
    componentDidMount() {
        window.addEventListener("resize", this.handleWindowSizeChange);
        this.handleWindowSizeChange();
        if (localStorage.getItem("fca_token")) {
            this.props.dispatch({ type: "LOADING" });
        } else {
            localStorage.removeItem("fca_token")
            this.props.dispatch({ type: "LOGOUT" });
        }
    }
    handleWindowSizeChange = () => {
        if (window.innerWidth <= 767) {
            this.setState({
                is_mobile: true
            });
        } else {
            this.setState({
                is_mobile: false
            });
        }
    };


    UserInfo = () => {
        let row_style = {
            padding: "14px"
        };

        return (
            <div
                style={{
                    fontSize: "26px",
                    width: this.state.is_mobile ? "90%" : "75%",
                    backgroundColor: "rgba(52, 52, 52, 0.75)",
                    borderRadius: "18px",
                    color: "white",
                    padding: "2.5rem",
                    overflowWrap: "break-word",
                    maxWidth: "700px"
                }}
            >
                <Row style={row_style}>
                    <Col xs={4}>
                        <p>First Name</p>
                    </Col>
                    <Col>
                        <p>
                            {this.props.user_info.first_name ? this.props.user_info.first_name : "N/A"}
                        </p>
                    </Col>
                </Row>
                <Row style={row_style}>
                    <Col xs={4}>
                        <p>Last Name</p>
                    </Col>
                    <Col>
                        <p>
                            {this.props.user_info.last_name ? this.props.user_info.last_name : "N/A"}
                        </p>
                    </Col>
                </Row>
                <Row style={row_style}>
                    <Col xs={4}>
                        <p>Email</p>
                    </Col>
                    <Col>
                        <p>
                            {this.props.user_info.uconnect_email_address ? this.props.user_info.uconnect_email_address : "N/A"}
                        </p>
                    </Col>
                </Row>
                <Row style={row_style}>
                    <Col xs={4}>
                        <p>Phone</p>
                    </Col>
                    <Col>
                        <p>
                            {this.props.user_info.phone_number ? this.props.user_info.phone_number : "N/A"}
                        </p>
                    </Col>
                </Row>
                <Row style={row_style}>
                    <Col xs={4}>
                        <p>
                            {
                                Object.keys(this.props.user_info.owned_vins).length > 1 ? "VINs" : "VIN"
                            }
                        </p>
                    </Col>
                    <Col>
                        <div>
                            {
                                Object.keys(this.props.user_info.owned_vins).map(function (key) {
                                    return(
                                        <p>{key}</p>
                                    )
                                })
                            }
                        </div>
                    </Col>
                </Row>
            </div>
        );
    };
    

    render() {
        if (this.props.loading) {
            return (
                <div>
                    <Loading />
                    <LoadPageAuthCheck {...this.props} />
                </div>
            );
        } else if (!this.props.logged_in) {
            return <Redirect to="/" />;
        } else {
            return (
                <div className="profile-page" style={{ paddingTop: this.state.is_mobile ? "4em" : "0", width: "100%", minHeight: "100vh" }}>
                    <div className="main">
                        <h1 className="profile-page-title">Profile</h1>
                        {this.UserInfo()}
                    </div>
                </div>
            );
        }
    }
}
const mapStateToProps = (state) => ({
    logged_in: state.logged_in,
    loading: state.loading,
    user_info: state.user_info
});

export default connect(mapStateToProps)(Profile);
