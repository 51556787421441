import React from "react";
import { Media } from "reactstrap";
import "../../css/fonts.css";

const Images = (props) => {
    return Object.keys(props.s3_instructions).map(function (key) {
        return (
            <div key={key}>
                <hr className="my-2" />
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        textAlign: "center",
                        paddingBottom: "0.4em",
                        fontSize: props.is_mobile ? "14px" : "16px"
                    }}
                >
                    {props.text && props.text_instructions[key]
                        ? props.text_instructions[key].split("\n").map((text, index) => (
                              <React.Fragment key={`${text}-${index}`}>
                                  {text}
                                  <br />
                              </React.Fragment>
                          ))
                        : null}
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <Media
                        object
                        src={props.s3_instructions[key]}
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            paddingBottom: "2em",
                            maxWidth: props.text
                                ? props.is_mobile
                                    ? "21em"
                                    : "35em"
                                : props.is_mobile
                                ? "21em"
                                : "30em",
                            maxHeight: props.text ? "35em" : "30em"
                        }}
                        alt="Instructions"
                    />
                </div>
            </div>
        );
    });
};

export default Images;
