import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router";
import LoadPageAuthCheck from "../../components/LoadPageAuthCheck";
import Body from "./Body";
import Loading from "../../components/Loading";
import Footer from "../../components/Footer";
import "../../css/fonts.css";

class GetStarted extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user_info: {},
            is_mobile: false,
            finding_user: false,
            redirect_to_signup: false,
            returning_visitor: true
        };
    }
    toggle_return_visitor = () => {
        this.setState(() => ({
            returning_visitor: !this.state.returning_visitor
        }));
    };
    componentDidMount() {
        window.addEventListener("resize", this.handleWindowSizeChange);
        this.handleWindowSizeChange();
        if (this.props.location.search.includes("code")) {
            this.props.dispatch({ type: "LOADING" });
        } else if (localStorage.getItem("fca_token")) {
            this.props.dispatch({ type: "LOADING" });
        } else {
            localStorage.removeItem("fca_token")
            this.props.dispatch({ type: "LOGOUT" });
        }

        const return_visitor = localStorage.getItem("return_visitor");
        if (!return_visitor) {
            localStorage.setItem("return_visitor", 1);
            this.setState(() => ({
                returning_visitor: false
            }));
        }
    }
    handleWindowSizeChange = () => {
        if (window.innerWidth <= 767) {
            this.setState({
                is_mobile: true
            });
        } else {
            this.setState({
                is_mobile: false
            });
        }
    };
    render() {
        if (this.props.loading) {
            return (
                <div>
                    <Loading />
                    <LoadPageAuthCheck {...this.props} />
                </div>
            );
        } else if (!this.props.logged_in) {
            return <Redirect to="/" />;
        } else {
            return (
                <div>
                    <Body
                        user_info={this.props.user_info}
                        is_mobile={this.state.is_mobile}
                        token={localStorage.getItem("fca_token")}
                    />
                    <div style={{ display: "flex", justifyContent: "center", backgroundColor: "black", color: "white" }}>
                        <Footer is_mobile={this.state.is_mobile} />
                    </div>
                </div>
            );
        }
    }
}

const mapStateToProps = (state) => ({
    logged_in: state.logged_in,
    loading: state.loading,
    user_info: state.user_info
});

export default withRouter(connect(mapStateToProps)(GetStarted));
