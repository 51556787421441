import React from "react";
import { useRef, useEffect } from "react";
import "../css/AccordionItem.css";
import ArrowIcon from "../img/AccordionArrow.svg";
import { Button } from "reactstrap";
import { Image } from "react-bootstrap";


const AccordionItem = ({ faq, active, onToggle }) => {

    const { question, answer } = faq;
    const contentEl = useRef();
    const [isMobile, setIsMobile] = React.useState(false);
    const [usSelected, setUsSelected] = React.useState(true);
    useEffect(() => {
        if (window.innerWidth <= 767) {
            setIsMobile(true);
        }
        else {
            setIsMobile(false);
        }
    }, [window.innerWidth]);
    return (
        <div className={`accordion_item ${active ? "active" : ""}`}>
            <button className={`${active ? "active-button" : "button"}`} onClick={onToggle}>
                <h2 style={{fontSize: isMobile ? "22px" : "40px", textAlign: "initial"}}>{question}</h2>
                <p className={`${active ? "active_accordion_icon" : ""}`}>
                    <Image src={ArrowIcon} />
                </p>
            </button>
            <div
                ref={contentEl}
                className="answer_wrapper"
                style={
                active
                    ? { height: contentEl.current.scrollHeight }
                    : { height: "0px" }
                }
            >
                <div className="answer"  style={{paddingBottom: "18px", paddingRight: "18px", fontSize: isMobile ? "12px" : "18px"}}>
                    {answer}
                    {
                        faq.us_vehicles && faq.canada_vehicles
                        ?
                        <div>
                            <Button
                                outline
                                onClick={() => {
                                    setUsSelected(true)
                                }}
                                size="sm"
                                color="primary"
                                active={usSelected ? true : false}
                            >
                                US
                            </Button>
                            <Button
                                outline
                                onClick={() => {
                                    setUsSelected(false)
                                }}
                                size="sm"
                                color="danger"
                                active={usSelected ? false : true}
                            >
                                Canada
                            </Button>
                            {
                                usSelected ? faq.us_vehicles : faq.canada_vehicles
                            }
                        </div>
                        :
                        null
                    }
                    
                </div>
            </div>
        </div>
  );
};

export default AccordionItem;