import React from "react";
import "../css/CircularButton.css";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

const CircularButton = ({ value, text, imageUrl, imageAltText, tooltipText, disabled, active, onClick }) => {
    return (
        <Box className="circular-button">
            {
                disabled ?
                <Button className={ [active ? 'active-circular-button-logo circular-button-logo' : 'circular-button-logo', disabled ? 'disabled-circular-button-logo' : ''] }
                    id={value} 
                    style={{backgroundImage: `url(${imageUrl})`, backgroundSize: 'cover', backgroundPosition: 'center'}}
                    onClick={e => null}
                >
                    <img className="sr-only" alt={imageAltText} src={imageUrl}/>
                </Button>
                :
                <Button className={ [active ? 'active-circular-button-logo circular-button-logo' : 'circular-button-logo', disabled ? 'disabled-circular-button-logo' : ''] }
                    id={value} 
                    style={{backgroundImage: `url(${imageUrl})`, backgroundSize: 'cover', backgroundPosition: 'center'}}
                    onClick={e => onClick(value)}
                >
                    <img className="sr-only" alt={imageAltText} src={imageUrl}/>
                </Button>
            }
            
            {
                text ?
                    <Typography className={active ? "active-logo-text": "logo-text"} variant="caption" display="block" gutterBottom>
                        {text}
                    </Typography>
                : null
            }
        </Box>
        
    )
}

export default CircularButton