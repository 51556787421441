import React from "react";

const Footer = (props) => {
    return (
        <div
            style={{
                padding: "1em",
                width: props.is_mobile ? "22em" : "40em",
                textAlign: "center"
            }}
        >
            <p
                style={{
                    fontFamily: "Roboto-Light",
                    fontSize: props.is_mobile ? "10px" : "11px",
                    margin: "0"
                }}
            >
                <h6>
                    Contact Us:{" "}
                    <a href="mailto: stellanova@shiftup.onmicrosoft.com">
                        stellanova@shiftup.onmicrosoft.com
                    </a>
                </h6>
                © {new Date().getFullYear()} FCA US LLC. All Rights Reserved. Chrysler, Dodge, Jeep,
                Ram, Mopar and SRT are registered trademarks of FCA US LLC. ALFA ROMEO and FIAT are
                registered trademarks of FCA Group Marketing S.p.A., used with permission.
                <br></br>
                <br></br>© {new Date().getFullYear()} FCA Canada Inc. All Rights Reserved.
            </p>
        </div>
    );
};

export default Footer;
