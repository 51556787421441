import React from "react";
import { useState } from "react";
import AccordionItem from "./AccordionItem";

const Accordion = (props) => {
  const [clicked, setClicked] = useState("0");

  const handleToggle = (index) => {
    if (clicked === index) {
      return setClicked("0");
    }
    setClicked(index);
  };

  return (
    <div className="accordion" style={{width: "100%", paddingLeft: "5%", overflowX: "hidden"}}>
      {props.faqs.map((faq, index) => (
        <AccordionItem
          onToggle={() => handleToggle(index)}
          active={clicked === index}
          key={index}
          faq={faq}
        />
      ))}
    </div>
  );
};

export default Accordion;