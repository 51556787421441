import React, { useState } from 'react';
import {
    Button,
    Modal,
    FormControl,
    InputLabel,
    FilledInput,
    IconButton
} from '@material-ui/core';
import '../../css/ScheduledRemoteOp.css';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import InputAdornment from '@material-ui/core/InputAdornment';

const SaveModal = ({ open, vin, handleSave, closeModal }) => {
    const [pin, setPin] = useState(null);
    const [isVisible, setIsVisible] = useState(false);

    const validatePin = (vpin) => {
        // const vpin = parseInt(tpin);
        if (isNaN(parseInt(vpin)) && vpin !== '') {
            return;
        }
        if (vpin > '9999' || vpin.length > 4) {
            return;
        } else if (vpin < '0' && !vpin.length === 0) {
            setPin('0');
        } else {
            setPin(vpin);
        }
    };

    const toggleVisibility = () => {
        setIsVisible(!isVisible);
    };

    return (
        <Modal
            open={open}
            onClose={closeModal}
        >
            <div className="sro-modal-container">
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                    <h3>Saving Schedules for</h3> 
                    <p style={{ fontSize: '1.5rem' }}>{vin}</p>
                    <p>
                        To save schedules, please enter your UConnect PIN below.
                    </p>
                    <FormControl variant="filled">
                        <InputLabel htmlFor="pin">PIN</InputLabel>
                        <FilledInput 
                            id="pin" 
                            value={pin === null ? '' : pin.toString()}
                            type={isVisible ? 'text' : 'password'}
                            onChange={(e) => validatePin(e.target.value)}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        onClick={() => toggleVisibility()}
                                    >
                                        {isVisible ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    <br />
                    <p style={{ textAlign: 'center' }}><b>
                        Please make sure PIN is correct. If PIN is incorrect, schedules may not execute. 
                    </b></p>
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Button
                            style={{ width: 80, margin: '1rem' }}
                            color='primary'
                            variant='contained'
                            onClick={() => handleSave(pin)}
                        >
                            Save
                        </Button> 
                        <Button
                            style={{ width: 80, margin: '1rem' }}
                            color='default'
                            variant='contained'
                            onClick={() => closeModal()}
                        >
                            Cancel
                        </Button> 
                    </div>
                </div>
            </div>
        </Modal>
    );
};


export default SaveModal; 
