import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import ButtonGroup from "@material-ui/core/ButtonGroup";
import "../../css/OddRow.css";
import "../../css/SpaceAppRow.css";
import Box from "@material-ui/core/Box";
import SpaceAppBrands from "./SpaceAppBrands";
import { EnrollInSpace } from "../../api";
import { connect } from "react-redux";

const EvenRowSpace = (props) => {
    const [operatingSystem, setOperatingSystem] = useState("android");
    const [selectedBrand, setSelectedBrand] = useState("lancia");
    const [enrollLoading, setEnrollLoading] = useState(false);
    const [enrollFail, setEnrollFail] = useState("");
    const [enrollSuccess, setEnrollSuccess] = useState(false);
    const [enrolledOperatingSystem, setEnrolledOperatingSystem] = useState("");
    const [enrolledBrand, setEnrolledBrand] = useState("");
    const [isValidSelection, setIsValidSelection] = useState(true);

    console.log(isValidSelection);

    const onEnrollClick = async () => {
        setEnrolledBrand(selectedBrand);
        setEnrolledOperatingSystem(operatingSystem);
        setEnrollLoading(true);
        setEnrollSuccess(false);
        if (operatingSystem !== "" && selectedBrand !== "") {
            const { uconnect_email_address, first_name, last_name } = props.user_info;
            const enrollInSpaceResponse = await EnrollInSpace(
                uconnect_email_address,
                first_name,
                last_name,
                selectedBrand,
                operatingSystem
            );
            if (enrollInSpaceResponse.status === 200) {
                setEnrollLoading(false);
                setEnrollFail("");
                setEnrollSuccess(true);
                return null;
            }
            setEnrollLoading(false);
            setEnrollFail("Uncaught Error");
        }
    };

    return (
        <div
            id={props.id.toLowerCase()}
            style={{ backgroundImage: `url(${props.imageUrl})`, backgroundSize: "cover" }}
            className="flex-container"
        >
            <img className="sr-only" alt={props.imageAltText} src={props.imageUrl} />
            <div className="flex-left">
                <div className="text-div">
                    <h3>Space App</h3>
                    <p>Select OS</p>
                    <Box>
                        <Button
                            className={[
                                "space-app-row-os-button",
                                operatingSystem === "android" ? "active-space-app-os-button" : ""
                            ]}
                            id={"android_button"}
                            onClick={() => setOperatingSystem("android")}
                        >
                            Android
                        </Button>
                        <Button
                            className={[
                                "space-app-row-os-button",
                                operatingSystem === "ios" ? "active-space-app-os-button" : ""
                            ]}
                            active={operatingSystem === "ios"}
                            id={"ios_button"}
                            onClick={() => setOperatingSystem("ios")}
                        >
                            iOS
                        </Button>
                    </Box>
                    <p>Select your brand</p>
                    <Box>
                        <SpaceAppBrands
                            selectedBrand={selectedBrand}
                            setSelectedBrand={setSelectedBrand}
                            selectedOperatingSystem={operatingSystem}
                            setIsValidSelection={setIsValidSelection}
                        />
                    </Box>
                    <ButtonGroup>
                        {isValidSelection ? (
                            <Button className="space-app-row-enroll-button" onClick={onEnrollClick}>
                                {enrollLoading ? "Enrolling..." : "Enroll"}
                            </Button>
                        ) : (
                            <Button
                                className="space-app-row-enroll-button enroll-button-disabled"
                                onClick={onEnrollClick}
                                disabled={true}
                            >
                                {enrollLoading ? "Enrolling..." : "Enroll"}
                            </Button>
                        )}
                    </ButtonGroup>
                    {enrollFail === "" ? null : <div className={"errorMessage"}>{enrollFail}</div>}
                    {enrollSuccess && !enrollFail && enrolledOperatingSystem === "ios" ? (
                        <div className={"paragraphs"}>
                            Successfully enrolled! Check your inbox for an invitation email.
                        </div>
                    ) : null}
                    {enrollSuccess && !enrollFail && enrolledOperatingSystem === "android" ? (
                        <div className={"paragraphs"}>
                            Successfully enrolled! You'll be approved by an administrator, please
                            review your email within 24 hours.
                        </div>
                    ) : null}
                    <div className={"paragraphs"}>
                        <a href={"https://forms.office.com/e/fzSPWSXFeF"}>
                            Give your feedback here!
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    logged_in: state.logged_in,
    loading: state.loading,
    user_info: state.user_info
});

export default connect(mapStateToProps)(EvenRowSpace);
