import React, { useState, useEffect } from "react";
import {
    Row,
    Col,
    Input,
    Button,
    Badge,
    Collapse,
    ListGroup,
    Alert,
    ButtonGroup,
    ListGroupItem
} from "reactstrap";
import { approveFeatureAPI, deleteFeatureAPI } from "../../api";
import "../../css/fonts.css";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { RiErrorWarningLine, RiGitRepositoryPrivateLine } from "react-icons/ri";
import { MdPublic } from "react-icons/md";
import { BsPeople } from "react-icons/bs";
import { AiFillEdit } from "react-icons/ai";

const Feature = (props) => {
    const [loadFeature, setLoadFeature] = useState(false);
    const [statusText, setStatusText] = useState("");
    const [statusColor, setStatusColor] = useState("");
    const [color, setColor] = useState("black");
    const [collapse, setCollapse] = useState(false);
    const [denyConfirm, setDenyConfirm] = useState(false);
    const [approveConfirm, setApproveConfirm] = useState(false);
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    const [notes, setNotes] = useState(props.feature.approval_notes);
    const [error, setError] = useState(null);
    const [submitting, setSubmitting] = useState(false);

    useEffect(() => {
        if (props.feature.approval_status === "public") {
            setStatusText("Public");
            setStatusColor("success");
        } else if (props.feature.approval_status === "internal") {
            setStatusText("Internal");
            setStatusColor("info");
        } else if (props.feature.approval_status === "waiting") {
            setStatusText("Waiting Approval");
            setStatusColor("warning");
        } else {
            setStatusText("Denied");
            setStatusColor("danger");
        }
    }, [props.feature.approval_status]);
    const toggleDeny = () => {
        setApproveConfirm(false);
        setDeleteConfirm(false);
        setDenyConfirm(!denyConfirm);
    };
    const toggleApprove = () => {
        setDenyConfirm(false);
        setDeleteConfirm(false);
        setApproveConfirm(!approveConfirm);
    };

    const toggleDelete = () => {
        setApproveConfirm(false);
        setDenyConfirm(false);
        setDeleteConfirm(!deleteConfirm);
    };

    const approveFeature = (feature, action, notes = false) => {
        setSubmitting(true);
        try {
            let approval_status;
            if (action === "approve") {
                // currently internal
                if (feature.approval_status === "internal") {
                    approval_status = "public";
                } else {
                    // currently waiting/denied
                    approval_status = "internal";
                }
            } else if (action === "unapprove") {
                // currently public
                if (feature.approval_status === "public") {
                    approval_status = "internal";
                } else {
                    // currently internal/waiting/denied
                    approval_status = "denied";
                }
            }
            let info = {
                author: feature.author,
                feature_id: feature.FeatureId,
                title: feature.title,
                approval_status: approval_status,
                approval_notes: notes
            };
            approveFeatureAPI(info, props.token)
                .then(async (result) => {
                    console.log("Approve feature result:", result);
                    window.location.reload(false);
                })
                .catch((error) => {
                    console.log("Approve feature error:", error);
                    try {
                        if (error.response.status === 401) {
                            window.location.reload(false);
                            return;
                        } else {
                            setError(true);
                        }
                    } catch (error) {
                        setError(true);
                    }
                });
        } catch {
            console.log("Approve feature error.");
            setError(true);
        }
    };
    const deleteFeature = (feature) => {
        setSubmitting(true);
        try {
            deleteFeatureAPI(feature.FeatureId, feature.author, props.token)
                .then((result) => {
                    console.log("Delete feature result:", result);
                    window.location.reload(false);
                })
                .catch((error) => {
                    console.log("Delete feature error:", error);

                    if (error.response.status === 401) {
                        window.location.reload(false);
                        return;
                    } else {
                        setError(true);
                    }
                });
        } catch {
            console.log("Delete feature error.");
            setError(true);
        }
    };

    const handleChange = async (event) => {
        const { target } = event;
        let value = target.value;
        const { name } = target;
        if (name === "notes") setNotes(value);
    };

    const TitleBar = () => {
        return (
            <Row>
                <Col style={{ paddingRight: "0" }}>
                    <Button
                        color="link"
                        onClick={() => {
                            setCollapse(!collapse);
                        }}
                        style={{
                            fontSize: props.is_mobile ? "13px" : "15px",
                            color: color,
                            paddingRight: "0.1em"
                        }}
                    >
                        <img
                            src={props.feature.s3_logo_small}
                            alt="Small Logo"
                            width="15em"
                            style={{ paddingBottom: "0.2em", marginRight: "0.5em" }}
                        />
                        {props.feature.title}
                        {collapse ? <IoIosArrowUp /> : <IoIosArrowDown />}
                    </Button>

                    <Badge color={statusColor}>
                        {props.feature.approval_status === "public" ? (
                            <MdPublic />
                        ) : props.feature.approval_status === "internal" ? (
                            <BsPeople />
                        ) : props.feature.approval_status === "waiting" ? (
                            <RiErrorWarningLine />
                        ) : (
                            <RiGitRepositoryPrivateLine />
                        )}
                    </Badge>
                </Col>

                <Col
                    xs="auto"
                    style={{ display: "flex", justifyContent: "right", paddingLeft: "0" }}
                >
                    {props.feature.approval_status === "denied" ||
                    props.feature.approval_status === "waiting" ? (
                        <Button
                            href={"/preview/" + props.feature.FeatureId}
                            target="_blank"
                            rel="noopener noreferrer"
                            color="link"
                            style={{ fontSize: props.is_mobile ? "12px" : "15px" }}
                        >
                            Preview
                        </Button>
                    ) : (
                        <Button
                            href={"/features/" + props.feature.FeatureId}
                            target="_blank"
                            rel="noopener noreferrer"
                            color="link"
                            style={{ fontSize: props.is_mobile ? "12px" : "15px" }}
                        >
                            Visit
                        </Button>
                    )}
                </Col>
            </Row>
        );
    };
    const Info = () => {
        return (
            <div>
                <p style={{ margin: "0" }}>
                    <b>Feature ID:</b> {props.feature.FeatureId}
                </p>
                <p style={{ margin: "0" }}>
                    <span
                        href={"#" + props.feature.FeatureId}
                        style={{ color: "#BCBCBC", padding: "0" }}
                    >
                        https://stellanova.io/features/
                    </span>
                    {props.feature.FeatureId}
                </p>
                <p style={{ margin: "0" }}>
                    <b>Author:</b> {props.feature.author}
                </p>
            </div>
        );
    };
    const Actions = () => {
        return (
            <div>
                <ButtonGroup>
                    <Button
                        href={"/admin/edit/" + props.feature.FeatureId}
                        size="sm"
                        target="_blank"
                        rel="noopener noreferrer"
                        color="warning"
                        style={{ fontSize: props.is_mobile ? "11px" : "13px" }}
                    >
                        Edit <AiFillEdit style={{ marginBottom: "0.3em" }} />
                    </Button>
                    <Button
                        size="sm"
                        color="danger"
                        active={deleteConfirm}
                        style={{ fontSize: props.is_mobile ? "11px" : "13px" }}
                        onClick={toggleDelete}
                    >
                        Delete
                    </Button>
                </ButtonGroup>
                <ButtonGroup>
                    {props.feature.approval_status === "public" ? null : (
                        <Button
                            outline
                            active={approveConfirm && !denyConfirm}
                            onClick={toggleApprove}
                            size="sm"
                            color="primary"
                            style={{ fontSize: props.is_mobile ? "11px" : "13px" }}
                        >
                            {props.feature.approval_status === "internal"
                                ? "Approve for Public"
                                : "Approve for Internal"}
                        </Button>
                    )}

                    <Button
                        outline
                        active={denyConfirm && !approveConfirm}
                        onClick={toggleDeny}
                        size="sm"
                        color="danger"
                        style={{ fontSize: props.is_mobile ? "11px" : "13px" }}
                    >
                        {props.feature.approval_status === "public"
                            ? "Make Internal Only"
                            : props.feature.approval_status === "internal"
                            ? "Unrelease"
                            : props.feature.approval_status === "waiting"
                            ? "Deny Request"
                            : "Edit and Resend Notes"}
                    </Button>
                </ButtonGroup>
            </div>
        );
    };

    const ConfirmApprove = () => {
        return (
            <Collapse isOpen={denyConfirm}>
                <p style={{ paddingTop: "0.5em", marginBottom: "0.5em" }}>Improvement Notes</p>
                <Input
                    style={{
                        fontSize: props.is_mobile ? "11px" : "13px",
                        height: "10em",
                        marginBottom: "0.5em"
                    }}
                    type="textarea"
                    name="notes"
                    defaultValue={
                        props.feature.approval_notes !== "" ? props.feature.approval_notes : ""
                    }
                    onChange={(e) => {
                        handleChange(e);
                    }}
                />
                <Button
                    size="sm"
                    color="danger"
                    disabled={notes === props.feature.approval_notes || submitting}
                    onClick={() => {
                        approveFeature(props.feature, "unapprove", notes);
                    }}
                    style={{ fontSize: props.is_mobile ? "11px" : "13px" }}
                >
                    Submit
                </Button>
            </Collapse>
        );
    };
    const ConfirmUnapprove = () => {
        return (
            <Collapse isOpen={approveConfirm}>
                <p style={{ paddingTop: "0.5em", margin: "0.5em", marginLeft: "0" }}>
                    Approve {props.feature.title}?
                </p>
                <Button
                    size="sm"
                    color="success"
                    disabled={submitting}
                    style={{ fontSize: props.is_mobile ? "11px" : "13px" }}
                    onClick={() => {
                        approveFeature(props.feature, "approve");
                    }}
                >
                    {props.feature.approval_status === "internal"
                        ? "Confirm Approval for Public Release"
                        : "Confirm Approval for Internal Release"}
                </Button>
            </Collapse>
        );
    };
    const ConfirmDelete = () => {
        return (
            <Collapse isOpen={deleteConfirm}>
                <p style={{ paddingTop: "0.5em", margin: "0.5em", marginLeft: "0" }}>
                    Delete {props.feature.title}?
                </p>
                <Button
                    size="sm"
                    color="danger"
                    disabled={submitting}
                    style={{ fontSize: props.is_mobile ? "11px" : "13px" }}
                    onClick={() => {
                        deleteFeature(props.feature);
                    }}
                >
                    Confirm Feature Deletion
                </Button>
            </Collapse>
        );
    };
    const CollapseComponent = () => {
        return (
            <Collapse isOpen={collapse}>
                <div
                    style={{
                        padding: "0.5em",
                        paddingLeft: "1em",
                        paddingTop: "0",
                        fontSize: props.is_mobile ? "12px" : "14px"
                    }}
                >
                    <Badge
                        style={{ fontSize: props.is_mobile ? "12px" : "12px" }}
                        color={statusColor}
                    >
                        {statusText}
                    </Badge>

                    {Info()}

                    {error ? (
                        <Alert color="danger" style={{ padding: "0.2em", paddingLeft: "0.4em" }}>
                            There has been an error. Please refresh and try again.
                        </Alert>
                    ) : (
                        <div style={{ fontSize: props.is_mobile ? "11px" : "13px" }}>
                            {Actions()}
                            {ConfirmApprove()}
                            {ConfirmUnapprove()}
                            {ConfirmDelete()}
                        </div>
                    )}
                </div>
            </Collapse>
        );
    };
    return (
        <div
            onMouseEnter={() => {
                setLoadFeature(true);
            }}
            onMouseLeave={() => {
                setLoadFeature(false);
            }}
        >
            <ListGroupItem
                id={props.feature.FeatureId}
                onMouseEnter={() => {
                    setColor("#0099F9");
                }}
                onMouseLeave={() => {
                    setColor("black");
                }}
                style={{ width: props.jumbotron_width, padding: "0", fontFamily: "Roboto-Light" }}
            >
                <div>
                    {TitleBar()}
                    {(loadFeature && props.parent_loaded) || collapse ? CollapseComponent() : null}
                </div>
            </ListGroupItem>
        </div>
    );
};

const Features = (props) => {
    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <ListGroup>
                {Object.keys(props.features).map(function (key) {
                    // Check if feature type checked to be visible
                    if (
                        (props.features[key].approval_status === "public" &&
                            !props.public_checked) ||
                        (props.features[key].approval_status === "internal" &&
                            !props.internal_checked) ||
                        (props.features[key].approval_status === "waiting" &&
                            !props.waiting_checked) ||
                        (props.features[key].approval_status === "denied" && !props.denied_checked)
                    ) {
                        return null;
                    } else {
                        return (
                            <Feature
                                key={key}
                                token={props.token}
                                parent_loaded={props.parent_loaded}
                                feature={props.features[key]}
                                jumbotron_width={props.jumbotron_width}
                                is_mobile={props.is_mobile}
                            />
                        );
                    }
                })}
            </ListGroup>
        </div>
    );
};

export default Features;
