import React, { useEffect, useState } from "react";
import "../../css/fonts.css";
import "../../css/ScheduledRemoteOp.css";
import { connect } from "react-redux";
import Loading from "../../components/Loading";
import LoadPageAuthCheck from "../../components/LoadPageAuthCheck";
import { Redirect } from 'react-router-dom';
import ScheduledROOptions from './ScheduledROOptions';
import LoginBackground from '../../img/loginbg.jpg';
import {
    getMoonbaseSequences,
    putMoonbaseSequence,
    postMoonbaseSequence,
    deleteMoonbaseSequence
} from "../../api";
import SaveModal from './SaveModal';

const ScheduledRemoteOp = props => {
    const [vinList, setVinList] = useState([]);
    const [curVin, setCurVin] = useState(null);
    const [sequences, setSequences] = useState([]);
    const [vinSequences, setVinSequences] = useState([]);
    const [deletedSequences, setDeletedSequences] = useState([]);
    const [newSeqIdCount, setNewSeqIdCount] = useState(0);
    const [saveModalOpen, setSaveModalOpen] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    // async create new sequence
    const handleAddSequence = () => {
        const seqId = `new-${newSeqIdCount}`;
        const sdp = props.user_info.owned_vins[curVin].sdp;
        const uid = props.user_info.uid;
        setNewSeqIdCount(newSeqIdCount + 1);
        setSequences([...sequences, {
            sequence_id: seqId,
            actions: [{
                action_type: 'remote_op',
                remote_op: 'RDU',
                pin: '1234',
                sdp: sdp,
                uid: uid,
                name: `Remote Op ${curVin}`,
                cooldown: 0,
                shared: []
            }],
            triggers: [{
                condition_type: 'time',
                cron: '0 0 * * *'
            }],
            association: uid,
            active: true,
            vins: [{
                vin: curVin,
                active: true,
                timezone: "America/New_York"
            }],
            shared: false,
            name: `sn-mb-service`,
            isNew: true
        }]);
    };

    const handleSave = async (pin) => {
        closeModal();
        setIsSaving(true);
        const [newSequences, updatedSequences] = buildMoonbaseRequests(pin);
        // send request for each
        
        // new sequences -> list of promises await all
        const newResults = await Promise.all(newSequences.map(s => {
            return postMoonbaseSequence(s); 
        }));

        // updated sequences -> list of promises await all
        const updatedResults = await Promise.all(updatedSequences.map(s => {
            return putMoonbaseSequence(s); 
        }));

        // deleted sequences -> list of promises await all
        const deletedResults = await Promise.all(deletedSequences.map(s => {
            return deleteMoonbaseSequence(s);
        }));

        // check for errors
        let error = false;
        newResults.concat(updatedResults).concat(deletedResults).forEach(res => {
            console.info(res)
            if (res.statusCode !== undefined && res.statusCode !== 200) {
                error = true;
                return;
            }
        });

        if (error) {
            alert('Error saving sequences.');
        }

        getSetMoonbaseSequences();
        setIsSaving(false);
    };

    const closeModal = () => {
        setSaveModalOpen(false);
    };

    // Returns [new sequences, updated sequences]
    const buildMoonbaseRequests = (pin) => {
        const newSequences = vinSequences.filter(s => {
            return s.isNew !== undefined && s.isNew === true;
        });

        const updatedSequences = vinSequences.filter(s => {
            return s.isNew === undefined && s.deleted === undefined;
        });


        return [
            moonbaseRequestBodyBuilder(pin, newSequences),
            moonbaseRequestBodyBuilder(pin, updatedSequences)
        ];
    };

    const moonbaseRequestBodyBuilder = (pin, sequences) => {
        return sequences.map(s => {
            const cron = s.triggers.find(t => t.condition_type === 'time').cron;
            const seqName = s.name;
            const seqId = s.sequence_id;
            const vin = curVin;
            const remoteOp = s.actions.find(a => { 
                return a.action_type === 'remote_op';
            }).remote_op;
            const timezone = s.vins.find(v => v.vin === vin).timezone;
            const active = s.active;

            return {
                sequence_id: seqId,
                vin: vin,
                pin: pin,
                sequence_name: seqName,
                name: seqName,
                cron_expression: cron,
                remote_op: remoteOp,
                timezone: timezone,
                active: active
            };
        });
    };

    useEffect(() => {
        if (localStorage.getItem("fca_token")) {
            props.dispatch({ type: "LOADING" });
        } else {
            localStorage.removeItem("fca_token");
            props.dispatch({ type: "LOGOUT" });
        }
    }, []);

    useEffect(() => {
        if (props.logged_in) {
            const vins = Object.keys(props.user_info.owned_vins);
            setVinList(vins);
            
            getSetMoonbaseSequences();
        }
    }, [props.logged_in]);

    const getSetMoonbaseSequences = () => {
        setIsSaving(true);
        // retrieve sequences from user info
        getMoonbaseSequences().then(res => {
            // filter for sequences with remote ops and time
            let ro_sequences = res.data || [];
            if (ro_sequences !== undefined || ro_sequences.length > 1) {
                ro_sequences = ro_sequences.filter(s => {
                    const hasRemoteOp = s.actions.filter(a => { 
                        return a.action_type === 'remote_op';
                    }).length > 0;

                    const hasTime = s.triggers.filter(t => {
                        return t.condition_type === 'time';
                    }).length > 0;

                    return hasTime && hasRemoteOp;
                }).map(s => {
                    // set vins to status of sequences
                    s.vins = s.vins.map(v => {
                        v.active = s.active;
                        return v;
                    });
                    return s;
                });
            }

            setSequences(ro_sequences);
            setIsSaving(false);
        });
        setDeletedSequences([]);
    };

    useEffect(() => {
        if (vinList === null) {
            return;
        }
        setCurVin(vinList[0] !== undefined ? vinList[0] : 'No VINS');
    }, [vinList]);

    useEffect(() => {
        setVinSequences(sequences.filter(s => {
            return s.vins.filter(v => {
                return v.vin === curVin;
            }).length > 0;
        }));
    }, [curVin, sequences]);


    const getSequenceById = (seqId) => {
        return sequences.filter(s => s.sequence_id === seqId)[0];
    };

    const setSequenceById = (seqId, sequence) => {
        const seqIdx = sequences.findIndex(s => s.sequence_id === seqId);
        setSequences([...sequences.slice(0, seqIdx), sequence, ...sequences.slice(seqIdx + 1)]);
    };

    const handleTimezoneChange = (seqId, timezone) => {
        const sequence = getSequenceById(seqId);
        const v = sequence.vins.find(v => v.vin === curVin);
        v.timezone = timezone;
        setSequenceById(seqId, sequence);
    };

    const handleDelete = (seqId) => {
        const sequence = getSequenceById(seqId);
        const seqIdx = sequences.findIndex(s => s.sequence_id === seqId);
        if (sequence.isNew === undefined) {
            setDeletedSequences([...deletedSequences, seqId]);
        }
        setSequences([...sequences.slice(0, seqIdx), ...sequences.slice(seqIdx + 1)]);
    };

    const handleDayClick = (seqId, day) => {
        const sequence = getSequenceById(seqId);
        const dayMap = {
            'Sunday': 7,
            'Monday': 1,
            'Tuesday': 2,
            'Wednesday': 3,
            'Thursday': 4,
            'Friday': 5,
            'Saturday': 6
        };
        const dayNum = dayMap[day];
        const t = sequence.triggers.find(t => {
            return t.condition_type === 'time';
        });
        const cronStr = t.cron.split(' '); 

        // get current values of day for cron string
        let curDays = [];
        if (cronStr[4] === '*') {
           curDays = Object.values(dayMap); 
        } else {
            curDays = cronStr[4].split(',').map(d => parseInt(d));
        }

        // toggle day
        const dayIdx = curDays.indexOf(dayNum);
        if (dayIdx === -1) {
            curDays.push(dayNum);
        } else {
            curDays.splice(dayIdx, 1);
        }

        // sort days
        curDays.sort((a, b) => a - b);

        // update cron string
        // if all days are selected, set cron string to '*'
        // else, set cron string to comma separated list of days
        let newCronStr = '';
        if (curDays.length === 7) {
            newCronStr = '*';
        } else {
            newCronStr = curDays.join(',');
        }

        // set cron string
        cronStr[4] = newCronStr;
        t.cron = cronStr.join(' ');
        if (t.cron.split(' ')[4] === '') {
            t.cron += '*';
        }

        setSequenceById(seqId, sequence);
    };

    const handleToggle = (seqId) => {
        const sequence = getSequenceById(seqId);
        const v = sequence.vins.find(v => v.vin === curVin);
        v.active = !v.active;
        sequence.active = v.active;
        setSequenceById(seqId, sequence);
    };

    const handleTimeChange = (seqId, time) => {
        const sequence = getSequenceById(seqId);
        const t = sequence.triggers.find(t => {
            return t.condition_type === 'time';
        });
        const cronStr = t.cron.split(' ');
        const [hour, min] = time.split(':');

        cronStr[0] = min;
        cronStr[1] = hour;

        t.cron = cronStr.join(' ');

        setSequenceById(seqId, sequence);
    };

    const handleRemoteOpChange = (seqId, remoteOp) => {
        const sequence = getSequenceById(seqId);

        const a = sequence.actions.find(a => {
            return a.action_type === 'remote_op';
        });

        a.remote_op = remoteOp;

        setSequenceById(seqId, sequence);
    };

    // render
    if (props.loading) {
        return (
            <div>
                <Loading />
                <LoadPageAuthCheck {...props} />
            </div>
        );
    } else if (!props.logged_in) {
        return <Redirect to="/" />;
    } else if (vinList === null || isSaving) {
        return (
            <Loading />
        );
    } else {
        return (
            // <div className="main" style={vinSequences.length >= 3 ? {
                //     backgroundImage: `url(${LoginBackground})`,
                // } : {}}>
            <div style={{ paddingTop: '4.5rem', width: '100%' }}>
                <div className="main">
                    <h1 className="sro-page-title">Scheduled Remote Ops</h1>
                    <br />
                    <ScheduledROOptions
                        vinList={vinList}
                        sequences={vinSequences}
                        curVin={curVin}
                        setCurVin={vin => setCurVin(vin)}
                        handleToggle={handleToggle}
                        handleDayClick={handleDayClick}
                        handleTimeChange={handleTimeChange}
                        handleRemoteOpChange={handleRemoteOpChange}
                        handleTimezoneChange={handleTimezoneChange}
                        handleAddSequence={handleAddSequence}
                        handleDelete={handleDelete}
                        openModal={() => setSaveModalOpen(true)}
                    />
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <SaveModal
                            open={saveModalOpen} 
                            handleSave={handleSave}
                            closeModal={closeModal}
                            vin={curVin}
                        />
                    </div>
                </div>
            </div>
        );
    }
};


const mapStateToProps = (state) => ({
    logged_in: state.logged_in,
    loading: state.loading,
    user_info: state.user_info
});

export default connect(mapStateToProps)(ScheduledRemoteOp);
