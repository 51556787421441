import React, { useState, useEffect } from "react";
import { Jumbotron, Media, Button } from "reactstrap";
import "../../css/fonts.css";
import { fetchFeatures } from "../../api";
import FeatureCardList from "./FeatureCardList";
import FeatureDetails from "./FeatureDetails";
import "../../css/fonts.css";
import Spinner from 'react-bootstrap/Spinner';

const Headers = (props) => {
    return props.is_mobile ? (
        <h3
            style={{
                display: "flex",
                justifyContent: "center",
                fontFamily: "Roboto",
                padding: "0em",
                margin: "0",
                color: "white"
            }}
        >
            Connected vehicle features at your fingertips
        </h3>
    ) : (
        <h1
            style={{
                display: "flex",
                justifyContent: "center",
                fontFamily: "Roboto",
                padding: "0.5em",
                margin: "0",
                color: "white",
                fontSize: "62px",
                fontWeight: "700"
            }}
        >
            Connected vehicle features at your fingertips
        </h1>
    );
};

const Description = (props) => {
    return props.is_mobile ? (
        <Media>
            <Media
                body
                style={{
                    textAlign: "center",
                    fontFamily: "Roboto",
                    fontSize: props.is_mobile ? "13px" : "16px",
                    color: "white"
                }}
            >
                Get the latest and greatest connected vehicle features by enrolling in the StellaNova program. Click a feature to learn more.
            </Media>
        </Media>
    ) : (
        <Media>
            <Media
                body
                style={{
                    textAlign: "center",
                    fontFamily: "Roboto",
                    fontSize: props.is_mobile ? "13px" : "16px",
                    color: "white",
                }}
            >
                Get the latest and greatest connected vehicle features by enrolling in the StellaNova program. Click a feature to learn more.
            </Media>
        </Media>
    );
};


const DevOptions = (props) => {
    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            {props.is_admin ? (
                <Button href="/admin" style={{ fontSize: "13px" }} color="link">
                    Go to Admin Console
                </Button>
            ) : props.is_developer ? (
                <Button href="/develop" style={{ fontSize: "13px" }} color="link">
                    Go to Developer Console
                </Button>
            ) : (
                <Button href="/apply" style={{ fontSize: "13px" }} color="link">
                    Apply to become a Developer
                </Button>
            )}
        </div>
    );
};

const Body = (props) => {

    const [features, setFeatures] = useState([]);
    const [featuresLoading, setFeturesLoading] = useState(true);

    useEffect(() => {

        const fetchFeaturesData = async () => {
            let features_response = await fetchFeatures(localStorage.getItem('fca_token'))
            setFeturesLoading(false);
            setFeatures(features_response.data.Items)
            localStorage.setItem('features', JSON.stringify(features_response.data.Items))
        }
        fetchFeaturesData()
    }, [])


    return (
        <div>
            <div style={{
                width: "100%", minHeight: "100vh",
                display: "flex", justifyContent: "center", alignItems: "center",
                paddingTop: "4rem", paddingBottom: "1rem", textAlign: "-webkit-center"
            }}>

                <div style={{ width: '80%' }}>
                    <Headers is_mobile={props.is_mobile} />

                    <Jumbotron
                        style={{
                            padding: "1em",
                            maxWidth: "250em",
                            backgroundColor: "transparent",
                            marginBottom: "0",
                            width: "70%"
                        }}
                    >
                        <Description is_mobile={props.is_mobile} />
                        <br></br>

                        {!props.user_info.email_verified ||
                            !props.user_info.email.includes("@fcagroup.com") ? null : (
                            <DevOptions
                                is_admin={props.user_info.is_admin}
                                is_developer={props.user_info.is_developer}
                            />
                        )}
                    </Jumbotron>
                    {
                        featuresLoading ?
                            <Spinner animation="border" role="status" variant="light">
                                <span className="visually-hidden"></span>
                            </Spinner>
                            :
                            <FeatureCardList
                                features={features}
                            />
                    }
                </div>
            </div>
            {
                featuresLoading ?
                    null
                    :
                    <div style={{ backgroundColor: 'black', display: 'flex', justifyContent: 'center' }}>
                        <FeatureDetails features={features} />
                    </div>
            }

        </div>
    );
};

export default Body;
