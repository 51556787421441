import React, { Component } from "react";
import { Jumbotron, Col, Button, Collapse } from "reactstrap";
import { Redirect } from "react-router";
import { connect } from "react-redux";
import LoadPageAuthCheck from "../../components/LoadPageAuthCheck";
import Loading from "../../components/Loading";
import Footer from "../../components/Footer";
import "../../css/fonts.css";
import Features from "./Features";

class DeveloperConsole extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_mobile: false,
            text_size: "1em",
            jumbotron_width: "40em",
            user_info: {}
        };
    }
    async componentDidMount() {
        window.addEventListener("resize", this.handleWindowSizeChange);
        this.handleWindowSizeChange();
        if (localStorage.getItem("fca_token")) {
            this.props.dispatch({ type: "LOADING" });
        } else {
            localStorage.removeItem("fca_token")
            this.props.dispatch({ type: "LOGOUT" });
        }
    }
    handleWindowSizeChange = () => {
        if (window.innerWidth <= 500) {
            this.setState({
                jumbotron_width: "19em",
                is_mobile: true
            });
        } else if (window.innerWidth > 500 && window.innerWidth <= 767) {
            this.setState({
                jumbotron_width: "30em",
                is_mobile: true
            });
        } else {
            this.setState({
                jumbotron_width: "47em",
                is_mobile: false
            });
        }
    };
    logout() {
        localStorage.removeItem("fca_token")
        this.props.dispatch({ type: "LOGOUT" });
    }
    // COMPONENTS
    Header = () => {
        return (
            <div>
                {this.state.is_mobile ? (
                    <h3
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            fontFamily: "Roboto-Light",
                            padding: "0em"
                        }}
                    >
                        Develop and Deploy
                    </h3>
                ) : (
                    <h1
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            fontFamily: "Roboto-Light",
                            padding: "0.5em"
                        }}
                    >
                        Develop and Deploy
                    </h1>
                )}
            </div>
        );
    };
    render() {
        if (this.props.loading) {
            return (
                <div>
                    <Loading />
                    <LoadPageAuthCheck findFeatures={true} {...this.props} />
                </div>
            );
        } else if (!this.props.logged_in) {
            return <Redirect to="/" />;
        } else if (!this.props.user_info.hasOwnProperty("owned_features_info")) {
            return (
                <div>
                    <Loading />
                </div>
            );
        } else {
            if (this.props.user_info.is_developer || this.props.user_info.is_admin) {
                return (
                    <div style={{ paddingTop: this.state.is_mobile ? "4em" : "0" }}>
                        {this.Header()}
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <Jumbotron
                                style={{
                                    padding: "0",
                                    width: this.state.jumbotron_width,
                                    backgroundColor: "transparent",
                                    fontFamily: "Roboto-Light"
                                }}
                            >
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <Collapse isOpen={true}>
                                        <Col>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center"
                                                }}
                                            >
                                                <h5>Your Features</h5>
                                            </div>

                                            {this.props.user_info.hasOwnProperty(
                                                "owned_features_info"
                                            ) ? (
                                                this.props.user_info.owned_features.length !== 0 ? (
                                                    <Features
                                                        feature_ids={
                                                            this.props.user_info.owned_features
                                                        }
                                                        features={
                                                            this.props.user_info.owned_features_info
                                                        }
                                                        author={this.props.user_info.email}
                                                        jumbotron_width={this.state.jumbotron_width}
                                                        is_mobile={this.state.is_mobile}
                                                    />
                                                ) : null
                                            ) : (
                                                <Loading />
                                            )}

                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "center",
                                                    paddingTop: "1em"
                                                }}
                                            >
                                                <Button
                                                    size={this.state.is_mobile ? "sm" : "md"}
                                                    color="primary"
                                                    href="/develop/deploy"
                                                >
                                                    Deploy a New Feature
                                                </Button>
                                            </div>
                                        </Col>
                                    </Collapse>
                                </div>
                            </Jumbotron>
                        </div>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <Footer is_mobile={this.state.is_mobile} />
                        </div>
                    </div>
                );
            } else {
                return <Redirect to="/" />;
            }
        }
    }
}

const mapStateToProps = (state) => ({
    logged_in: state.logged_in,
    loading: state.loading,
    user_info: state.user_info
});

export default connect(mapStateToProps)(DeveloperConsole);
