import { Component } from "react";
import { connect } from "react-redux";
import {
    get_access_token,
    authorizeUser,
    findUserBetaAPI,
    addUserAPI,
    findFeaturesAPI
} from "../api";

class LoadPageAuthCheck extends Component {
    // Dispatch user info and logged in status with redux
    async dispatchLoginInfo(append_to_info) {
        if (!this.state.user_info.hasOwnProperty("uconnect_email")) {
            await this.setState({
                user_info: Object.assign(this.state.user_info, {
                    uconnect_email: this.state.user_info.email
                })
            });
        }
        if (append_to_info) {
            this.props.dispatch({
                type: "LOGIN_WITH_INFO",
                payload: Object.assign(this.state.user_info, append_to_info)
            });
        } else {
            this.props.dispatch({
                type: "LOGIN_WITH_INFO",
                payload: this.state.user_info
            });
        }
    }
    // Add cookie (fca_token)
    addCookie() {
        let fca_token = this.state.headers.authorization.split("Bearer ")[1];
        console.log("Bearer token:", fca_token);
        localStorage.setItem("fca_token", fca_token);
    }
    // Abstracted login function
    login(append_to_info) {
        this.dispatchLoginInfo(append_to_info);
        this.addCookie();
    }
    // Abstracted maintain login function
    maintainLogin(append_to_info) {
        this.dispatchLoginInfo(append_to_info);
    }
    // Logout
    logout() {
        localStorage.removeItem("fca_token");
        this.props.dispatch({ type: "LOGOUT" });
    }
    componentDidMount() {
        // If OAuth Code in URL (only on /getstarted upon initial login)
        if (this.props.hasOwnProperty("location")) {
            if (
                this.props.location.search.includes("code") &&
                !localStorage.getItem("fca_token")
            ) {
                let fca_code = this.props.location.search.split("code=")[1];
                try {
                    get_access_token(fca_code)
                        .then(async (result) => {
                            if (result) {
                                await this.setState({
                                    user_info: result.data,
                                    headers: result.config.headers
                                });
                                this.findUser();
                                return;
                            } else {
                                this.logout();
                                return;
                            }
                        })
                        .catch((error) => {
                            this.logout();
                            return;
                        });
                } catch {
                    this.logout();
                    return;
                }
            }
        }
        // If OAuth Code not in URL (not initial sign in)
        //   -> If there is fca_token cookie
        if (localStorage.getItem("fca_token")) {
            try {
                authorizeUser(localStorage.getItem("fca_token"))
                    .then(async (result) => {
                        console.log(`Ayy ${result.data}`);
                        if (result.data.hasOwnProperty("uid")) {
                            this.props.dispatch({
                                type: "LOGIN_WITH_INFO",
                                payload: result.data
                            });
                        } else {
                            console.log("No Result");
                            this.logout();
                            return;
                        }
                    })
                    .catch((error) => {
                        this.logout();
                        return;
                    });
            } catch {
                console.log(`Maybe here?`);
                this.logout();
                return;
            }
        }
        // Otherwise logout
        else {
            this.logout();
            return;
        }
    }

    // Find user in beta-site-users DB
    findUser(maintainLogin = false) {
        try {
            findUserBetaAPI(
                this.state.user_info.email,
                this.state.headers.authorization.split("Bearer ")[1]
            )
                .then(async (result) => {
                    // User not found
                    if (Object.keys(result.data).length === 0) {
                        this.addUser();
                        if (maintainLogin) {
                            this.logout();
                            return;
                        }
                    } else {
                        await this.setState({
                            user_info: Object.assign(this.state.user_info, result.data)
                        });

                        // From Developer Console - get info of user's owned features
                        if (this.props.findFeatures) {
                            this.findFeatures(this.state.user_info.owned_features, maintainLogin);
                        } else {
                            if (maintainLogin) {
                                this.maintainLogin();
                            } else {
                                this.login();
                            }
                        }
                    }
                })
                .catch((error) => {
                    this.logout();
                    return;
                });
        } catch {
            this.logout();
            return;
        }
    }

    // Add new user if does not exist
    addUser() {
        try {
            addUserAPI(
                this.state.user_info.email,
                this.state.headers.authorization.split("Bearer ")[1]
            )
                .then(async (result) => {
                    console.log("Add user result:", result);
                    this.login();
                })
                .catch((error) => {
                    console.log("Add user error:", error);
                    this.logout();
                    return;
                });
        } catch {
            console.log("Add user error.");
            this.logout();
            return;
        }
    }

    // Find user's features if specified (only needed from Developer Console)
    findFeatures(feature_ids, maintainLogin = false) {
        try {
            findFeaturesAPI(feature_ids, this.state.headers.authorization.split("Bearer ")[1])
                .then(async (result) => {
                    console.log("Find features result:", result);
                    if (maintainLogin) {
                        this.maintainLogin({
                            owned_features_info: result.items
                        });
                    } else {
                        this.login({
                            owned_features_info: result.items
                        });
                    }
                })
                .catch((error) => {
                    console.log("Find features error:", error);
                    this.logout();
                    return;
                });
        } catch {
            console.log("Find features error.");
            this.logout();
            return;
        }
    }

    render() {
        return null;
    }
}

const mapStateToProps = (state) => ({
    logged_in: state.logged_in,
    loading: state.loading,
    user_info: state.user_info
});

export default connect(mapStateToProps)(LoadPageAuthCheck);
