import React from "react";
import { Link } from "react-router-dom";
import "../../css/FeatureCard.css";

const FeatureCard = ({id, title, imageUrl, imageAltText}) => {
    const isSRO = id.toLowerCase() === 'scheduled-remote-ops';
    const isMB = id.toLowerCase() === 'moonbase-features';
    if (isMB) {
        return (
            <a href={isMB ? '/moonbase-features' : `#${id.toLowerCase()}`}>
                <button className="feature-card">
                    <div className="feature-image" style={{backgroundImage: `url(${imageUrl})`}}>
                        <img className="sr-only" alt={imageAltText} src={imageUrl}/>
                    </div>
                    <p className="feature-title">{title}</p>
                </button>
            </a>
        )
    }
    else {
        return (
            <a href={isSRO ? '/scheduled-remote-ops' : `#${id.toLowerCase()}`}>
                <button className="feature-card">
                    <div className="feature-image" style={{backgroundImage: `url(${imageUrl})`}}>
                        <img className="sr-only" alt={imageAltText} src={imageUrl}/>
                    </div>
                    <p className="feature-title">{title}</p>
                </button>
            </a>
        );
    }
    
};

export default FeatureCard;
