import "../../css/BetaFeatures.css";
import "react-slideshow-image/dist/styles.css";
import React from "react";
import EvenRow from "./EvenRow";
import OddRow from "./OddRow";
import EvenRowSpace from "./EvenRowSpace";
import OddRowSpace from "./OddRowSpace";

const FeatureDetails = ({ features }) => {
    const renderRows = () => {
        return features.map((feature, index) => {
            if (index % 2 === 0 && feature.is_visible === true) {
                if (feature.id === "space") {
                    return (
                        <EvenRowSpace
                            key={feature.id}
                            id={feature.id}
                            title={feature.title}
                            imageUrl={feature.image}
                            imageAltText={feature.image_alt_text}
                            description={feature.description}
                        />
                    );
                } else if (feature.id === "scheduled-remote-ops") {
                    return <></>;
                }
                else if (feature.id === 'moonbase-features') {
                    return <></>;
                }
                return (
                    <EvenRow
                        key={feature.id}
                        id={feature.id}
                        title={feature.title}
                        imageUrl={feature.image}
                        imageAltText={feature.image_alt_text}
                        description={feature.description}
                        setup_instruction={feature.setup_instruction}
                        links={feature.links}
                        description_html={feature.description_html}
                    />
                );
            } else if (feature.is_visible === true) {
                if (feature.id === "space") {
                    return (
                        <OddRowSpace
                            key={feature.id}
                            id={feature.id}
                            title={feature.title}
                            imageUrl={feature.image}
                            imageAltText={feature.image_alt_text}
                            description={feature.description}
                        />
                    );
                } else if (feature.id === "scheduled-remote-ops") {
                    return <></>;
                }
                else if (feature.id === 'moonbase-features') {
                    return <></>;
                }
                return (
                    <OddRow
                        key={feature.id}
                        id={feature.id}
                        title={feature.title}
                        imageUrl={feature.image}
                        imageAltText={feature.image_alt_text}
                        description={feature.description}
                        setup_instruction={feature.setup_instruction}
                        links={feature.links}
                        description_html={feature.description_html}
                    />
                );
            }
        });
    };

    return <div style={{ paddingTop: "2em", maxWidth: "1400px" }}>{renderRows()}</div>;
};

export default FeatureDetails;
