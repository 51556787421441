import React, { useState } from "react";
import {
    Row,
    Col,
    ListGroup,
    ListGroupItem,
    Alert,
    ButtonGroup,
    Button,
    Collapse
} from "reactstrap";
import { approveDevRequestAPI } from "../../api";
import "../../css/fonts.css";

const DevRequest = (props) => {
    const [showDetails, setShowDetails] = useState(false);
    const [confirmApprove, setConfirmApprove] = useState(false);
    const [confirmDeny, setConfirmDeny] = useState(false);
    const [error, setError] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const approveDevRequest = (beta_site_email, uconnect_email, approve) => {
        setSubmitting(true);
        try {
            approveDevRequestAPI(beta_site_email, uconnect_email, approve, props.token)
                .then((result) => {
                    console.log("Approve dev request result:", result);
                    window.location.reload(false);
                })
                .catch((error) => {
                    console.log("Approve dev request error:", error);
                    setError(true);
                    setSubmitting(false);
                });
        } catch {
            console.log("Approve dev request error.");
            setError(true);
            setSubmitting(false);
        }
    };

    return (
        <ListGroupItem
            style={{
                padding: "0.5em",
                paddingLeft: "1em",
                width: props.jumbotron_width,
                fontFamily: "Roboto-Light"
            }}
        >
            {error ? (
                <Alert color="danger" style={{ fontSize: "10px", padding: "0.5em", margin: "0" }}>
                    Error. Please refresh and try again.
                </Alert>
            ) : null}
            <Row style={{ fontSize: props.is_mobile ? "12px" : "14px" }}>
                <Col style={{ marginTop: "0.3em" }}>{props.beta_site_email}</Col>

                <Col style={{ display: "flex", justifyContent: "right" }}>
                    <Button
                        onClick={() => {
                            setShowDetails(!showDetails);
                        }}
                        outline
                        color="primary"
                        size="sm"
                        active={showDetails}
                    >
                        Actions
                    </Button>
                </Col>
            </Row>
            <Collapse
                isOpen={showDetails}
                style={{ paddingLeft: "1.6em", fontSize: props.is_mobile ? "10px" : "12px" }}
            >
                <Row style={{ paddingTop: "0.5em" }}>
                    {!confirmApprove && !confirmDeny ? (
                        <div style={{ display: "flex", justifyContent: "left" }}>
                            <ButtonGroup>
                                <Button
                                    color="primary"
                                    size="sm"
                                    outline
                                    disabled={submitting}
                                    onClick={() => {
                                        setConfirmApprove(true);
                                    }}
                                >
                                    Approve
                                </Button>
                                <Button
                                    color="danger"
                                    size="sm"
                                    outline
                                    disabled={submitting}
                                    onClick={() => {
                                        setConfirmDeny(true);
                                    }}
                                >
                                    Deny
                                </Button>
                            </ButtonGroup>
                        </div>
                    ) : null}

                    {confirmApprove ? (
                        <div style={{ display: "flex", justifyContent: "left" }}>
                            <ButtonGroup>
                                <Button
                                    color="success"
                                    size="sm"
                                    disabled={submitting}
                                    onClick={() => {
                                        approveDevRequest(
                                            props.beta_site_email,
                                            props.uconnect_email,
                                            true
                                        );
                                    }}
                                >
                                    Confirm Developer Approval
                                </Button>
                                <Button
                                    color="danger"
                                    size="sm"
                                    disabled={submitting}
                                    onClick={() => {
                                        setConfirmApprove(false);
                                    }}
                                >
                                    Cancel
                                </Button>
                            </ButtonGroup>
                        </div>
                    ) : null}

                    {confirmDeny ? (
                        <div style={{ display: "flex", justifyContent: "left" }}>
                            <ButtonGroup>
                                <Button
                                    color="warning"
                                    size="sm"
                                    disabled={submitting}
                                    onClick={() => {
                                        approveDevRequest(
                                            props.beta_site_email,
                                            props.uconnect_email,
                                            false
                                        );
                                    }}
                                >
                                    Confirm Developer Denial
                                </Button>
                                <Button
                                    color="danger"
                                    size="sm"
                                    disabled={submitting}
                                    onClick={() => {
                                        setConfirmDeny(false);
                                    }}
                                >
                                    Cancel
                                </Button>
                            </ButtonGroup>
                        </div>
                    ) : null}
                </Row>
            </Collapse>
        </ListGroupItem>
    );
};

const DevRequests = (props) => {
    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <ListGroup>
                {Object.keys(props.dev_requests).map(function (key) {
                    return (
                        <DevRequest
                            key={key}
                            token={props.token}
                            beta_site_email={props.dev_requests[key].UserEmail}
                            uconnect_email={props.dev_requests[key].uconnect_email}
                            is_mobile={props.is_mobile}
                            jumbotron_width={props.jumbotron_width}
                        />
                    );
                })}
            </ListGroup>
        </div>
    );
};

export default DevRequests;
