import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiTextField-root': {
            margin: theme.spacing(1),
            width: '25ch',
            borderRadius: '12px',
        },
    },
}));

export default function TimePicker({ onChange, label, value }) {
    const classes = useStyles();
    const displayValue = value || '00:00';

    return (
        <form className={classes.root} noValidate>
            <TextField
                style={{
                    backgroundColor: 'white',
                    width: '8rem'
                }}
                variant='filled'
                id="time"
                label={label}
                type="time"
                value={displayValue}
                InputLabelProps={{
                    shrink: true,
                }}
                onChange={e => onChange(e.target.value)}
            />
        </form>
    );
}
