import React, { Component, useState } from "react";
import topLogo from "../img/logo.png";
import {
    Button,
    ButtonGroup,
    NavbarToggler,
    Collapse,
    Navbar,
    Nav,
    NavItem,
} from "reactstrap";
import { connect } from "react-redux";
import Lottie from "react-lottie";
import navbarLogo from "../img/uconnect_navbar.json";
import { BsShieldLock } from "react-icons/bs";

const RegularButtons = (props) => {
    const navbarLinkColor = "#ECA935";
    const navbarLinkStyle = {
        fontFamily: 'Roboto',
        fontWeight: 700,
        fontSize: props.is_mobile ? '14px' : '18px',
        color: navbarLinkColor
    }

    const logout = () => {
        localStorage.removeItem("fca_token")
        console.log("Removed Cookie!");
        props.dispatch({ type: "LOGOUT" });
    };

    return (
        <ButtonGroup>
            {props.is_admin ? (
                <Button
                    size={props.button_size}
                    color="link"
                    href="/admin"
                    style={navbarLinkStyle}
                >
                    Admin <BsShieldLock style={{ paddingBottom: "0.2em", paddingTop: "0.1em" }} />
                </Button>
            ) : null}
            {props.is_admin || props.is_developer ? (
                <Button
                    size={props.button_size}
                    color="link"
                    href="/develop"
                    style={navbarLinkStyle}
                >
                    {"{Develop}"}
                </Button>
            ) : null}
            <Button
                size={props.button_size}
                color="link"
                href="/getstarted"
                style={navbarLinkStyle}
            >
                Features
            </Button>
            <Button
                size={props.button_size}
                color="link"
                href="/sdk"
                style={navbarLinkStyle}
            >
                SDK
            </Button>
            <Button
                size={props.button_size}
                color="link"
                href="/feedback"
                style={navbarLinkStyle}
            >
                Feedback
            </Button>
            <Button
                size={props.button_size}
                color="link"
                href="/download"
                style={navbarLinkStyle}
            >
                Download
            </Button>
            <Button
                size={props.button_size}
                color="link"
                href="/about"
                style={navbarLinkStyle}
            >
                About
            </Button>
            <Button
                size={props.button_size}
                color="link"
                href="/profile"
                style={navbarLinkStyle}
            >
                Profile
            </Button>
            <Button
                size={props.button_size}
                color="link"
                style={navbarLinkStyle}
                onClick={logout}
            >
                Sign out
            </Button>
        </ButtonGroup>
    );
};
function HamburgerButtons(props) {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const logout = () => {
        localStorage.removeItem("fca_token")
        console.log("Removed Cookie!");
        props.dispatch({ type: "LOGOUT" });
    };
    const navbarLinkColor = "#ECA935";
    const navbarLinkStyle = {
        fontFamily: 'Roboto',
        fontWeight: 700,
        fontSize: '18px',
        color: navbarLinkColor
    }

    return (
        <div style={{ width: '120px', position: 'absolute', textAlign: 'end', backgroundColor: 'rgba(0, 0, 0, 0.6)', borderRadius: '12px 0px 12px 12px' }}>
            <Collapse isOpen={!dropdownOpen}>
                <div>
                    {props.is_admin ? (
                        <NavItem>
                            <Button
                                size={props.button_size}
                                color="link"
                                href="/admin"
                                style={navbarLinkStyle}
                            >
                                Admin
                                <BsShieldLock
                                    style={{ paddingBottom: "0.2em", paddingTop: "0.1em" }}
                                />
                            </Button>
                        </NavItem>
                    ) : null}
                    {props.is_admin || props.is_developer ? (
                        <NavItem>
                            <Button
                                size={props.button_size}
                                color="link"
                                href="/develop"
                                style={navbarLinkStyle}
                            >
                                {"{Develop}"}
                            </Button>
                        </NavItem>
                    ) : null}

                    <NavItem>
                        <Button size={props.button_size} href="/getstarted" color="link">
                            <span style={navbarLinkStyle}>
                                Features
                            </span>
                        </Button>
                    </NavItem>

                    <NavItem>
                        <Button size={props.button_size} href="/sdk" color="link">
                            <span style={navbarLinkStyle}>
                                SDK
                            </span>
                        </Button>
                    </NavItem>

                    <NavItem>
                        <Button size={props.button_size} href="/feedback" color="link">
                            <span style={navbarLinkStyle}>
                                Feedback
                            </span>
                        </Button>
                    </NavItem>

                    <NavItem>
                        <Button size={props.button_size} href="/download" color="link">
                            <span style={navbarLinkStyle}>
                                Download
                            </span>
                        </Button>
                    </NavItem>

                    <NavItem>
                        <Button size={props.button_size} href="/about" color="link">
                            <span style={navbarLinkStyle}>
                                About
                            </span>
                        </Button>
                    </NavItem>

                    <NavItem>
                        <Button size={props.button_size} href="/profile" color="link">
                            <span style={navbarLinkStyle}>
                                Profile
                            </span>
                        </Button>
                    </NavItem>

                    <NavItem>
                        <Button size={props.button_size} color="link" oncClick={logout}>
                            <span style={navbarLinkStyle}>Sign out</span>
                        </Button>
                    </NavItem>
                </div>
            </Collapse>
        </div>
    );
}

const LoggedInButtons = (props) => {
    const [hamburgerOpen, setHamburger] = useState(false);
    const hamburgerToggle = () => setHamburger(!hamburgerOpen);
    return (
        <Navbar color="faded" light expand="md" className={props.navbar_class}>
            <a
                href="/"
            >
                <img src={topLogo} alt="Logo" style={props.logo_img_style} />
            </a>
            <NavbarToggler onClick={hamburgerToggle} />

            <Collapse isOpen={hamburgerOpen} navbar>
                <Nav className="rightNav ml-auto align-items-end" navbar>
                    {props.is_mobile ? (
                        <HamburgerButtons
                            is_admin={props.is_admin}
                            is_developer={props.is_developer}

                            button_size={props.button_size}
                            dispatch={props.dispatch}
                        />
                    ) : (
                        <RegularButtons
                            is_admin={props.is_admin}
                            is_developer={props.is_developer}

                            button_size={props.button_size}
                            dispatch={props.dispatch}
                        />
                    )}
                </Nav>
            </Collapse>
        </Navbar>
    );
};

const LoggedOutButtons = (props) => {
    const [navbarHover, setNavbarHover] = useState(false);
    return (
        <Navbar color="faded" light expand="lg" className={props.navbar_class}>
            <a
                href="/"
                onMouseEnter={() => {
                    setNavbarHover(true);
                }}
                onMouseLeave={() => {
                    setNavbarHover(false);
                }}
            >
                {navbarHover ? (
                    <Lottie
                        options={{
                            loop: true,
                            autoplay: true,
                            animationData: navbarLogo,
                            rendererSettings: {
                                preserveAspectRatio: "xMidYMid slice"
                            }
                        }}
                        width={120}
                    />
                ) : (
                    <img src={topLogo} alt="Logo" style={props.logo_img_style} />
                )}
            </a>
            <Nav className="rightNav ml-auto" navbar>
                <Button
                    href="/about"
                    size={props.button_size}
                    color="link"
                    style={{ fontFamily: "Roboto-Light", color: "black" }}
                >
                    About
                </Button>
            </Nav>
        </Navbar>
    );
};

class AppNavbar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hamburgerOpen: false,
            dropdownOpen: false,
            button_size: "md",
            navbar_class: "mb-5",
            is_mobile: false,
            uconnect_email: "",
            different_uconnect_email: false,
            uconnect_show_tip: false,
            logo_img_style: { maxWidth: "390px" },
            submitting_uconnect: false
        };
    }
    componentDidMount() {
        window.addEventListener("resize", this.handleWindowSizeChange);
        this.handleWindowSizeChange();
    }

    handleWindowSizeChange = () => {
        if (window.innerWidth <= 767) {
            this.setState({
                button_size: "sm",
                navbar_class: "mb-2",
                is_mobile: true
            });
        } else if (window.innerWidth >= 1034) {
            this.setState({
                button_size: "md",
                navbar_class: "mb-5 pt-3 pl-5",
                is_mobile: false
            });
        }
        else {
            this.setState({
                button_size: "sm",
                navbar_class: "mb-5 pt-3 pl-2 pr-0",
                is_mobile: false
            });
        }

        if (window.innerWidth <= 767) {
            this.setState({
                logo_img_style: { maxWidth: "275px" },
            });
        }
        else if (window.innerWidth <= 1034) {
            this.setState({
                logo_img_style: { maxWidth: "250px" },
            });
        }
        else {
            this.setState({
                logo_img_style: { maxWidth: "350px" },
            });
        }
    };

    hamburgerToggle = () =>
        this.setState({
            hamburgerOpen: !this.state.hamburgerOpen
        });

    handleChange = async (event, type = false) => {
        const { target } = event;
        let value;
        if (target.type === "checkbox") {
            value = target.checked;
            const { id } = target;
            await this.setState({
                [id]: value
            });
        } else {
            value = target.value;
            const { name } = target;
            await this.setState({
                [name]: value
            });
        }
        console.log(this.state);
    };
    logout() {
        localStorage.removeItem("fca_token")
        this.props.dispatch({ type: "LOGOUT" });
    }
    render() {
        console.log("navbar props", this.props);
        let navbar;
        if (this.props.loading) {
            navbar =
                <Navbar color="faded" light expand="lg" className={this.state.navbar_class}>
                    <a href="/">
                        <img src={topLogo} alt="Logo" style={this.state.logo_img_style} />
                    </a>
                    <Nav>
                        <NavItem>
                            <Button color="link">...</Button>
                        </NavItem>
                    </Nav>
                </Navbar>
        } else if (this.props.logged_in) {
            navbar =
                <LoggedInButtons
                    is_admin={this.props.user_info.is_admin}
                    is_developer={this.props.user_info.is_developer}
                    is_mobile={this.state.is_mobile}

                    button_size={this.state.button_size}
                    dispatch={this.props.dispatch}
                    navbar_class={this.state.navbar_class}
                    logo_img_style={this.state.logo_img_style}
                />
        } else {
            navbar = <LoggedOutButtons navbar_class={this.state.navbar_class} logo_img_style={this.state.logo_img_style} />;
        }

        return (
            <div style={{ backgroundColor: 'transparent', height: '3rem', position: 'absolute', width: '100%' }}>{navbar}</div>
        )
    }
}

const mapStateToProps = (state) => ({
    logged_in: state.logged_in,
    loading: state.loading,
    user_info: state.user_info
});

export default connect(mapStateToProps)(AppNavbar);