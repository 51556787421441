import React from "react";
import FeatureCard from "./FeatureCard";
import "../../css/FeatureCardList.css";

const FeatureCardList = ({ features }) => {
    const renderCards = () => {
        return features.map((feature) => {
            if (feature.is_visible === true) {
                return (
                    <FeatureCard
                        key={feature.id}
                        id={feature.id}
                        title={feature.title}
                        imageUrl={feature.image}
                        imageAltText={feature.image_alt_text}
                    />
                );
            }
        });
    };

    return <div className="feature-card-list">{renderCards()}</div>;
};

export default FeatureCardList;
