import React, { useEffect, useState } from "react";
import "../../css/fonts.css";
import "../../css/MoonbaseFeatures.css";
import {
    Select, MenuItem, FormControl, InputLabel
} from '@material-ui/core';
import { connect } from "react-redux";
import Loading from "../../components/Loading";
import LoadPageAuthCheck from "../../components/LoadPageAuthCheck";
import { Redirect } from 'react-router-dom';
import HazardAlertFeature from "./HazardAlertFeature";
import SilentAlertFeature from "./SilentAlertFeature";
import LoginBackground from "../../img/loginbg.jpg";
import {
    getMoonbaseSequences
} from "../../api";



const MoonbaseFeatures = props => {
    const [vinList, setVinList] = useState([]);
    const [curVin, setCurVin] = useState(null);
    const [sequences, setSequences] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [phoneNumbers, setNumbers] = useState([]);
    const [hazEnabled, setHazEnabled] = useState(false)
    const [hazSeqByVin, setHazSeqByVin] = useState({})
    const [silentSeqByVin, setSilentSeqByVin] = useState({})
    const [hasSequence, setHasSequences] = useState(false)
    

    useEffect(() => {
        if (localStorage.getItem("fca_token")) {
            props.dispatch({ type: "LOADING" });
        } else {
            localStorage.removeItem("fca_token");
            props.dispatch({ type: "LOGOUT" });
        }
    }, []);

    // checkin if the user has any vins 
    useEffect(() => {
        if (props.logged_in) {
            const vins = Object.keys(props.user_info.owned_vins);
            setVinList(vins);
            getSetMoonbaseSequences();
        }
    }, [props.logged_in]);


    // gets the moonbase sequences and sorts them into dictionaries keyed of sequence type (haz or silent)
    const getSetMoonbaseSequences = () => {
        // retrieve sequences from user info
         getMoonbaseSequences().then(res => {
            // initialize temp vars 
            let tempHazSeq = []  // temp list of total haz sequences
            let tempVinHazSeq = {}  // temp dict for haz sequences keyed by vin
            let tempSilentSeq = []  // temp list of total silent sequences
            let tempVinSilentSeq = {}  // temp dict for silent sequences keyed by vin

            let haz_sequences = res.data || [];
            if (haz_sequences !== undefined || haz_sequences.length > 1) {
                // make it off the name instead 
                for(let i =0; i < haz_sequences.length; i++) 
                {
                    // collect hazard alert sequences
                    if (haz_sequences[i].name === 'sn-mb-haz-service') 
                    {
                        tempHazSeq.push(haz_sequences[i])
                        tempVinHazSeq[haz_sequences[i].vins[0].vin] = haz_sequences[i]
                    }

                    // collect silent sequences
                    if (haz_sequences[i].name === 'sn-mb-silent-service') 
                    {
                        tempSilentSeq.push(haz_sequences[i])
                        tempVinSilentSeq[haz_sequences[i].vins[0].vin] = haz_sequences[i]
                    }
                }
                // set the sequence dictionaries
                setHazSeqByVin(tempVinHazSeq)
                setSilentSeqByVin(tempVinSilentSeq)
            }
             setSequences(haz_sequences) // store results from "getMoonbaseSequences" api call
             setIsLoading(false);
         });
     };

   
    useEffect(() => {
        if (vinList === null) {
            return;
        }
        setCurVin(vinList[0] !== undefined ? vinList[0] : 'No VINS');
    }, [vinList]);


    // render
    if (props.loading || isLoading) {
        return (
            <div>
                <Loading />
                <LoadPageAuthCheck {...props} />
            </div>
        );
    } else if (!props.logged_in) {
        return <Redirect to="/" />;
    } else {
        return (
            <div
                style={{
                    backgroundImage: `url(${LoginBackground})`,
                    width: "100%",
                    height: "auto",
                    backgroundSize: "cover",
                    backgroundRepeat: "none",
                }}
            >

            <div style={{ paddingTop: '4.5rem', width: '100%' }}>
                <div className="main">
                    <h1 className="mb-page-title">Moonbase Features</h1>

                    
                    {vinList.length > 0 ? (
                        <div>
                            <FormControl style={{ backgroundColor: 'white', marginTop:'.5rem', marginBottom: '1rem', borderRadius: '.5rem', maxWidth: '50rem', marginLeft: '.5rem'}} variant="filled" fullWidth>
                                <InputLabel>VIN</InputLabel>
                                <Select value={curVin} onChange={e => {setCurVin(e.target.value); }}>
                                    {vinList.map((v, idx) => <MenuItem key={idx} value={v}>{v}</MenuItem>)}
                                </Select>
                            </FormControl>

                            <div style={{ border: '1px solid white', padding: '10px', borderRadius: '1rem', height: 'auto', backgroundColor: 'rgba(255, 255, 255, 0.3)'}}>
                                <HazardAlertFeature
                                    vinList={vinList}
                                    sequences={sequences}
                                    curVin={curVin}
                                    setCurVin={vin => setCurVin(vin)}
                                    isActive={hazEnabled}
                                    numbers={phoneNumbers}
                                    hazSeqByVin={hazSeqByVin}
                                />
                            </div>

                            <br style={{marginTop: '2rem'}}/>

                            <div style={{ border: '1px solid white', padding: '10px', borderRadius: '1rem', height: 'auto', backgroundColor: 'rgba(255, 255, 255, 0.3)'}}>
                                <SilentAlertFeature
                                    vinList={vinList}
                                    sequences={sequences}
                                    curVin={curVin}
                                    setCurVin={vin => setCurVin(vin)}
                                    isActive={hazEnabled}
                                    numbers={phoneNumbers}
                                    silentSeqByVin={silentSeqByVin}
                                />
                            </div>

                            <br style={{marginBottom: '2rem'}}/>

                        </div>
                    ) : (
                        <div style={{ height: '100vh' }}>
                            <h3 style={{ style: 'white' }}>
                                No VINs found for Current User
                            </h3>
                        </div>
                    )
                }

                  
            </div>
        </div>
    </div>
    )}
};

const mapStateToProps = (state) => ({
    logged_in: state.logged_in,
    loading: state.loading,
    user_info: state.user_info
});

export default connect(mapStateToProps)(MoonbaseFeatures);