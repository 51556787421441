import React, { useEffect, useState } from "react";
import '../../css/fonts.css';
import {
    Button, TextField, Typography, List, ListItem, ListItemText
} from '@material-ui/core';
import '../../css/HazardAlert.css';
import {
  putMoonbaseSequence,
  postMoonbaseSequence
} from "../../api";


function addVinToSeq(vin, numbers) {
  if (vin != null){
      const body = {
              vin: vin,
              numbers: numbers,
              sequence_name: 'sn-mb-haz-service',
              active: true,
              timezone: 'North America/Detroit',
              isHaz: true
      };
      postMoonbaseSequence(body);
      return true
  }
  alert('Please select a VIN and try again')
  return false
}

function updateSeq(seq_id, active, vin, numbers) {
  if (seq_id != null) {
    const body = {
        active: active,
        numbers: numbers,
        sequence_id: seq_id,
        isHaz: true,
        name: 'sn-mb-haz-service',
        vin: vin,
        timezone: 'North America/Detroit'
      }
      putMoonbaseSequence(body)
      return true;
  }
  return false;
}


const HazardAlert = ({
    vinList, 
    curVin,
    numbers,
    isActive,
    hazSeqByVin
    
}) => {
    
    const [toggleOn, setToggleOn] = useState(isActive);
    const [phoneNumber, setPhoneNumber] = useState(''); // State for phone number input
    const [phoneNumbers, setPhoneNumbers] = useState(numbers)
    const [seqId, setSeqId] = useState('')
    
    // initalize state on vin switch from parent component
    useEffect(() => {
        // if the sequence exists, set state
        if (Object.keys(hazSeqByVin).length > 0 && hazSeqByVin[curVin]) {
            setToggleOn(hazSeqByVin[curVin].active);
            setPhoneNumbers(hazSeqByVin[curVin].actions[0].phones)
            setSeqId(hazSeqByVin[curVin].sequence_id)
        }
        else 
        {
            setToggleOn(false)
            setPhoneNumbers([])
            setSeqId('')
        }
    }, [hazSeqByVin, curVin]);
   

    // handle the feature toggle switch
    const handleToggle = () => {
        // don't do anthing if there isn't a phone number
        if (phoneNumbers.length == 0) 
        {
            alert('Please add a phone number before enabling feature')
            return;
        }
        setToggleOn(!toggleOn);

        // add sequence to vin for the first time
        if (!toggleOn && Object.keys(hazSeqByVin).length === 0) {
            addVinToSeq(curVin, phoneNumbers);
        }
        // enable the already added sequence
        else if (!toggleOn && Object.keys(hazSeqByVin).length > 0) {
            updateSeq(seqId, true, curVin, phoneNumbers)  
        }
        // disable the already added sequence
        else {
            updateSeq(seqId, false, curVin, phoneNumbers) 
        }
    };


    // phone number add handler with regex for phone number validation
    const handleAddPhoneNumber = () => {
        const regex = /^\+\d{11}$/;
        if (phoneNumber.trim() !== '' && regex.test(phoneNumber)) {
            setPhoneNumbers([...phoneNumbers, phoneNumber.trim()]);
            setPhoneNumber('')
        } else {
            alert("Phone number not valid. Make sure you add your country code. Ex) +1 for US")
        }
    };

    // phone number remove handler
    const handleDeletePhoneNumber = (index) => {
        const newPhoneNumbers = [...phoneNumbers];
        newPhoneNumbers.splice(index, 1);
        setPhoneNumbers(newPhoneNumbers);
    };


    return (
        <div className="main-container">

            <p1 className="card-title">Hazard Lights Alert</p1>
            
            <div className="container">
                <div className="toggle-box">
                    <label htmlFor="toggle">Hazard Alerts</label>
                    <input
                        type="checkbox"
                        id="toggle"
                        checked={toggleOn}
                        onChange={handleToggle}
                    />
                </div>
            </div>
                <div className="container">
                    <div>
                        <TextField
                            id="phone"
                            label="Phone Number"
                            variant="outlined"
                            value={phoneNumber}
                            onChange={e => setPhoneNumber(e.target.value)}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleAddPhoneNumber}
                            style={{ marginLeft: '10px' , marginTop: '1.5vh'}}
                        >
                            Add
                        </Button>
                    </div>
                </div>
                <div className="container">
                    {phoneNumbers.length > 0 && (

                        <div>
                            <Typography variant="subtitle1">Entered Phone Numbers:</Typography>
                            <List>
                                {phoneNumbers.map((number, index) => (
                                    <ListItem key={index}>
                                        <ListItemText primary={number} />
                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => handleDeletePhoneNumber(index)}
                                            style={{ marginLeft: '10px'}}
                                        >
                                            Delete
                                        </Button>
                                    </ListItem>
                                ))}
                            </List>
                        </div>
                    )}
                </div>
        </div>
    );
};

export default HazardAlert;
