import React from 'react';
import '../../css/ScheduledRemoteOp.css';
// material ui components
import {
    Button,
    Select,
    Switch as SwitchBtn,
    MenuItem,
    FormControl,
    FormControlLabel,
    Fab,
    InputLabel,
    TextField,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TimePicker from './TimePicker';
import { timezones } from './timezones';

const dayMap = {
    'Sunday': 7,
    'Monday': 1,
    'Tuesday': 2,
    'Wednesday': 3,
    'Thursday': 4,
    'Friday': 5,
    'Saturday': 6,
};
const dayMapRev = {
    7: 'Sunday',
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday'
};

const days = Object.keys(dayMap);

const roMap = {
    'RDU': 'Remote Unlock',
    'RDL': 'Remote Lock',
    'REON': 'Remote Start',
    'REOFF': 'Remote Stop'
};

const roMapRev = {
    'Remote Unlock': 'RDU',
    'Remote Lock': 'RDL',
    'Remote Start': 'REON',
    'Remote Stop': 'REOFF'
};

/**
 * Display Sequence
 * sequence : sequence object
 * sequenceIdx : index of sequence object in parent array
 * handleDayClick : function that handles the click on day buttons 
 * handleToggle : function that handles the click on toggle buttons
 */
const DisplaySequence = ({
    sequence,
    curVin,
    handleDayClick,
    handleToggle,
    handleTimeChange,
    handleRemoteOpChange,
    handleTimezoneChange,
    handleDelete
}) => {
    const seqEnabled = sequence.active;
    const remoteOptions = ['Remote Start', 'Remote Stop', 'Remote Lock', 'Remote Unlock'];

    // split time trigger
    const timeTrigger = sequence.triggers.find(t => t.condition_type === 'time');
    const cronSplit = timeTrigger.cron.split(' ');
    const minute = cronSplit[0].length === 1 ? `0${cronSplit[0]}` : cronSplit[0];
    const hour = cronSplit[1].length === 1 ? `0${cronSplit[1]}` : cronSplit[1];
    // const day = cronSplit[2];
    // const month = cronSplit[3];
    const weekDays = cronSplit[4] === '*' ? days :
        cronSplit[4].split(',').map(d => dayMapRev[parseInt(d)]);

    const displayTime = `${hour}:${minute}`;
    const dayColors = days.map(d => {
        return weekDays.includes(d) ? 'primary' : 'default';
    });

    // split remote op action
    const remoteAction = sequence.actions.find(a => a.action_type === 'remote_op');

    const displayRemoteOp = roMap[remoteAction.remote_op];

    const seqId = sequence.sequence_id;

    const workingVin = sequence.vins.find(v => v.vin === curVin);
    const displayTimezone = workingVin === undefined ? 'America/Detroit' : workingVin.timezone;

    return (
        
        <div className="sro-sequence-container">
            <div className="sro-day-row">
                {days.map((d, idx) => {
                    return (
                        <Fab
                            key={idx}
                            color={dayColors[idx]}
                            onClick={() => handleDayClick(seqId, d)}
                            style={{ borderRadius: '50%', width: '10%' }}
                        >
                            {d.charAt(0)}
                        </Fab>
                    );
                })}

            </div>
            <div className="sro-btn-row">
                <FormControl style={{ backgroundColor: 'white', borderRadius: '12px' }} variant='filled'>
                    <InputLabel>Remote Op</InputLabel>
                    <Select value={displayRemoteOp} onChange={e => {
                        handleRemoteOpChange(seqId, roMapRev[e.target.value]);
                    }}>
                        {Object.values(roMap).map((op, idx) => {
                            return <MenuItem key={idx} value={op}>{op}</MenuItem>;
                        })}
                    </Select>
                </FormControl>
                <TimePicker label='Time' value={displayTime} onChange={time => handleTimeChange(seqId, time)} />
                <Autocomplete
                    options={timezones}
                    id="timezones"
                    getOptionLabel={option => option}
                    style={{ width: 200 }}
                    value={displayTimezone}
                    onChange={(_, value) => handleTimezoneChange(seqId, value)}
                    renderInput={(params) => {
                        return <TextField
                            style={{ backgroundColor: 'white', borderRadius: '12px' }}
                            {...params}
                            label="Time Zone"
                            variant="filled"
                        />;
                    }}
                />
                <FormControl>
                    <FormControlLabel
                        labelPlacement='top'
                        control={<SwitchBtn
                            onClick={() => handleToggle(seqId)}
                            checked={seqEnabled}
                            color='primary'
                        />}
                        label='Disable / Enable' />
                </FormControl>
            </div>
            <Button
                style={{
                    backgroundColor: 'rgb(220,53,69)',
                    color: 'white',
                    borderRadius: '12px',
                    padding: '0.5rem 1rem 0.5rem 1rem',
                    borderRadius: '12px',
                }}
                variant="contained"
                onClick={() => handleDelete(seqId)}
            >
                Delete
            </Button>
        </div>
    );
};

export default DisplaySequence;
