import React from 'react';
import '../../css/fonts.css';
import '../../css/ScheduledRemoteOp.css';
import DisplaySequence from './DisplaySequence';
import {
    Button, Select, MenuItem, FormControl, InputLabel
} from '@material-ui/core';

const ScheduledROOptions = ({
    vinList,
    sequences,
    curVin,
    setCurVin,
    handleToggle,
    handleDayClick,
    handleTimeChange,
    handleRemoteOpChange,
    handleTimezoneChange,
    handleAddSequence,
    openModal,
    handleDelete
}) => {
    if (vinList === undefined || vinList.length === 0) {
        return (
            <div style={{ height: '100vh' }}>
                <h3 style={{ style: 'white' }}>
                    No VINs found for Current User
                </h3>
            </div>
        );
    }

    const renderDisplaySequence = () => {
        if (sequences.length === 0) {
            return (
                <div style={{ textAlign: 'center' }}>
                    <h3 style={{ color: 'white' }}>
                        No Schedules Found
                    </h3>
                </div>
            );
        }
        return (<>{sequences.map((s, idx) => {
            return (
                <DisplaySequence
                    key={`${curVin}-${idx}`}
                    sequence={s}
                    curVin={curVin}
                    handleDayClick={handleDayClick}
                    handleToggle={handleToggle}
                    handleTimeChange={handleTimeChange}
                    handleRemoteOpChange={handleRemoteOpChange}
                    handleTimezoneChange={handleTimezoneChange}
                    handleDelete={handleDelete}
                />
            );
        })}</>);
    };

    return (
        <div className="sro-container">
            <FormControl style={{ backgroundColor: 'white', borderRadius: '10px' }} variant="filled" fullWidth>
                <InputLabel>VIN</InputLabel>
                <Select value={curVin} onChange={e => setCurVin(e.target.value)} variant="outlined">
                    {vinList.map((v, idx) => <MenuItem key={idx} value={v}>{v}</MenuItem>)}
                </Select>
            </FormControl>
            <div className="sro-container-content">
                <Button
                    onClick={handleAddSequence}
                    color='primary'
                    variant='contained'
                    size='large'
                    disabled={sequences.length >= 4}
                    style={{ width: '15rem', padding: '0.5rem', paddingTop: '0.6rem', borderRadius: '12px' }}
                >
                    Create New Schedule
                </Button>
                <Button
                    color='primary'
                    variant='contained'
                    size='large'
                    onClick={openModal}
                    style={{ width: '15rem', padding: '0.5rem', paddingTop: '0.6rem', borderRadius: '12px' }}
                >
                    Save
                </Button>
            </div>
            <div className="sro-sequences-container">
                {renderDisplaySequence()}
            </div>
        </div>
    );
};

export default ScheduledROOptions;
