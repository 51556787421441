import React from "react";
import { Badge, ListGroup, ListGroupItem } from "reactstrap";

const USCarsList = () => {
    return (
        <ListGroup>
            <ListGroupItem color="primary" style={{ padding: "0.5em", fontSize: "13px" }}>
                2015 – 2017 Chrysler 200 <Badge color="primary">US</Badge>
            </ListGroupItem>
            <ListGroupItem color="primary" style={{ padding: "0.5em", fontSize: "13px" }}>
                2015 – 2020 Chrysler 300 <Badge color="primary">US</Badge>
            </ListGroupItem>
            <ListGroupItem color="primary" style={{ padding: "0.5em", fontSize: "13px" }}>
                2017 – 2020 Chrysler Pacifica <Badge color="primary">US</Badge>
            </ListGroupItem>
            <ListGroupItem color="primary" style={{ padding: "0.5em", fontSize: "13px" }}>
                2015 – 2020 Dodge Challenger and Charger <Badge color="primary">US</Badge>
            </ListGroupItem>
            <ListGroupItem color="primary" style={{ padding: "0.5em", fontSize: "13px" }}>
                2014 – 2020 Dodge Durango <Badge color="primary">US</Badge>
            </ListGroupItem>
            <ListGroupItem color="primary" style={{ padding: "0.5em", fontSize: "13px" }}>
                2013 – 2017 Dodge Viper <Badge color="primary">US</Badge>
            </ListGroupItem>
            <ListGroupItem color="primary" style={{ padding: "0.5em", fontSize: "13px" }}>
                2014 – 2020 Jeep® Cherokee <Badge color="primary">US</Badge>
            </ListGroupItem>
            <ListGroupItem color="primary" style={{ padding: "0.5em", fontSize: "13px" }}>
                2017 – 2020 Jeep® Compass <Badge color="primary">US</Badge>
            </ListGroupItem>
            <ListGroupItem color="primary" style={{ padding: "0.5em", fontSize: "13px" }}>
                2014 – 2020 Jeep® Grand Cherokee <Badge color="primary">US</Badge>
            </ListGroupItem>
            <ListGroupItem color="primary" style={{ padding: "0.5em", fontSize: "13px" }}>
                2015 – 2020 Jeep® Renegade <Badge color="primary">US</Badge>
            </ListGroupItem>
            <ListGroupItem color="primary" style={{ padding: "0.5em", fontSize: "13px" }}>
                2018 – 2020 Jeep® Wrangler <Badge color="primary">US</Badge>
            </ListGroupItem>
            <ListGroupItem color="primary" style={{ padding: "0.5em", fontSize: "13px" }}>
                2020 Jeep® Gladiator <Badge color="primary">US</Badge>
            </ListGroupItem>
            <ListGroupItem color="primary" style={{ padding: "0.5em", fontSize: "13px" }}>
                2013 – 2020 Ram 1500/2500/3500/Chassis Cab <Badge color="primary">US</Badge>
            </ListGroupItem>
            <ListGroupItem color="primary" style={{ padding: "0.5em", fontSize: "13px" }}>
                2016 – 2018 FIAT 500e (electric vehicle) <Badge color="primary">US</Badge>
            </ListGroupItem>
        </ListGroup>
    );
};

export default USCarsList;
