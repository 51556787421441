import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Button } from "reactstrap";

class FCACheck extends Component {
    render() {
        return (
            <div style={{ paddingTop: "7em" }}>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        fontFamily: "Roboto-Light"
                    }}
                >
                    Are you a Stellantis Employee?
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <div style={{ padding: "1em" }}>
                        <NavLink
                            to="/register/employee"
                            style={{
                                paddingLeft: "1.2em",
                                paddingRight: "1.2em",
                                fontFamily: "Roboto-Light"
                            }}
                            className="btn btn-primary"
                        >
                            Yes
                        </NavLink>
                    </div>
                    <div style={{ padding: "1em" }}>
                        <NavLink
                            to="/register/regular"
                            style={{
                                paddingLeft: "1.2em",
                                paddingRight: "1.2em",
                                fontFamily: "Roboto-Light"
                            }}
                            className="btn btn-secondary"
                        >
                            No
                        </NavLink>
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <Button color="link" href="/" size="sm" style={{ fontFamily: "Roboto-Light" }}>
                        Back
                    </Button>
                </div>
            </div>
        );
    }
}

export default FCACheck;
