import React from "react";
import ReactDOM from "react-dom";
import { createStore } from "redux";
import { Provider } from "react-redux";
import App from "./App";
import "./css/index.css";
import {BrowserRouter} from "react-router-dom";

// Initial Redux State
const initialState = {
    user_info: {},
    public_logged_in: false,
    logged_in: false,
    loading: true,
    scheduled_ops_user_info: {},
    scheduled_ops_logged_in: false,
    scheduled_ops_loading: true,
    moonbase_user_info: {},
    moonbase_logged_in: false,
    moonbase_loading: true
};

// Redux reducer function
function reducer(state = initialState, action) {
    switch (action.type) {
        case "PUBLIC_LOGIN":
            return {
                ...state,
                public_logged_in: true
            };
        case "LOGIN":
            return {
                ...state,
                logged_in: true,
                loading: false
            };
        case "LOGIN_WITH_INFO":
            return {
                ...state,
                user_info: action.payload,
                logged_in: true,
                loading: false
            };
        case "LOGOUT":
            return {
                user_info: {},
                logged_in: false,
                loading: false
            };
        case "LOADING":
            return {
                ...state,
                loading: true
            };
        case "SCHEDULED_OPS_LOGIN":
            return {
                ...state,
                scheduled_ops_logged_in: true,
                scheduled_ops_loading: false
            };
        case "SCHEDULED_OPS_LOGOUT":
            return {
                scheduled_ops_user_info: {},
                scheduled_ops_logged_in: false,
                scheduled_ops_loading: false
            };
        case "MOONBASE_LOGIN":
            return {
                ...state,
                moonbase_logged_in: true,
                moonbase_loading: false
            };
        case "MOONBASE_LOGOUT":
            return {
                moonbase_user_info: {},
                moonbase_logged_in: false,
                moonbase_loading: false
            };
        default:
            return state;
    }
}

// Remove console log output for production build
if (process.env.NODE_ENV !== "development") console.log = () => {};

const store = createStore(reducer);

const AppPackage = () => (
  <BrowserRouter>
        <Provider store={store}>
                <App />
        </Provider>
  </BrowserRouter>
);
ReactDOM.render(<AppPackage />, document.getElementById("root"));
