import React, { Component } from "react";
import LoadPageAuthCheck from "../../components/LoadPageAuthCheck";
import { Redirect, withRouter } from "react-router";
import { connect } from "react-redux";
import Loading from "../../components/Loading";
import "../../css/fonts.css";
import "../../css/SDK.css";

class SDK extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_mobile: false,
        };
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        if (window.innerWidth <= 767) {
            this.setState({
                is_mobile: true
            });
        } else {
            this.setState({
                is_mobile: false
            });
        }
    };

    render() {
        if (this.props.loading) {
            return (
                <div>
                    <Loading />
                    <LoadPageAuthCheck {...this.props} />
                </div>
            );
        } else if (!this.props.logged_in) {
            return <Redirect to="/" />;
        } else {
            return (
                <div className="sdk-page">
                    <div className="main" style={{
                        paddingTop: this.state.is_mobile ? "4em" : "0", width: "100%", minHeight: "100vh",
                        backgroundRepeat: "no-repeat", backgroundSize: "cover"
                    }}>
                        <h1 className="sdk-page-title">SDK</h1>
                        <p id="sdk-text">
                            Not seeing a feature you like? Develop your own with our proprietary StellaNova SDK.
                            Use the SDK to access vehicle data and remote operation so you can tailor your own vehicle experiences and features.
                        </p>
                        <button><a href="https://developers.stellantis.com/" target="_blank" rel="noopener noreferrer">LAUNCH SDK</a></button>
                    </div>
                </div>
            );
        }
    }
}



const mapStateToProps = (state) => ({
    logged_in: state.logged_in,
    loading: state.loading,
    user_info: state.user_info
});

export default withRouter(connect(mapStateToProps)(SDK));