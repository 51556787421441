import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router";
import Accordion from "../../components/Accodion";
import LoadPageAuthCheck from "../../components/LoadPageAuthCheck";
import Loading from "../../components/Loading";
import "../../css/About.css";
import "../../css/fonts.css";
import USCarsList from "./USCarsList";
import CanadaCarsList from "./CanadaCarsList";

class About extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_mobile: false,
            user_info: {}
        };
    }
    logout() {
        localStorage.removeItem("fca_token");
        this.props.dispatch({ type: "LOGOUT" });
    }
    componentDidMount() {
        window.addEventListener("resize", this.handleWindowSizeChange);
        this.handleWindowSizeChange();

        if (localStorage.getItem("fca_token")) {
            this.props.dispatch({ type: "LOADING" });
        } else {
            localStorage.removeItem("fca_token");
            this.props.dispatch({ type: "LOGOUT" });
        }
    }
    handleWindowSizeChange = () => {
        if (window.innerWidth <= 767) {
            this.setState({
                is_mobile: true
            });
        } else {
            this.setState({
                is_mobile: false
            });
        }
    };
    render() {
        if (this.props.loading) {
            return (
                <div>
                    <Loading />
                    <LoadPageAuthCheck {...this.props} />
                </div>
            );
        } else if (!this.props.logged_in) {
            return <Redirect to="/" />;
        } else {
            return (
                <div className="about-page">
                    <div
                        className="main"
                        style={{
                            paddingTop: this.state.is_mobile ? "4em" : "0",
                            width: "100%",
                            minHeight: "100vh"
                        }}
                    >
                        <h1 className="about-page-title">About</h1>
                        <div className="flipping-cards-container" style={{ display: "flex" }}>
                            <div className="flip-card">
                                <div className="flip-card-inner">
                                    <div className="flip-card-front">
                                        <h1
                                            style={{
                                                fontSize: this.state.is_mobile ? "32px" : "42px"
                                            }}
                                        >
                                            What is the Stellanova program?
                                        </h1>
                                    </div>
                                    <div className="flip-card-back">
                                        <p>
                                            The StellaNova Program lets users try out pre-release
                                            connectivity features. These are features that are not
                                            yet available to the general public. You have the
                                            opportunity to provide feedback on the quality and user
                                            experience of these features. This will help us identify
                                            the features that our customers really care about and
                                            find useful, which will help Uconnect Connected Services
                                            even better. Since these features have not been
                                            commercially released, they may contain bugs and
                                            inaccuracies. These features may not function as well as
                                            commercially released features.
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div className="flip-card">
                                <div className="flip-card-inner">
                                    <div class="flip-card-front">
                                        <h1
                                            style={{
                                                fontSize: this.state.is_mobile ? "32px" : "42px"
                                            }}
                                        >
                                            About us
                                        </h1>
                                    </div>
                                    <div className="flip-card-back">
                                        <p>
                                            The StellaNova Program was created internally at ex-FCA
                                            in 2020. Our goal is to allow employee and customer
                                            early access to new and experimental features to gain
                                            valuable feedback for the next generation of connected
                                            services. This program allows us to test out new
                                            capabilities and features without having to fully
                                            develop them into a commercially available feature. We
                                            get to experiment with new technology and hardware,
                                            allowing our customers to interact with a vehicle like
                                            never before.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <button className="contact-us-button">
                            <a href="mailto: stellanova@shiftup.onmicrosoft.com">CONTACT US</a>
                        </button>
                    </div>

                    <div className="faq-section">
                        <h1 style={{ fontSize: this.state.is_mobile ? "32px" : "62px" }}>
                            Frequently Asked Questions
                        </h1>
                        <Accordion
                            faqs={[
                                {
                                    question: "Is my vehicle compatible?",
                                    answer: "List of compatible vehicles:",
                                    us_vehicles: <USCarsList />,
                                    canada_vehicles: <CanadaCarsList />
                                },
                                {
                                    question: "Do I have to pay a fee to join the program?",
                                    answer:
                                        "StellaNova is free, but it does require an active " +
                                        "Uconnect Connected Services subscription. Eligible vehicles come " +
                                        "with a free 1 year subscription. However, to continue using the " +
                                        "services you must continue to subscribe."
                                },
                                {
                                    question: "Who can participate?",
                                    answer:
                                        "StellaNova is available to anyone with an active " +
                                        "connected services subscription who accepts the StellaNova " +
                                        "Program Terms and Conditions."
                                },
                                {
                                    question: "How do I provide feedback?",
                                    answer:
                                        'Navigate to "SDK" on the top navigation bar and click on the ' +
                                        '"Submit Feedback" button.'
                                },
                                {
                                    question: "How does StellaNova contact me?",
                                    answer:
                                        "StellaNova uses the contact information associated " +
                                        "with your Mopar Account. You may keep your contact information up to " +
                                        "date by visiting https://www.mopar.com and updating " +
                                        "your profile."
                                },
                                {
                                    question: "How do I leave StellaNova?",
                                    answer:
                                        "To leave the program, visit the Unenroll page and follow the " +
                                        "instructions to remove your account from StellaNova."
                                }
                            ]}
                        />
                    </div>
                </div>
            );
        }
    }
}

const mapStateToProps = (state) => ({
    logged_in: state.logged_in,
    loading: state.loading
});

export default withRouter(connect(mapStateToProps)(About));
