import React, { useState, useEffect } from "react";
import CircularButton from "../../components/CircularButton";
import puegeotLogo from "../../img/peugeot-logo.png";
import citroenLogo from "../../img/citroen-logo.png";
import opelLogo from "../../img/opel-logo.png";
import lanciaLogo from "../../img/lancia-logo.jpg";
import fiatLogo from "../../img/fiat-logo.jpg";
import fiatProLogo from "../../img/fiat-pro-logo.jpeg";
import jeepLogo from "../../img/jeep-logo.jpeg";
import alfaRomeoLogo from "../../img/alfa-romeo-logo.jpeg";
import abarthLogo from "../../img/abarth-logo.jpeg";
import maseratiLogo from "../../img/maserati-logo.png";
import dsLogo from "../../img/ds-logo.png";
import vauxhallLogo from "../../img/vauxhall-logo.jpg";
import chryslerLogo from "../../img/chrysler-logo.jpg";
import dodgeLogo from "../../img/dodge-logo.png";
import ramLogo from "../../img/ram-logo.jpg";

const SpaceAppBrands = ({
    selectedBrand,
    setSelectedBrand,
    selectedOperatingSystem,
    setIsValidSelection
}) => {
    const [availableBrands, setAvailableBrands] = useState([]);
    const [unavailableBrands, setUnavailableBrands] = useState([]);

    const brands = [
        {
            value: "peugeot",
            text: "Peugeot",
            imageUrl: puegeotLogo,
            imageAltText: "Peugeot logo",
            tooltipText: "Peugeot",
            disabled: selectedOperatingSystem === "android"
        },
        {
            value: "citroen",
            text: "Citroën",
            imageUrl: citroenLogo,
            imageAltText: "Citroën logo",
            tooltipText: "Citroën",
            disabled: selectedOperatingSystem === "android"
        },
        {
            value: "opel",
            text: "Opel",
            imageUrl: opelLogo,
            imageAltText: "Opel logo",
            tooltipText: "Opel",
            disabled: true
        },
        {
            value: "lancia",
            text: "Lancia",
            imageUrl: lanciaLogo,
            imageAltText: "Lancia logo",
            tooltipText: "Lancia",
            disabled: false
        },
        {
            value: "fiat",
            text: "Fiat",
            imageUrl: fiatLogo,
            imageAltText: "Fiat logo",
            tooltipText: "Fiat",
            disabled: true
        },
        {
            value: "ds",
            text: "DS",
            imageUrl: dsLogo,
            imageAltText: "DS logo",
            tooltipText: "DS",
            disabled: true
        },
        {
            value: "jeep",
            text: "Jeep",
            imageUrl: jeepLogo,
            imageAltText: "Jeep logo",
            tooltipText: "Jeep",
            disabled: true
        },
        {
            value: "fiat-pro",
            text: "Fiat Pro",
            imageUrl: fiatProLogo,
            imageAltText: "Fiat pro logo",
            tooltipText: "Fiat Pro",
            disabled: true
        },
        {
            value: "alfa-romeo",
            text: "Alfa Romeo",
            imageUrl: alfaRomeoLogo,
            imageAltText: "Alfa romeo logo",
            tooltipText: "Alfa Romeo",
            disabled: true
        },
        {
            value: "abarth",
            text: "Abarth",
            imageUrl: abarthLogo,
            imageAltText: "Abarth logo",
            tooltipText: "Abarth",
            disabled: true
        },
        {
            value: "maserati",
            text: "Maserati",
            imageUrl: maseratiLogo,
            imageAltText: "Maserati logo",
            tooltipText: "Maserati",
            disabled: true
        },
        {
            value: "vauxhall",
            text: "Vauxhall",
            imageUrl: vauxhallLogo,
            imageAltText: "Vauxhall logo",
            tooltipText: "Vaxhall",
            disabled: true
        },
        {
            value: "chrysler",
            text: "Chrysler",
            imageUrl: chryslerLogo,
            imageAltText: "Chrysler logo",
            tooltipText: "Chrysler",
            disabled: true
        },
        {
            value: "dodge",
            text: "Dodge",
            imageUrl: dodgeLogo,
            imageAltText: "Dodge logo",
            tooltipText: "Dodge",
            disabled: true
        },
        {
            value: "ram",
            text: "RAM",
            imageUrl: ramLogo,
            imageAltText: "Ram logo",
            tooltipText: "RAM",
            disabled: true
        }
    ];

    useEffect(() => {
        setAvailableBrands(brands.filter((brand) => brand.disabled === false));
        setUnavailableBrands(brands.filter((brand) => brand.disabled === true));
    }, [selectedOperatingSystem]);

    let invalidSelection = true;
    for (let i = 0; i < availableBrands.length; i++) {
        console.log(selectedBrand);
        console.log(availableBrands[i].value);
        if (selectedBrand === availableBrands[i].value) invalidSelection = false;
    }
    setIsValidSelection(!invalidSelection);

    return (
        <>
            <div>
                {availableBrands.map((brand_item, index) => (
                    <CircularButton
                        key={brand_item.value}
                        value={brand_item.value}
                        text={brand_item.text}
                        imageUrl={brand_item.imageUrl}
                        imageAltText={brand_item.imageAltText}
                        tooltipText={brand_item.tooltipText}
                        disabled={brand_item.disabled}
                        active={brand_item.value === selectedBrand}
                        onClick={(value) => setSelectedBrand(value)}
                    />
                ))}
            </div>

            <div>
                <div class="title" style={{ display: "table", width: "100%" }}>
                    <div class="title-row" style={{ display: "table-row" }}>
                        <div
                            class="bar-container"
                            style={{ display: "table-cell", position: "relative", width: "37.5%" }}
                        >
                            <div
                                class="bar"
                                style={{
                                    position: "absolute",
                                    width: "100%",
                                    top: "55%",
                                    borderBottom: "1px solid rgba(255, 255, 255, 0.4)"
                                }}
                            ></div>
                        </div>
                        <p
                            class="text"
                            style={{
                                display: "table-cell",
                                paddingLeft: "5px",
                                paddingRight: "5px",
                                width: "25%"
                            }}
                        >
                            Coming soon!
                        </p>
                        <div
                            class="bar-container"
                            style={{ display: "table-cell", position: "relative", width: "37.5%" }}
                        >
                            <div
                                class="bar"
                                style={{
                                    position: "absolute",
                                    width: "100%",
                                    top: "55%",
                                    borderBottom: "1px solid rgba(255, 255, 255, 0.4)"
                                }}
                            ></div>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                {unavailableBrands.map((brand_item, index) => (
                    <CircularButton
                        key={brand_item.value}
                        value={brand_item.value}
                        text={brand_item.text}
                        imageUrl={brand_item.imageUrl}
                        imageAltText={brand_item.imageAltText}
                        tooltipText={brand_item.tooltipText}
                        disabled={brand_item.disabled}
                        active={brand_item.value === selectedBrand}
                        onClick={(value) => setSelectedBrand(value)}
                    />
                ))}
            </div>
        </>
    );
};

export default SpaceAppBrands;
