import React, { Component } from "react";
import LoadPageAuthCheck from "../../components/LoadPageAuthCheck";
import { Form, FormGroup, Button, Input, Label, Spinner, Dropdown, DropdownMenu, DropdownItem, DropdownToggle } from "reactstrap";
import { Redirect, withRouter } from "react-router";
import { connect } from "react-redux";
import Loading from "../../components/Loading";
import "../../css/fonts.css";
import "../../css/Feedback.css";
import { sendFeedback } from "../../api";

class Feedback extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_mobile: false,
            feedback_body: "",
            is_bug: false,
            feedback_title: "",
            feedback_submit_loading: false,
            form_submission_message: "",
            feedback_topic: "",
        };
    }

    componentDidMount() {
        window.addEventListener("resize", this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        if (window.innerWidth <= 767) {
            this.setState({
                is_mobile: true
            });
        } else {
            this.setState({
                is_mobile: false
            });
        }
    };

    addFeedback(e) {
        e.preventDefault()
        this.setState({ feedback_submit_loading: true })

        try {
            let feedback_info = {
                author: this.props.user_info.uconnect_email_address,
                feedback_title: this.state.feedback_title,
                feedback_body: this.state.feedback_body,
                is_bug_report: this.state.is_bug,
                feedback_topic: this.state.feedback_topic
            };
            sendFeedback(feedback_info, localStorage.getItem("fca_token"))
                .then((result) => {
                    this.setState({
                        feedback_submit_loading: false,
                        form_submission_message: "Feedback received. Thank you.",
                        feedback_body: "",
                        feedback_title: "",
                        feedback_topic: ""
                    });
                })
                .catch((error) => {
                    this.setState({ feedback_submit_loading: false, form_submission_message: "There was error submitting the feedback. Please try again later." });
                });
        } catch {
            this.setState({ feedback_submit_loading: false, form_submission_message: "There was error submitting the feedback. Please try again later." });
        }
    }

    render() {
        if (this.props.loading) {
            return (
                <div>
                    <Loading />
                    <LoadPageAuthCheck {...this.props} />
                </div>
            );
        } else if (!this.props.logged_in) {
            return <Redirect to="/" />;
        } else {
            return (
                <div className="feedback-page">
                    <div className="main" style={{
                        paddingTop: this.state.is_mobile ? "4em" : "0", minHeight: "100vh",
                        backgroundRepeat: "no-repeat", backgroundSize: "cover"
                    }}>
                        <h1 className="feedback-page-title">Feedback</h1>

                        <div className="feedback-section">
                            <Form
                                onSubmit={(e) => {
                                    this.addFeedback(e);
                                }}
                            >
                                <div className="form-div">
                                    <p>
                                        Have an idea, suggestion, or need help? We want to hear from you.
                                    </p>
                                    <FormGroup tag="fieldset">
                                        <span>Is this a bug?</span>
                                        <FormGroup check>
                                            <Label check>
                                                <Input type="radio" name="yes" checked={this.state.is_bug} onClick={() => this.setState({ is_bug: true })} />{'Yes'}
                                            </Label>
                                        </FormGroup>
                                        <FormGroup check>
                                            <Label check>
                                                <Input type="radio" name="no" checked={!this.state.is_bug} onClick={() => this.setState({ is_bug: false })} />{'No'}
                                            </Label>
                                        </FormGroup>
                                    </FormGroup>
                                    <FormGroup>
                                        <Dropdown id="feedback-dropdown" isOpen={this.state.dropdownOpen} toggle={() => { this.setState({ dropdownOpen: !this.state.dropdownOpen }) }} direction="down">
                                            <DropdownToggle caret style={{ width: "100%", textAlign: "start", padding: "0.35rem", borderRadius: '10px', border: "2px solid white", backgroundColor: "rgba(149, 149, 149, 0.4)" }}>{this.state.feedback_topic ? this.state.feedback_topic : "Topic"}</DropdownToggle>
                                            <DropdownMenu id="feedback-dropdown-menu">

                                                {
                                                    localStorage.getItem("features") ?
                                                        JSON.parse(localStorage.getItem("features")).map((feature, index) => {
                                                            return <DropdownItem className="feedback-dropdown-menu-item" key={index} value={feature.title} style={{

                                                            }} onClick={() => { this.setState({ feedback_topic: feature.title }) }}>{feature.title}</DropdownItem>
                                                        })
                                                        : null
                                                }
                                                <DropdownItem className="feedback-dropdown-menu-item" onClick={() => { this.setState({ feedback_topic: 'Other' }) }}><em>Other</em></DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>
                                    </FormGroup>
                                    <FormGroup>
                                        <Input className="feedback-title" required type="text" value={this.state.feedback_title} placeholder="Title" name="feedback-title" onChange={(e) => this.setState({ feedback_title: e.target.value })} />
                                    </FormGroup>
                                    <FormGroup>
                                        <Input
                                            required
                                            type="textarea"
                                            name="feedback-body"
                                            placeholder="Share your experience with a StellaNova feature. What do you like? What don’t you like? What changes would you like to see? Or what do you need help with?"
                                            value={this.state.feedback_body}
                                            onChange={(e) => {
                                                this.setState({ feedback_body: e.target.value });
                                            }}
                                            style={{ height: "15em" }}
                                        />
                                    </FormGroup>
                                    <div className="form-submission-message">{this.state.form_submission_message}</div>
                                    <div className="submit-feedback">
                                        <Button
                                            disabled={this.state.feedback_submit_loading}
                                            id="submit-feedback-button"
                                        >
                                            {
                                                this.state.feedback_submit_loading ?
                                                    <>
                                                        <Spinner size="sm">
                                                            Loading...
                                                        </Spinner>
                                                        <span>
                                                            {' '}Loading
                                                        </span>
                                                    </>
                                                    :
                                                    <>SUBMIT FEEDBACK</>
                                            }

                                        </Button>
                                    </div>
                                </div>
                            </Form>
                        </div>

                    </div>
                </div>
            );
        }
    }
}



const mapStateToProps = (state) => ({
    logged_in: state.logged_in,
    loading: state.loading,
    user_info: state.user_info
});

export default withRouter(connect(mapStateToProps)(Feedback));