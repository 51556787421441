import React, { useState } from "react";
import {
    Row,
    Col,
    ListGroup,
    ListGroupItem,
    Alert,
    ButtonGroup,
    Button,
    Collapse
} from "reactstrap";
import { approveVINsRequestAPI } from "../../api";
import { IoMdCar } from "react-icons/io";
import "../../css/fonts.css";

const UsersVINsRequest = (props) => {
    const [showDetails, setShowDetails] = useState(false);
    const [confirmApprove, setConfirmApprove] = useState(false);
    const [confirmDeny, setConfirmDeny] = useState(false);
    const [submitting, setSubmitting] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    const approveVINsRequest = (uconnect_email, beta_site_email, approve) => {
        setSubmitting(true);
        try {
            approveVINsRequestAPI(
                {
                    uconnect_email: uconnect_email,
                    beta_site_email: beta_site_email
                },
                approve,
                props.token
            )
                .then((result) => {
                    console.log("Approve vins request result:", result);
                    window.location.reload(false);
                })
                .catch((error) => {
                    console.log("Approve vins request error:", error);
                    if (error.response.status === 401) {
                        window.location.reload(false);
                        return;
                    } else {
                        setErrorMessage(
                            "There has been an error. Please refresh the page and try again."
                        );
                    }
                });
        } catch {
            console.log("Approve vins request error.");
            setErrorMessage("There has been an error. Please refresh the page and try again.");
        }
    };

    return (
        <ListGroupItem
            style={{
                padding: "0.5em",
                paddingLeft: "1em",
                width: props.jumbotron_width,
                fontFamily: "Roboto-Light"
            }}
        >
            <Alert isOpen={errorMessage !== ""} color="danger" style={{ fontSize: "12px" }}>
                {errorMessage}
            </Alert>
            <Row style={{ fontSize: props.is_mobile ? "12px" : "14px" }}>
                <Col style={{ marginTop: "0.3em" }}>{props.uconnect_email}</Col>

                <Col style={{ display: "flex", justifyContent: "right" }}>
                    <Button
                        onClick={() => {
                            setShowDetails(!showDetails);
                        }}
                        outline
                        color="primary"
                        size="sm"
                        active={showDetails}
                    >
                        Show Details
                    </Button>
                </Col>
            </Row>
            <Collapse
                isOpen={showDetails}
                style={{ paddingLeft: "1.6em", fontSize: props.is_mobile ? "10px" : "12px" }}
            >
                {props.uconnect_email !== props.beta_site_email ? (
                    <Row>
                        <span style={{ fontFamily: "Roboto-Bold" }}>Beta Site Email:</span> &nbsp;
                        {props.beta_site_email}
                    </Row>
                ) : null}
                <Row>
                    <span style={{ fontFamily: "Roboto-Bold" }}>Uconnect Email:</span> &nbsp;
                    {props.uconnect_email}
                </Row>
                {Object.keys(props.vins).map(function (key) {
                    return (
                        <Row key={key}>
                            {props.vins[key]} {" | "}
                            <IoMdCar style={{ marginTop: "0.25em" }} /> {props.vins_nicknames[key]}
                        </Row>
                    );
                })}
                <Row style={{ paddingTop: "0.5em" }}>
                    {!confirmApprove && !confirmDeny ? (
                        <div style={{ display: "flex", justifyContent: "left" }}>
                            <ButtonGroup>
                                <Button
                                    color="primary"
                                    size="sm"
                                    outline
                                    onClick={() => {
                                        setConfirmApprove(true);
                                    }}
                                >
                                    Approve
                                </Button>
                                <Button
                                    color="danger"
                                    size="sm"
                                    outline
                                    onClick={() => {
                                        setConfirmDeny(true);
                                    }}
                                >
                                    Deny
                                </Button>
                            </ButtonGroup>
                        </div>
                    ) : null}

                    {confirmApprove ? (
                        <div style={{ display: "flex", justifyContent: "left" }}>
                            <ButtonGroup>
                                <Button
                                    color="success"
                                    size="sm"
                                    disabled={submitting}
                                    onClick={() => {
                                        approveVINsRequest(
                                            props.uconnect_email,
                                            props.beta_site_email,
                                            "true"
                                        );
                                    }}
                                >
                                    Confirm User Approval
                                </Button>
                                <Button
                                    color="danger"
                                    size="sm"
                                    disabled={submitting}
                                    onClick={() => {
                                        setConfirmApprove(false);
                                    }}
                                >
                                    Cancel
                                </Button>
                            </ButtonGroup>
                        </div>
                    ) : null}

                    {confirmDeny ? (
                        <div style={{ display: "flex", justifyContent: "left" }}>
                            <ButtonGroup>
                                <Button
                                    color="warning"
                                    size="sm"
                                    disabled={submitting}
                                    onClick={() => {
                                        approveVINsRequest(
                                            props.uconnect_email,
                                            props.beta_site_email,
                                            "false"
                                        );
                                    }}
                                >
                                    Confirm User Denial
                                </Button>
                                <Button
                                    color="danger"
                                    size="sm"
                                    disabled={submitting}
                                    onClick={() => {
                                        setConfirmDeny(false);
                                    }}
                                >
                                    Cancel
                                </Button>
                            </ButtonGroup>
                        </div>
                    ) : null}
                </Row>
            </Collapse>
        </ListGroupItem>
    );
};

const UsersVINsRequests = (props) => {
    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <ListGroup>
                {Object.keys(props.users_vins_requests).map(function (key) {
                    return (
                        <UsersVINsRequest
                            key={key}
                            token={props.token}
                            uconnect_email={props.users_vins_requests[key].UconnectEmail}
                            beta_site_email={props.users_vins_requests[key].beta_site_email}
                            vins_nicknames={props.users_vins_requests[key].vins_nicknames}
                            vins={props.users_vins_requests[key].vins}
                            is_mobile={props.is_mobile}
                            jumbotron_width={props.jumbotron_width}
                        />
                    );
                })}
            </ListGroup>
        </div>
    );
};

export default UsersVINsRequests;
