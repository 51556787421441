import React from "react";
import "../../css/OddRow.css";

const OddRow = (props) => {
    const CreateDescriptionHTML = (htmlString) => {
        return { __html: htmlString };
    };

    return (
        <div className="odd-row-container" id={props.id.toLowerCase()}>
            <div className="odd-row-left">
                <div className="odd-row-text">
                    <h3>{props.title}</h3>

                    {props.description_html ? (
                        <div
                            dangerouslySetInnerHTML={CreateDescriptionHTML(props.description_html)}
                        ></div>
                    ) : (
                        <p>{props.description}</p>
                    )}
                    {props.features ? (
                        <>
                            <p>FEATURES</p>
                            <ul>
                                {props.features.map((feature) => (
                                    <li>{feature}</li>
                                ))}
                            </ul>
                        </>
                    ) : null}
                    {props.setup_instruction.length !== 0 ? (
                        <>
                            <p>INSTRUCTIONS</p>
                            <ol className={"feature-instructions"}>
                                {props.setup_instruction.map((instruction) => {
                                    return <li>{instruction}</li>;
                                })}
                            </ol>
                        </>
                    ) : null}
                    {props.links.length !== 0 ? (
                        <>
                            <p>LINKS</p>
                            <p>
                                {props.links.map((link) => {
                                    return (
                                        <div>
                                            <a href={link.link_url}>{link.link_text}</a>
                                        </div>
                                    );
                                })}
                            </p>
                        </>
                    ) : null}
                </div>
            </div>
            <div className="odd-row-right">
                <img className="odd-row-image" src={props.imageUrl} alt={props.imageAltText} />
            </div>
        </div>
    );
};

export default OddRow;
