import React, { useEffect } from "react";
import { Form, Button, Input, InputGroupText, InputGroup } from "reactstrap";
import { useState } from "react";
import { Redirect, useLocation } from "react-router-dom";
import { UserGigyaLogin, UserOAuthLogin } from "../api";
import { connect } from "react-redux";
import StellanovaLogo from "../img/logo.png";
import "../css/Login.css";
import { Image } from "react-bootstrap";
import EmailIcon from "../img/email_icon.svg";
import PasswordIcon from "../img/password_icon.svg";
import EyeIcon from "../img/eye_icon.svg";
import Loading from "../components/Loading";
import LoadPageAuthCheck from "../components/LoadPageAuthCheck";


const LoginScreen = (props) => {
    const [emailInput, setEmailInput] = useState("");
    const [passwordInput, setPasswordInput] = useState("");
    const [redirectToGetStarted, setRedirectToGetStarted] = useState(false);
    const [redirectToLegalTerms, setRedirectToLegalTerms] = useState(false);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [logoWidth, setLogoWidth] = useState("700em");
    const [loginFail, setLoginFail] = useState("");
    const [loginLoading, setLoginLoading] = useState(false);
    const [ssoLoginLoading, setSSOLoginLoading] = useState(false);

    let forgotPasswordURL = "https://connect.jeep.com/us/en/forgot-password";
    let signupURL = "https://connect.jeep.com/us/en/local-register";

    const location = useLocation();
    const queryParameters = new URLSearchParams(location.search);

    useEffect(() => {
        window.addEventListener("resize", handleWindowSizeChange);
        handleWindowSizeChange();
        if (localStorage.getItem("fca_token")) {
            props.dispatch({ type: "LOADING" });
        } else {
            localStorage.removeItem("fca_token");
            props.dispatch({ type: "LOGOUT" });
        }
        if (queryParameters.get("code")) {
            oAuthLogin(queryParameters.get("code"));
        }
    }, []);

    const handleWindowSizeChange = () => {
        if (window.innerWidth <= 767) {
            setLogoWidth("300em");
        } else {
            setLogoWidth("700em");
        }
    };

    const oAuthLogin = async (oAuthCode) => {
        setSSOLoginLoading(true);
        let oAuthLoginResponse = await UserOAuthLogin(oAuthCode);
        console.log(oAuthLoginResponse);
        if (!oAuthLoginResponse && !oAuthLoginResponse.response) {
            setLoginLoading(false);
            return null;
        }
        if (oAuthLoginResponse.response) {
            if (oAuthLoginResponse.response.status !== 200) {
                setLoginLoading(false);
                setLoginFail("SSO Login Failed");
                return null;
            }
        }
        if (oAuthLoginResponse.status === 200) {
            let fca_token = oAuthLoginResponse.data.login_token;
            localStorage.setItem("fca_token", fca_token);
            props.dispatch({
                type: "LOGIN_WITH_INFO",
                payload: oAuthLoginResponse.data
            });
        }
        setSSOLoginLoading(false);
        setLoginFail("SSO Login Failed");
    };

    const onSignInClick = async (e) => {
        e.preventDefault();
        setLoginFail("");
        setLoginLoading(true);
        if (emailInput === "" || passwordInput === "") {
            setLoginLoading(false);
            setLoginFail("Please enter both email and password");
            return null;
        }
        const gigya_login_response = await UserGigyaLogin(emailInput, passwordInput);
        if (!gigya_login_response && !gigya_login_response.response) {
            setLoginLoading(false);
            return null;
        }
        if (gigya_login_response.response) {
            if (gigya_login_response.response.status === 401) {
                setLoginLoading(false);
                setLoginFail("Invalid email or password");
                return null;
            } else if (gigya_login_response.response.status === 403) {
                setLoginLoading(false);
                setLoginFail(
                    "User is not authorized -- StellaNova is open to @stellantis.com email addresses only"
                );
                return null;
            } else {
                setLoginLoading(false);
                setLoginFail("Login failed");
                return null;
            }
        }
        if (gigya_login_response.status === 200) {
            let fca_token = gigya_login_response.data.login_token;
            localStorage.setItem("fca_token", fca_token);
            props.dispatch({
                type: "LOGIN_WITH_INFO",
                payload: gigya_login_response.data
            });
        }
        setLoginLoading(false);
        setLoginFail(true);
    };

    const handleChange = async (event) => {
        switch (event.target.id) {
            case "emailInputForm":
                setEmailInput(event.target.value);
                break;
            case "passwordInputForm":
                setPasswordInput(event.target.value);
                break;
            case "signInButton":
                onSignInClick();
                break;
            default:
                break;
        }
    };

    const handlePasswordVisibility = (event) => {
        setPasswordVisible((prevVisibility) => !prevVisibility);
    };

    if (redirectToLegalTerms) {
        return (
            <div>
                <Redirect to="/terms" />
            </div>
        );
    }
    if (redirectToGetStarted) {
        return (
            <div>
                <Redirect to="/getstarted" />
            </div>
        );
    }

    if (props.logged_in) {
        if (props.user_info.accepted_legal_terms === false) {
            setRedirectToLegalTerms(true);
        } else {
            setRedirectToGetStarted(true);
        }
    }

    if (props.loading) {
        return (
            <div>
                <Loading />
                <LoadPageAuthCheck {...props} />
            </div>
        );
    }

    if (ssoLoginLoading) {
        return <Loading />;
    }

    return (
        <div className="login-page">
            <div>
                <div className="div-logo">
                    <Image src={StellanovaLogo} width={logoWidth} />
                </div>


                <div className="div-sign-in-container">
                    <div className="div-sign-in-box-background-left">
                        <Form className="div-sign-in-form" onSubmit={onSignInClick}>
                            <InputGroup className="sign-in-page-input-group">
                                <InputGroupText>
                                    <Image src={EmailIcon} />
                                </InputGroupText>
                                <Input
                                    type="email"
                                    name="email"
                                    id="emailInputForm"
                                    placeholder="EMAIL"
                                    value={emailInput}
                                    onChange={handleChange}
                                />
                            </InputGroup>
                            <InputGroup className="sign-in-page-input-group">
                                <InputGroupText>
                                    <Image src={PasswordIcon} />
                                </InputGroupText>
                                <Input
                                    type={passwordVisible ? "text" : "password"}
                                    name="password"
                                    id="passwordInputForm"
                                    placeholder="PASSWORD"
                                    value={passwordInput}
                                    onChange={handleChange}
                                />
                                <Button onClick={handlePasswordVisibility}>
                                    <Image src={EyeIcon} />
                                </Button>
                            </InputGroup>
                            <div className="div-sign-in-box">
                                <Button
                                    className="btn btn-primary btn-md btn-block sign-in-button"
                                    id="signInButton"
                                    type="submit"
                                >
                                    {loginLoading ? "SIGNING IN..." : "SIGN IN"}
                                </Button>
                            </div>
                        </Form>

                        {loginFail === "" ? null : <div className={"errorMessage"}>{loginFail}</div>}
                        <div className="div-box">
                            <p className="paragraphs" style={{ marginTop: 10 }}>
                                {" "}
                                Please use your Stellantis branded connected vehicle account to sign in
                            </p>
                            <p className="paragraphs">
                                <a className="hyperlinks" href={forgotPasswordURL}>
                                    FORGOT PASSWORD?
                                </a>
                            </p>
                            <p className="paragraphs">
                                <a className="hyperlinks" href={signupURL}>
                                    SIGN UP
                                </a>
                            </p>
                        </div>
                    </div>

                    <div className="sign-in-divider"></div>

                    <div className="div-sign-in-box-background-right">
                        <h3 className="paragraphs" style={{ marginTop: 30 }}>ATTENTION</h3>
                        <p className="paragraphs" style={{ marginTop: 20}}>
                            {" "}
                            If you are a Stellantis employee, you may use Stellantis-SSO to sign in.
                        </p>

                        <p className="paragraphs" style={{ marginTop: 10,  marginBottom: 20 }}>
                            {" "}
                            However, you will NOT be able to use VIN ownership dependent features, such as Scheduled Remote Ops.
                        </p>


                        <div className={"idp-container"}>
                            <a
                                href={`${process.env.REACT_APP_STELLANOVA_AUTH_URL}/oauth2/authorize?identity_provider=StellantisSSO-PingFederate&redirect_uri=${process.env.REACT_APP_REDIRECT_URI}&response_type=CODE&client_id=${process.env.REACT_APP_STELLANOVA_CLIENT_ID}&scope=email openid phone aws.cognito.signin.user.admin`}
                            >
                                <input
                                    type="button"
                                    value={
                                        ssoLoginLoading ? "Signing in..." : "StellantisSSO-PingFederate"
                                    }
                                    className="btn btn-info idpButton-customizable"
                                    aria-label="StellantisSSO-PingFederate"
                                />
                            </a>
                    </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state) => ({
    logged_in: state.logged_in,
    loading: state.loading,
    user_info: state.user_info
});

export default connect(mapStateToProps)(LoginScreen);
