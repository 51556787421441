import React from "react";
import { Badge, ListGroup, ListGroupItem } from "reactstrap";

const CanadaCarsList = () => {
    return (
        <ListGroup>
            <ListGroupItem color="primary" style={{ padding: "0.5em", fontSize: "13px" }}>
                2018 – 2020 Chrysler 300 <Badge color="danger">CANADA</Badge>
            </ListGroupItem>
            <ListGroupItem color="primary" style={{ padding: "0.5em", fontSize: "13px" }}>
                2018 – 2020 Chrysler Pacifica <Badge color="danger">CANADA</Badge>
            </ListGroupItem>
            <ListGroupItem color="primary" style={{ padding: "0.5em", fontSize: "13px" }}>
                2018 – 2020 Dodge Challenger and Charger <Badge color="danger">CANADA</Badge>
            </ListGroupItem>
            <ListGroupItem color="primary" style={{ padding: "0.5em", fontSize: "13px" }}>
                2018 – 2020 Dodge Durango <Badge color="danger">CANADA</Badge>
            </ListGroupItem>
            <ListGroupItem color="primary" style={{ padding: "0.5em", fontSize: "13px" }}>
                2020 Jeep® Cherokee <Badge color="danger">CANADA</Badge>
            </ListGroupItem>
            <ListGroupItem color="primary" style={{ padding: "0.5em", fontSize: "13px" }}>
                2018 – 2020 Jeep® Compass <Badge color="danger">CANADA</Badge>
            </ListGroupItem>
            <ListGroupItem color="primary" style={{ padding: "0.5em", fontSize: "13px" }}>
                2018 – 2020 Jeep® Grand Cherokee <Badge color="danger">CANADA</Badge>
            </ListGroupItem>
            <ListGroupItem color="primary" style={{ padding: "0.5em", fontSize: "13px" }}>
                2018 – 2020 Jeep® Renegade <Badge color="danger">CANADA</Badge>
            </ListGroupItem>
            <ListGroupItem color="primary" style={{ padding: "0.5em", fontSize: "13px" }}>
                2018 – 2020 Jeep® Wrangler <Badge color="danger">CANADA</Badge>
            </ListGroupItem>
            <ListGroupItem color="primary" style={{ padding: "0.5em", fontSize: "13px" }}>
                2020 Jeep® Gladiator <Badge color="danger">CANADA</Badge>
            </ListGroupItem>
            <ListGroupItem color="primary" style={{ padding: "0.5em", fontSize: "13px" }}>
                2018 – 2020 Ram 1500/2500/3500/Chassis Cab <Badge color="danger">CANADA</Badge>
            </ListGroupItem>
        </ListGroup>
    );
};

export default CanadaCarsList;
