import React, { Component } from "react";
import { Container } from "reactstrap";
import { connect } from "react-redux";
import LoadPageAuthCheck from "./components/LoadPageAuthCheck";
import Loading from "./components/Loading";
import Footer from "./components/Footer";
import centerLogo from "./img/UconnectlabBeta.png";
import "./css/fonts.css";

class PageNotFound extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_mobile: false,
            user_info: {},
            logo_width: "350em"
        };
    }
    logout() {
        localStorage.removeItem("fca_token")
        this.props.dispatch({ type: "LOGOUT" });
    }
    componentDidMount() {
        window.addEventListener("resize", this.handleWindowSizeChange);
        this.handleWindowSizeChange();

        if (localStorage.getItem("fca_token")) {
            this.props.dispatch({ type: "LOADING" });
        } else {
            this.logout();
            return;
        }
    }
    handleWindowSizeChange = () => {
        if (window.innerWidth <= 767) {
            this.setState({
                logo_width: "245em",
                is_mobile: true
            });
        } else {
            this.setState({
                logo_width: "350em",
                is_mobile: false
            });
        }
    };
    render() {
        if (this.props.loading) {
            return (
                <div>
                    <Loading />
                    <LoadPageAuthCheck {...this.props} />
                </div>
            );
        } else {
            return (
                <div style={{ paddingTop: this.state.is_mobile ? "4em" : "0" }}>
                    <Container>
                        <div>
                            {this.state.is_mobile ? (
                                <h3
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        fontFamily: "Roboto-Light",
                                        padding: "0em"
                                    }}
                                >
                                    404 Page Not Found
                                </h3>
                            ) : (
                                <h1
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        fontFamily: "Roboto-Light",
                                        padding: "0.5em"
                                    }}
                                >
                                    404 Page Not Found
                                </h1>
                            )}
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                padding: "2em",
                                bgcolor: "grey"
                            }}
                        >
                            <a href="/">
                                <img src={centerLogo} alt="Logo" width={this.state.logo_width} />
                            </a>
                        </div>
                    </Container>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <Footer is_mobile={this.state.is_mobile} />
                    </div>
                </div>
            );
        }
    }
}

const mapStateToProps = (state) => ({
    logged_in: state.logged_in,
    loading: state.loading
});

export default connect(mapStateToProps)(PageNotFound);
