import React, { useEffect, useState } from "react";
import { Row, Col, Button, Badge, Collapse, ListGroupItem, ListGroup } from "reactstrap";
import { IoIosArrowUp, IoIosArrowDown, IoMdStar } from "react-icons/io";
import { RiErrorWarningLine } from "react-icons/ri";
import { BsQuestionCircle } from "react-icons/bs";
import "../../css/fonts.css";

const Feature = (props) => {
    const [color, setColor] = useState("black");
    const [statusColor, setStatusColor] = useState("");
    const [statusText, setStatusText] = useState("");
    const [collapse, setCollapse] = useState(false);

    useEffect(() => {
        if (props.approval_status === "public") {
            setStatusText("Approved for Public");
            setStatusColor("success");
        } else if (props.approval_status === "internal") {
            setStatusText("Approved for Internal");
            setStatusColor("success");
        } else if (props.approval_status === "waiting") {
            setStatusText("Waiting Approval");
            setStatusColor("warning");
        } else {
            setStatusText("Denied");
            setStatusColor("danger");
        }
    }, [props.approval_status]);

    return (
        <ListGroupItem
            id={props.feature_id}
            onMouseEnter={() => {
                setColor("#0099F9");
            }}
            onMouseLeave={() => {
                setColor("black");
            }}
            style={{ width: props.jumbotron_width, padding: "0", fontFamily: "Roboto-Light" }}
        >
            <div>
                <Row>
                    <Col style={{ paddingRight: "0" }}>
                        <Button
                            color="link"
                            onClick={() => {
                                setCollapse(!collapse);
                            }}
                            style={{
                                fontSize: props.is_mobile ? "13px" : "15px",
                                color: color,
                                paddingRight: "0.1em"
                            }}
                        >
                            <img
                                src={props.s3_logo_small}
                                alt="Small Logo"
                                width="15em"
                                style={{ paddingBottom: "0.2em", marginRight: "0.5em" }}
                            />
                            {props.title}
                            {collapse ? <IoIosArrowUp /> : <IoIosArrowDown />}
                        </Button>

                        {props.approval_status === "waiting" || props.approval_notes !== "" ? (
                            <Badge
                                color={
                                    props.approval_notes !== ""
                                        ? props.approval_status !== "internal"
                                            ? "danger"
                                            : "dark"
                                        : "warning"
                                }
                            >
                                {props.approval_notes !== "" ? (
                                    <RiErrorWarningLine />
                                ) : (
                                    <BsQuestionCircle />
                                )}
                            </Badge>
                        ) : null}
                    </Col>

                    <Col
                        xs="auto"
                        style={{ display: "flex", justifyContent: "right", paddingLeft: "0" }}
                    >
                        {props.approval_notes !== "" || props.approval_status === "waiting" ? (
                            <Button
                                href={"/develop/redeploy/" + props.feature_id}
                                target="_blank"
                                rel="noopener noreferrer"
                                color="link"
                                style={{ fontSize: props.is_mobile ? "12px" : "15px" }}
                            >
                                Redeploy
                            </Button>
                        ) : (
                            <Button
                                href={"/features/" + props.feature_id}
                                target="_blank"
                                rel="noopener noreferrer"
                                color="link"
                                style={{ fontSize: props.is_mobile ? "12px" : "15px" }}
                            >
                                Visit
                            </Button>
                        )}
                    </Col>
                </Row>

                <Collapse isOpen={collapse}>
                    <div
                        style={{
                            padding: "0.5em",
                            paddingLeft: "1em",
                            paddingTop: "0",
                            fontSize: props.is_mobile ? "12px" : "14px"
                        }}
                    >
                        <Badge
                            style={{ fontSize: props.is_mobile ? "12px" : "12px" }}
                            color={statusColor}
                        >
                            {statusText}
                            {props.approval_status === "public" ? (
                                <IoMdStar style={{ marginBottom: "0.1em", marginLeft: "0.3em" }} />
                            ) : null}
                        </Badge>

                        <p style={{ margin: "0" }}>
                            <b>Feature ID:</b> {props.feature_id}
                        </p>
                        <p style={{ margin: "0" }}>
                            <span style={{ color: "#BCBCBC", padding: "0" }}>
                                https://stellanova.io/features/
                            </span>
                            {props.feature_id}
                        </p>

                        {props.approval_notes !== "" ? (
                            <p style={{ margin: "0" }}>
                                <Badge color="warning" pill>
                                    Improvement Notes:
                                </Badge>{" "}
                                {props.approval_notes}
                            </p>
                        ) : null}
                    </div>
                </Collapse>
            </div>
        </ListGroupItem>
    );
};
const Features = (props) => {
    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <ListGroup>
                {Object.keys(props.features).map(function (key) {
                    return (
                        <Feature
                            key={key}
                            feature={props.features[key]}
                            title={props.features[key].title}
                            approval_status={props.features[key].approval_status}
                            approval_notes={props.features[key].approval_notes}
                            s3_logo_small={props.features[key].s3_logo_small}
                            feature_id={props.feature_ids[key]}
                            author={props.author}
                            jumbotron_width={props.jumbotron_width}
                            is_mobile={props.is_mobile}
                        />
                    );
                })}
            </ListGroup>
        </div>
    );
};

export default Features;
