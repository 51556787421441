import React, { useEffect } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import AppNavbar from "./components/AppNavbar";
import GetStarted from "./scenes/GetStarted/GetStarted";
import FCACheck from "./components/FCACheck";
import Terms from "./components/Terms";
import About from "./scenes/About/About";
import Feature from "./scenes/Feature/Feature";
import Profile from "./scenes/Profile/Profile";
import DeveloperConsole from "./scenes/DeveloperConsole/DeveloperConsole";
import AdminConsole from "./scenes/AdminConsole/AdminConsole";
import Download from "./scenes/Download/Download";
import SDK from "./scenes/SDK/SDK";
import Feedback from "./scenes/Feedback/Feedback";
import PageNotFound from "./PageNotFound";
import "./css/App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import LoginBackground from "./img/loginbg.jpg";
import LoginScreen from "./scenes/LoginScreen";
import ScheduledRemoteOp from "./scenes/ScheduledRemoteOp/ScheduledRemoteOp";
import MoonbaseFeatures from "./scenes/MoonbaseFeatures/MoonbaseFeaturesPage";
import Unenroll from "./scenes/Unenroll/Unenroll";

function App(props) {
    const location = useLocation();

    return (
        <div>
            {/* Navbar always exists */}
            {(props.logged_in || props.public_logged_in) & (location.pathname !== "/terms") ? (
                <AppNavbar />
            ) : (
                <div></div>
            )}

            {/* Routes */}
            <div style={{ display: "flex", justifyContent: "center" }}>
                <div
                    style={{
                        backgroundImage: `url(${LoginBackground})`,
                        width: "100%",
                        height: "100vh",
                        backgroundSize: "cover",
                        backgroundRepeat: "none"
                    }}
                >
                    <Switch>
                        <Route exact path="/terms" component={Terms} />
                        <Route exact path="/register" component={FCACheck} />
                        <Route exact path="/">
                            <LoginScreen />
                        </Route>
                        <Route
                            exact
                            path="/getstarted"
                            render={(props) => <GetStarted {...props} />}
                        />
                        <Route
                            exact
                            path="/features/:feature_id"
                            render={(props) => <Feature {...props} />}
                        />
                        <Route
                            exact
                            path="/preview/:feature_id"
                            render={(props) => <Feature is_preview={true} {...props} />}
                        />
                        <Route exact path="/about">
                            <About />
                        </Route>
                        <Route exact path="/profile">
                            <Profile />
                        </Route>
                        <Route exact path="/develop">
                            <DeveloperConsole />
                        </Route>
                        <Route exact path="/admin">
                            <AdminConsole />
                        </Route>
                        <Route exact path="/download">
                            <Download />
                        </Route>
                        <Route exact path="/sdk">
                            <SDK />
                        </Route>
                        <Route exact path="/feedback">
                            <Feedback />
                        </Route>
                        <Route exact path="/scheduled-remote-ops">
                            <ScheduledRemoteOp />
                        </Route>
                        <Route exact path="/moonbase-features">
                            <MoonbaseFeatures />
                        </Route>
                        <Route path="/unenroll">
                            <Unenroll />
                        </Route>
                        <Route>
                            <PageNotFound />
                        </Route>
                    </Switch>
                </div>
            </div>
        </div>
    );
}

const mapStateToProps = (state, ownProps) => ({
    logged_in: state.logged_in,
    loading: state.loading,
    public_logged_in: state.public_logged_in
});

export default connect(mapStateToProps, null)(App);
