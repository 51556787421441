import React, { Component } from "react";
import {
    Jumbotron,
    Modal,
    ModalBody,
    ModalHeader,
    Col,
    CustomInput,
    Button,
    Badge,
    Collapse,
    FormGroup
} from "reactstrap";
import { Redirect } from "react-router";
import { connect } from "react-redux";
import { queryFeaturesAPI, queryDevRequestsAPI, queryVINsRequestsAPI } from "../../api";
import LoadPageAuthCheck from "../../components/LoadPageAuthCheck";
import Loading from "../../components/Loading";
import Footer from "../../components/Footer";
import "../../css/fonts.css";
import ButtonGroup from "reactstrap/lib/ButtonGroup";
import Features from "./Features";
import DevRequests from "./DevRequests";
import UsersVINsRequests from "./UsersVINsRequests";

class AdminConsole extends Component {
    constructor(props) {
        super(props);
        this.state = {
            is_mobile: false,
            text_size: "1em",
            jumbotron_width: "40em",
            user_info: {},
            pictures: [],
            features_loading: true,
            features: [],
            dev_requests_loading: true,
            dev_requests: [],
            users_vins_requests_loading: true,
            users_vins_requests: [],
            public_checked: true,
            internal_checked: true,
            waiting_checked: true,
            waiting_count: 0,
            denied_checked: true,
            filter_modal: false,
            show_features: true,
            show_users: false,
            show_developers: false
        };
    }
    componentDidMount() {
        window.addEventListener("resize", this.handleWindowSizeChange);
        this.handleWindowSizeChange();
        if (localStorage.getItem("fca_token")) {
            this.props.dispatch({ type: "LOADING" });
        } else {
            localStorage.removeItem("fca_token")
            this.props.dispatch({ type: "LOGOUT" });
        }
        this.queryFeature();
        this.queryDevRequests();
        this.queryVINsRequests();
    }
    logout() {
        localStorage.removeItem("fca_token")
        this.props.dispatch({ type: "LOGOUT" });
    }
    queryFeature() {
        try {
            queryFeaturesAPI(localStorage.getItem("fca_token"))
                .then(async (result) => {
                    console.log("Query features result:", result);
                    let waiting_count = 0;
                    for (let i = 0; i < result.items.length; ++i) {
                        if (result.items[i].approval_status === "waiting") {
                            ++waiting_count;
                        }
                    }
                    this.setState({ waiting_count: waiting_count });
                    await this.setState({
                        features: result.items.sort(function (a, b) {
                            if (a.approval_status > b.approval_status) {
                                return -1;
                            }
                            if (a.approval_status < b.approval_status) {
                                return 1;
                            }
                            return 0;
                        }),
                        features_loading: false
                    });
                })
                .catch((error) => {
                    console.log("Query features error:", error);
                    this.logout();
                    return;
                });
        } catch {
            console.log("Query features error.");
            this.logout();
            return;
        }
    }
    queryVINsRequests() {
        try {
            queryVINsRequestsAPI(localStorage.getItem("fca_token"))
                .then(async (result) => {
                    console.log("Query vins requests result:", result);
                    await this.setState({
                        users_vins_requests: result.items,
                        users_vins_requests_loading: false
                    });
                })
                .catch((error) => {
                    console.log("Query vins requests error:", error);
                    this.logout();
                    return;
                });
        } catch {
            console.log("Query vins requests error.");
            this.logout();
            return;
        }
    }
    queryDevRequests() {
        try {
            queryDevRequestsAPI(localStorage.getItem("fca_token"))
                .then(async (result) => {
                    console.log("Query dev requests result:", result);
                    await this.setState({
                        dev_requests: result.items,
                        dev_requests_loading: false
                    });
                })
                .catch((error) => {
                    console.log("Query dev requests error:", error);
                    this.logout();
                    return;
                });
        } catch {
            console.log("Query dev requests error.");
            this.logout();
            return;
        }
    }
    handleWindowSizeChange = () => {
        if (window.innerWidth <= 500) {
            this.setState({
                jumbotron_width: "19em",
                is_mobile: true
            });
        } else if (window.innerWidth > 500 && window.innerWidth <= 767) {
            this.setState({
                jumbotron_width: "30em",
                is_mobile: true
            });
        } else {
            this.setState({
                jumbotron_width: "47em",
                is_mobile: false
            });
        }
    };
    handleChange = async (event) => {
        const { target } = event;
        let value;
        if (target.type === "checkbox") {
            value = target.checked;
            const { id } = target;
            await this.setState({
                [id]: value
            });
        } else {
            value = target.value;
            const { name } = target;
            await this.setState({
                [name]: value
            });
        }
    };

    toggleFeatures = () => {
        this.setState({ show_users: false, show_developers: false, show_features: true });
    };
    toggleUsers = () => {
        this.setState({ show_features: false, show_developers: false, show_users: true });
    };
    toggleDevelopers = () => {
        this.setState({ show_features: false, show_users: false, show_developers: true });
    };
    // COMPONENTS
    Header = () => {
        return (
            <div>
                {this.state.is_mobile ? (
                    <h3
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            fontFamily: "Roboto-Light",
                            padding: "0em"
                        }}
                    >
                        Admin Console
                    </h3>
                ) : (
                    <h1
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            fontFamily: "Roboto-Light",
                            padding: "0.5em"
                        }}
                    >
                        Admin Console
                    </h1>
                )}
            </div>
        );
    };
    Tabs = () => {
        return (
            <div style={{ display: "flex", justifyContent: "center", paddingBottom: "0.5em" }}>
                <ButtonGroup>
                    <Button
                        size={this.state.is_mobile ? "sm" : "md"}
                        outline
                        color="primary"
                        active={this.state.show_features}
                        onClick={this.toggleFeatures}
                    >
                        Features{" "}
                        {this.state.waiting_count > 0 ? (
                            <Badge color={this.state.show_features ? "light" : "primary"} pill>
                                {this.state.waiting_count}
                            </Badge>
                        ) : null}
                    </Button>
                    <Button
                        size={this.state.is_mobile ? "sm" : "md"}
                        outline
                        color="primary"
                        active={this.state.show_users}
                        onClick={this.toggleUsers}
                    >
                        Users{" "}
                        {this.state.users_vins_requests.length > 0 ? (
                            <Badge color={this.state.show_users ? "light" : "primary"} pill>
                                {this.state.users_vins_requests.length}
                            </Badge>
                        ) : null}
                    </Button>
                    <Button
                        size={this.state.is_mobile ? "sm" : "md"}
                        outline
                        color="primary"
                        active={this.state.show_developers}
                        onClick={this.toggleDevelopers}
                    >
                        Developers{" "}
                        {this.state.dev_requests.length > 0 ? (
                            <Badge color={this.state.show_developers ? "light" : "primary"} pill>
                                {this.state.dev_requests.length}
                            </Badge>
                        ) : null}
                    </Button>
                </ButtonGroup>
            </div>
        );
    };
    MobileFeatureFilters = () => {
        return (
            <div>
                <Modal
                    isOpen={this.state.filter_modal}
                    toggle={() => {
                        this.setState({ filter_modal: !this.state.filter_modal });
                    }}
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        paddingTop: "13em",
                        opacity: "92%"
                    }}
                >
                    <ModalHeader
                        style={{
                            fontSize: "15px",
                            padding: "0.5em",
                            display: "flex",
                            justifyContent: "center"
                        }}
                    >
                        Feature Filters
                    </ModalHeader>
                    <ModalBody style={{ display: "flex", justifyContent: "center" }}>
                        <FormGroup
                            onChange={(e) => {
                                this.handleChange(e);
                            }}
                        >
                            <CustomInput
                                type="checkbox"
                                defaultChecked={this.state.waiting_checked}
                                id="waiting_checked"
                                label="Waiting Approval"
                            />
                            <CustomInput
                                type="checkbox"
                                defaultChecked={this.state.internal_checked}
                                id="internal_checked"
                                label="Internal Releases"
                            />
                            <CustomInput
                                type="checkbox"
                                defaultChecked={this.state.public_checked}
                                id="public_checked"
                                label="Public Releases"
                            />
                            <CustomInput
                                type="checkbox"
                                defaultChecked={this.state.denied_checked}
                                id="denied_checked"
                                label="Denied Requests"
                            />
                        </FormGroup>
                    </ModalBody>
                </Modal>
            </div>
        );
    };
    WebFeatureFilters = () => {
        return (
            <FormGroup
                onChange={(e) => {
                    this.handleChange(e);
                }}
            >
                <CustomInput
                    type="checkbox"
                    defaultChecked={this.state.waiting_checked}
                    id="waiting_checked"
                    label="Waiting Approval"
                    inline
                />
                <CustomInput
                    type="checkbox"
                    defaultChecked={this.state.internal_checked}
                    id="internal_checked"
                    label="Internal Releases"
                    inline
                />
                <CustomInput
                    type="checkbox"
                    defaultChecked={this.state.public_checked}
                    id="public_checked"
                    label="Public Releases"
                    inline
                />
                <CustomInput
                    type="checkbox"
                    defaultChecked={this.state.denied_checked}
                    id="denied_checked"
                    label="Denied Requests"
                    inline
                />
            </FormGroup>
        );
    };
    FeaturesList = () => {
        return (
            <Collapse isOpen={this.state.show_features}>
                <Col>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <h5 style={{ margin: "0" }}>
                            {this.state.is_mobile ? (
                                <Button
                                    onClick={() => {
                                        this.setState({ filter_modal: true });
                                    }}
                                    size="sm"
                                    color="link"
                                    style={{ paddingBottom: "0.45em" }}
                                >
                                    Filters
                                </Button>
                            ) : null}
                        </h5>
                    </div>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            fontSize: this.state.is_mobile ? "12px" : "14px"
                        }}
                    >
                        {this.state.is_mobile
                            ? this.MobileFeatureFilters()
                            : this.WebFeatureFilters()}
                    </div>
                    <Features
                        token={localStorage.getItem("fca_token")}
                        parent_loaded={this.state.show_features}
                        waiting_checked={this.state.waiting_checked}
                        internal_checked={this.state.internal_checked}
                        public_checked={this.state.public_checked}
                        denied_checked={this.state.denied_checked}
                        features={this.state.features}
                        jumbotron_width={this.state.jumbotron_width}
                        is_mobile={this.state.is_mobile}
                    />
                </Col>
            </Collapse>
        );
    };
    DevelopersList = () => {
        return (
            <Collapse isOpen={this.state.show_developers}>
                <Col>
                    <DevRequests
                        token={localStorage.getItem("fca_token")}
                        dev_requests={this.state.dev_requests}
                        jumbotron_width={this.state.jumbotron_width}
                        is_mobile={this.state.is_mobile}
                    />
                </Col>
            </Collapse>
        );
    };
    UsersList = () => {
        return (
            <Collapse isOpen={this.state.show_users}>
                <Col>
                    <UsersVINsRequests
                        token={localStorage.getItem("fca_token")}
                        users_vins_requests={this.state.users_vins_requests}
                        jumbotron_width={this.state.jumbotron_width}
                        is_mobile={this.state.is_mobile}
                    />
                </Col>
            </Collapse>
        );
    };
    render() {
        if (this.props.loading) {
            return (
                <div>
                    <Loading />
                    <LoadPageAuthCheck {...this.props} />
                </div>
            );
        } else if (!this.props.logged_in) {
            return <Redirect to="/" />;
        } else if (this.state.features_loading) {
            return (
                <div>
                    <Loading component="Features" />
                </div>
            );
        } else if (this.state.dev_requests_loading) {
            return (
                <div>
                    <Loading component="Developers" />
                </div>
            );
        } else {
            if (this.props.user_info.is_admin) {
                return (
                    <div style={{ paddingTop: this.state.is_mobile ? "4em" : "0" }}>
                        {this.Header()}
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <Jumbotron
                                style={{
                                    width: this.state.jumbotron_width,
                                    padding: "0",
                                    backgroundColor: "transparent"
                                }}
                            >
                                {this.Tabs()}

                                {this.FeaturesList()}
                                {this.DevelopersList()}
                                {this.UsersList()}
                            </Jumbotron>
                        </div>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <Footer is_mobile={this.state.is_mobile} />
                        </div>
                    </div>
                );
            } else {
                return <Redirect to="/" />;
            }
        }
    }
}

const mapStateToProps = (state) => ({
    logged_in: state.logged_in,
    loading: state.loading,
    user_info: state.user_info
});

export default connect(mapStateToProps)(AdminConsole);
